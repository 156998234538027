//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import {
  makeStyles,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

import useMoveImport from './useMoveImport';

const headers = [
  {
    id: `field_name`,
    label: `Field\xa0Name`,
  },
  {
    id: `required`,
    label: `Required\xa0for Request\xa0Type`,
  },
  {
    id: `accepted_values`,
    label: `Accepted\xa0Values`,
  },
  {
    id: `description`,
    label: `Description`,
  },
];

//////////////////////// COMPONENT ////////////////////////

export default function ExplanationTable() {
  const cls = useStyles();

  const { explanations, configExplanations } = useMoveImport();

  return (
    <>
      <Typography className={cls.titleTxt}>Essential Fields</Typography>

      <Spacer size='xs' />

      <div className={cls.paper}>
        <TableContainer>
          <Table size='small' aria-label='explanation-table'>
            <TableHead>
              <TableRow>
                {headers.map(header => (
                  <TableCell key={header.id} align={header.align || `left`} padding={header.padding || `normal`}>
                    {header.label || `Label`}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {explanations.map((row, i) => {
                return (
                  <TableRow key={`explanation-row-${i}`} className={i % 2 ? cls.rowEven : cls.rowOdd}>
                    <TableCell className={i < explanations.length - 1 ? `` : cls.lastCell} align='left'>
                      {row.label || `-`}
                    </TableCell>

                    <TableCell className={i < explanations.length - 1 ? `` : cls.lastCell} align='left'>
                      {row.required && row.required.length
                        ? row.required.map((val, j) => (
                            <div key={`explanation-required-value-${j}`}>
                              {val}
                              {j < row.required.length - 1 ? `,` : ``}
                            </div>
                          ))
                        : `-`}
                      {row.requiredNote ? <div className={cls.noteTxt}>*{row.requiredNote}</div> : null}
                    </TableCell>

                    <TableCell className={i < explanations.length - 1 ? `` : cls.lastCell} align='left'>
                      {row.acceptedValues && row.acceptedValues.length
                        ? row.acceptedValues.map((val, j) => (
                            <div key={`explanation-accepted-value-${j}`}>
                              "{val}"{j < row.acceptedValues.length - 1 ? `,` : ``}
                            </div>
                          ))
                        : `-`}
                    </TableCell>

                    <TableCell className={i < explanations.length - 1 ? `` : cls.lastCell} align='left'>
                      {row.desc || `-`}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <Spacer size='lg' />

      <Typography className={cls.titleTxt}>Additional Configuration Fields</Typography>
      <Typography className={cls.subtitleTxt}>*Not included in base CSV template (Must be added manually)</Typography>

      <Spacer size='xs' />

      <div className={cls.paper}>
        <TableContainer>
          <Table size='small' aria-label='configExplanation-table'>
            <TableHead>
              <TableRow>
                {headers.map(header => (
                  <TableCell key={header.id} align={header.align || `left`} padding={header.padding || `normal`}>
                    {header.label || `Label`}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {configExplanations.map((row, i) => {
                return (
                  <TableRow key={`configExplanation-row-${i}`} className={i % 2 ? cls.rowEven : cls.rowOdd}>
                    <TableCell className={i < configExplanations.length - 1 ? `` : cls.lastCell} align='left'>
                      {row.label || `-`}
                    </TableCell>

                    <TableCell className={i < configExplanations.length - 1 ? `` : cls.lastCell} align='left'>
                      {row.required && row.required.length
                        ? row.required.map((val, j) => (
                            <div key={`configExplanation-required-value-${j}`}>
                              {val}
                              {j < row.required.length - 1 ? `,` : ``}
                            </div>
                          ))
                        : `-`}
                      {row.requiredNote ? <div className={cls.noteTxt}>*{row.requiredNote}</div> : null}
                    </TableCell>

                    <TableCell className={i < configExplanations.length - 1 ? `` : cls.lastCell} align='left'>
                      {row.acceptedValues && row.acceptedValues.length > 0
                        ? row.acceptedValues.map((val, j) => (
                            <div key={`configExplanation-accepted-value-${j}`}>
                              "{val}"{j < row.acceptedValues.length - 1 ? `,` : ``}
                            </div>
                          ))
                        : `-`}
                    </TableCell>

                    <TableCell className={i < configExplanations.length - 1 ? `` : cls.lastCell} align='left'>
                      {row.desc || `-`}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    border: theme.border[0],
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    overflow: 'hidden',
  },
  rowOdd: {
    background: theme.palette.background.paper,
    // '&:hover, &:active': {
    //   background: `${theme.palette.action.hover} !important`,
    // },
    // cursor: 'pointer',
    transition: '0.1s',
  },
  rowEven: {
    background: theme.palette.background.light,
    // '&:hover, &:active': {
    //   background: `${theme.palette.action.hover} !important`,
    // },
    // cursor: 'pointer',
    transition: '0.1s',
  },
  lastCell: {
    border: `none`,
  },
  titleTxt: {
    lineHeight: 1,
    fontSize: 21,
    fontWeight: 600,
  },
  subtitleTxt: {
    marginTop: theme.spacing(0.5),
    lineHeight: 1,
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  noteTxt: {
    marginTop: theme.spacing(1),
    lineHeight: 1.25,
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
}));
