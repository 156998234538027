//////////////////////// DEPENDENCIES ////////////////////////

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { useData } from '../../DataProvider';
import { makeStyles, Container, Grid, Typography, TextField, InputAdornment, Icon, MenuItem } from '@material-ui/core';
import { Button } from '@hopdrive/storybook';
import Divide from '../reusable/Divide';

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import * as Sentry from '@sentry/react';
import { getCustomerId } from '../utils/authHelper.js';
import Loading from '../utils/Loading';
import DashboardFilter from './dashboard/DashboardFilter';
import DashboardGrid from './dashboard/DashboardGrid';
import DashboardGridByCustomer from './dashboard/DashboardGridByCustomer';
import DashboardGridByRooftop from './dashboard/DashboardGridByRooftop';
import DashboardGridByCustomerAndRooftop from './dashboard/DashboardGridByCustomerAndRooftop';
import DashboardGridByHangtag from './dashboard/DashboardGridByHangtag';

const log = false;

const minDate = dayjs().startOf(`day`).add(1, `day`).subtract(3, `month`).format();
const maxDate = dayjs().endOf(`day`).add(3, `month`).format();

const getDisable = (range = null) => {
  const localRange = range || localStorage.getItem(`dashboard-range`);
  if (localRange === `custom`) return false;
  return true;
};
const getRange = () => {
  const localRange = localStorage.getItem(`dashboard-range`);
  if (localRange) return localRange;
  return `3day`;
};
const getStart = (range = null) => {
  const defaultStart = dayjs().startOf(`day`).subtract(3, `day`).format();
  const localRange = range || localStorage.getItem(`dashboard-range`);
  const localStart = localStorage.getItem(`dashboard-start`) || defaultStart;
  if (localRange === `day`) return dayjs().startOf(`day`).format();
  if (localRange === `3day`) return dayjs().startOf(`day`).add(1, `day`).subtract(3, `day`).format();
  if (localRange === `week`) return dayjs().startOf(`day`).add(1, `day`).subtract(1, `week`).format();
  if (localRange === `2week`) return dayjs().startOf(`day`).add(1, `day`).subtract(2, `week`).format();
  if (localRange === `month`) return dayjs().startOf(`day`).add(1, `day`).subtract(1, `month`).format();
  if (localRange === `custom`) {
    if (localStart < minDate || localStart === 'Invalid Date') return minDate;
    return localStart;
  }
  return defaultStart;
};
const getEnd = (range = null) => {
  const defaultEnd = dayjs().endOf(`day`).format();
  const localRange = range || localStorage.getItem(`dashboard-range`);
  const localEnd = localStorage.getItem(`dashboard-end`) || defaultEnd;
  if (localRange === `custom`) {
    if (localEnd > maxDate || localEnd === 'Invalid Date') return maxDate;
    return localEnd;
  }
  return defaultEnd;
};
const getConcierge = () => {
  const localConcierge = localStorage.getItem(`dashboard-group-concierge`);
  if (localConcierge === `true`) return true;
  return false;
};
const getRooftop = () => {
  const localRooftop = localStorage.getItem(`dashboard-group-rooftop`);
  if (localRooftop === `true`) return true;
  return false;
};

//////////////////////// COMPONENT ////////////////////////

export default function Dashboard(props) {
  const ctx = useData();
  const cls = useStyles();

  const [disablePickers, setDisablePickers] = useState(getDisable());
  const [range, setRange] = useState(getRange());
  const [start, setStart] = useState(getStart());
  const [end, setEnd] = useState(getEnd());
  const [sort, setSort] = useState(`new`);
  const [search, setSearch] = useState(``);
  const [conciergeTag, setConciergeTag] = useState(getConcierge());
  const [hangtagTag, setHangtagTag] = useState(getConcierge());
  const [statusTags, setStatusTags] = useState([]);
  const [makeTags, setMakeTags] = useState([]);
  const [modelTags, setModelTags] = useState([]);
  const [yearTags, setYearTags] = useState([]);
  const [colorTags, setColorTags] = useState([]);
  const [rooftopTag, setRooftopTag] = useState(getRooftop());
  const [customerNameTags, setCustomerNameTags] = useState([]);
  const [customerId, setCustomerId] = useState(null);
  const [userNameTags, setUserNameTags] = useState([]);

  const fetchCustomerId = async () => {
    if (ctx.customerOverride) {
      setCustomerId(ctx.customerOverride);
    } else if (localStorage.getItem('selectedCustomerId')) {
      const id = parseInt(localStorage.getItem('selectedCustomerId'));
      setCustomerId(id);
    } else {
      const id = await getCustomerId();
      setCustomerId(id);
    }
  };

  useEffect(() => {
    fetchCustomerId();
  }, [ctx.customerOverride]);

  useEffect(() => {
    log && console.log(`Dashboard loading...`);
    if (props.location && props.location.state && props.location.state.insertedMove) {
      log &&
        console.log(
          `Detected route to this page from move planner. Auto refreshing to show new move...`,
          props.location.state.insertedMove
        );
      doRefetch();
    }
  }, []);

  let _refetch = null;
  const doRefetch = () => {
    if (_refetch) {
      log && console.log(`Performing refresh of data...`);
      _refetch();
    } else {
      log && console.log(`Tried performing refresh of data but _refetch is not set`);
    }
  };

  let moves = [];

  const handleRangeChange = event => {
    const value = event.target.value;
    localStorage.setItem(`dashboard-range`, value);

    setRange(value);
    setStart(getStart(value));
    setEnd(getEnd(value));
    setDisablePickers(getDisable(value));
  };

  const handleDateChange = name => event => {
    if (name === `start`) {
      const newStart = dayjs(event).startOf(`day`).format();
      const dateIsValid = new Date(newStart).getTime() > 0;
      if (!dateIsValid) return;
      localStorage.setItem(`dashboard-start`, newStart);
      setStart(newStart);
    } else {
      const newEnd = dayjs(event).endOf(`day`).format();
      const dateIsValid = new Date(newEnd).getTime() > 0;
      if (!dateIsValid) return;
      localStorage.setItem(`dashboard-end`, newEnd);
      setEnd(newEnd);
    }
  };

  const handleSort = event => {
    setSort(event.target.value);
  };

  const handleSearch = event => {
    setSearch(event.target.value.toLowerCase());
  };

  const handleClearFilters = () => {
    setDisablePickers(true);
    setRange(`3day`);
    setStart(getStart(`3day`));
    setEnd(getEnd(`3day`));
    setSort(`new`);
    setSearch(``);
    setStatusTags([]);
    setMakeTags([]);
    setModelTags([]);
    setYearTags([]);
    setColorTags([]);
    setCustomerNameTags([]);
    setUserNameTags([]);
    setConciergeTag(false);
    setRooftopTag(false);
    setHangtagTag(false);
    localStorage.removeItem(`dashboard-group-concierge`);
    localStorage.removeItem(`dashboard-group-rooftop`);
    localStorage.removeItem(`dashboard-group-hangtag`);
  };

  const getFixedMoves = movesData => {
    let sortedMoves;

    if (sort === `new`)
      sortedMoves = movesData.sort((a, b) => dayjs(b.pickup_time).unix() - dayjs(a.pickup_time).unix());
    else if (sort === `old`)
      sortedMoves = movesData.sort((a, b) => dayjs(a.pickup_time).unix() - dayjs(b.pickup_time).unix());
    else if (sort === `status`)
      sortedMoves = movesData.sort((a, b) => {
        let valA =
          a.cancel_status && a.cancel_status !== null
            ? `aaa${a.cancel_status.toLowerCase()}`
            : a.status && a.status !== null
            ? a.status.toLowerCase()
            : `zzz`;
        let valB =
          b.cancel_status && b.cancel_status !== null
            ? `aaa${b.cancel_status.toLowerCase()}`
            : b.status && b.status !== null
            ? b.status.toLowerCase()
            : `zzz`;
        return valA > valB ? 1 : valA < valB ? -1 : 0;
      });
    else if (sort === `make`)
      sortedMoves = movesData.sort((a, b) => {
        let valA = a.vehicle_make && a.vehicle_make !== null ? a.vehicle_make.toLowerCase() : `zzz`;
        let valB = b.vehicle_make && b.vehicle_make !== null ? b.vehicle_make.toLowerCase() : `zzz`;
        return valA > valB ? 1 : valA < valB ? -1 : 0;
      });
    else if (sort === `model`)
      sortedMoves = movesData.sort((a, b) => {
        let valA = a.vehicle_model && a.vehicle_model !== null ? a.vehicle_model.toLowerCase() : `zzz`;
        let valB = b.vehicle_model && b.vehicle_model !== null ? b.vehicle_model.toLowerCase() : `zzz`;
        return valA > valB ? 1 : valA < valB ? -1 : 0;
      });
    else if (sort === `year`)
      sortedMoves = movesData.sort((a, b) => {
        let valA = a.vehicle_year && a.vehicle_year !== null ? a.vehicle_year : `0`;
        let valB = b.vehicle_year && b.vehicle_year !== null ? b.vehicle_year : `0`;
        return valA < valB ? 1 : valA > valB ? -1 : 0;
      });
    else if (sort === `color`)
      sortedMoves = movesData.sort((a, b) => {
        let valA = a.vehicle_color && a.vehicle_color !== null ? a.vehicle_color.toLowerCase() : `zzz`;
        let valB = b.vehicle_color && b.vehicle_color !== null ? b.vehicle_color.toLowerCase() : `zzz`;
        return valA > valB ? 1 : valA < valB ? -1 : 0;
      });

    let filterResults = sortedMoves;
    if (statusTags && statusTags.length > 0) {
      filterResults = filterResults.filter(move => {
        if (move.cancel_status && statusTags.includes(`Canceled`)) return true;
        if (
          !move.cancel_status &&
          (!move.status || (move.status && move.status === `dispatched`)) &&
          statusTags.includes(`Planning`)
        )
          return true;
        if (!move.cancel_status && move.status && move.status.includes(`pickup`) && statusTags.includes(`Pickup`))
          return true;
        if (
          !move.cancel_status &&
          move.status &&
          move.status.includes(`delivery`) &&
          !move.status.includes(`delivery successful`) &&
          statusTags.includes(`Delivery`)
        )
          return true;
        if (
          !move.cancel_status &&
          move.status &&
          move.status === `delivery successful` &&
          statusTags.includes(`Completed`)
        )
          return true;
        return false;
      });
    }
    if (makeTags && makeTags.length > 0) {
      filterResults = filterResults.filter(move => {
        if (move.vehicle_make && makeTags.includes(move.vehicle_make)) return true;
        return false;
      });
    }
    if (modelTags && modelTags.length > 0) {
      filterResults = filterResults.filter(move => {
        if (move.vehicle_model && modelTags.includes(move.vehicle_model)) return true;
        return false;
      });
    }
    if (yearTags && yearTags.length > 0) {
      filterResults = filterResults.filter(move => {
        if (move.vehicle_year && yearTags.includes(move.vehicle_year)) return true;
        return false;
      });
    }
    if (colorTags && colorTags.length > 0) {
      filterResults = filterResults.filter(move => {
        if (move.vehicle_color && colorTags.includes(move.vehicle_color)) return true;
        return false;
      });
    }
    if (customerNameTags && customerNameTags.length > 0) {
      filterResults = filterResults.filter(move => {
        if (move.customer && move.customer.name && customerNameTags.includes(move.customer.name)) return true;
        return false;
      });
    }

    if (userNameTags && userNameTags.length > 0) {
      filterResults = filterResults.filter(move => {
        const user = move.createdBy
          ? move.createdBy
          : move.eventlogs && move.eventlogs.length > 0
          ? move.eventlogs[0].user
          : null;
        return user && userNameTags.some(tag => tag === user);
      });
    }

    let searchResults = filterResults;
    if (search && search.length > 0)
      searchResults = searchResults.filter(
        move =>
          (move.id && (move.id + ``).toLocaleLowerCase().includes(search)) ||
          (move.lane ? move.lane.description && move.lane.description.toLocaleLowerCase().includes(search) : false) ||
          (move.reference_num && move.reference_num.toLocaleLowerCase().includes(search)) ||
          (move.vehicle_stock && move.vehicle_stock.toLocaleLowerCase().includes(search)) ||
          (move.vehicle_vin && move.vehicle_vin.toLocaleLowerCase().includes(search)) ||
          (move.vehicle_make && move.vehicle_make.toLocaleLowerCase().includes(search)) ||
          (move.vehicle_model && move.vehicle_model.toLocaleLowerCase().includes(search)) ||
          (move.vehicle_year && move.vehicle_year.toLocaleLowerCase().includes(search)) ||
          (move.vehicle_color && move.vehicle_color.toLocaleLowerCase().includes(search)) ||
          (move.consumer_name && move.consumer_name.toLocaleLowerCase().includes(search)) ||
          (move.consumer_phone &&
            move.consumer_phone.replace(/\D/g, '') !== '' &&
            search.replace(/\D/g, '') !== '' &&
            move.consumer_phone.replace(/\D/g, '').includes(search.replace(/\D/g, '')))
      );
    // log && console.log(`Search Results`, searchResults);

    let activeMoves = searchResults.filter(
      move =>
        move.status &&
        (move.status.includes(`pickup`) || move.status.includes(`delivery`)) &&
        move.status !== `delivery successful` &&
        move.cancel_status === null
    );
    let plannedMoves = searchResults.filter(
      move => (move.status === null || move.status === `dispatched`) && move.cancel_status === null
    );
    let finishedMoves = searchResults.filter(
      move => move.status === `delivery successful` || move.cancel_status !== null
    );

    // log && console.log(`activeMoves:`, activeMoves);
    // log && console.log(`plannedMoves:`, plannedMoves);
    // log && console.log(`finishedMoves:`, finishedMoves);

    return { activeMoves, plannedMoves, finishedMoves, searchResults };
  };

  const getMovesVariables = () => {
    let variables = {};
    variables.customerId = customerId || 0;
    variables.start = start;
    variables.end = end;
    // variables.payerIdArray = uniqueCustomersArray;
    return variables;
  };

  return (
    <>
      <div className={cls.root}>
        <Container maxWidth='xl'>
          {ctx && ctx.firebaseUser && !ctx.loading && customerId ? (
            <Query
              query={GET_SPECIFIC_CUSTOMER_MOVES}
              variables={getMovesVariables()}
              onError={error => {
                console.error(error);
                Sentry.captureException(error);
              }}
              fetchPolicy='no-cache'
            >
              {({ loading, data, error, refetch }) => {
                _refetch = refetch; //Make it available everywhere for event handling
                if (loading) return <Loading fixed />;
                if (error) console.error('Error fetching moves:', error);
                if (data && data.moves) {
                  moves = getFixedMoves(data.moves);
                  log && console.log(`Fixed Moves`, moves);
                  // The customer is a third party payer if the query has returned moves from more than one customer
                  const firstMoveCustomerId =
                    data.moves.length && data.moves[0].customer && data.moves[0].customer.id
                      ? data.moves[0].customer.id
                      : 0;
                  const findOtherCustomerMove = move => {
                    let customerId = move && move.customer && move.customer.id ? move.customer.id : 0;
                    if (!customerId || !firstMoveCustomerId) return false;
                    if (customerId !== firstMoveCustomerId) return true;
                    return false;
                  };
                  const moveFromDifferentCustomer = data.moves.find(move => findOtherCustomerMove(move));

                  return (
                    <>
                      <div className={cls.contain}>
                        {data.moves.length > 0 ? (
                          <>
                            <div className={cls.filter}>
                              <DashboardFilter
                                moves={data.moves}
                                handleClearFilters={handleClearFilters}
                                customerIsThirdPartyPayer={moveFromDifferentCustomer ? true : false}
                                tags={{
                                  concierge: conciergeTag,
                                  hangtag: hangtagTag,
                                  status: statusTags,
                                  make: makeTags,
                                  model: modelTags,
                                  year: yearTags,
                                  customerNames: customerNameTags,
                                  color: colorTags,
                                  rooftop: rooftopTag,
                                  userNames: userNameTags,
                                }}
                                setTags={{
                                  concierge: setConciergeTag,
                                  hangtag: setHangtagTag,
                                  status: setStatusTags,
                                  make: setMakeTags,
                                  model: setModelTags,
                                  year: setYearTags,
                                  color: setColorTags,
                                  rooftop: setRooftopTag,
                                  customerNames: setCustomerNameTags,
                                  userNames: setUserNameTags,
                                }}
                              />
                            </div>
                          </>
                        ) : null}

                        <div className={cls.grid}>
                          <Grid container spacing={2} alignItems='center'>
                            {/* TITLE */}
                            <Grid item>
                              <Typography className={cls.head}>Dashboard</Typography>
                            </Grid>

                            {/* BUTTON */}
                            <Grid item>
                              <Link to='/moves/add'>
                                <Button color='primary' data-testid='add-moves-button' size='large'>
                                  Add&nbsp;Moves
                                </Button>
                              </Link>
                            </Grid>

                            {/* CONTROLS */}
                            <Grid item xs>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid container spacing={2} alignItems='center'>
                                  <Grid item xl={2} sm={4} xs={12}>
                                    <TextField
                                      select
                                      fullWidth
                                      label='Date Range'
                                      placeholder='Select a date range...'
                                      variant='outlined'
                                      size='small'
                                      value={range}
                                      onChange={handleRangeChange}
                                    >
                                      <MenuItem value={`day`}>Today</MenuItem>
                                      <MenuItem value={`3day`}>Past 3 Days</MenuItem>
                                      <MenuItem value={`week`}>Past Week</MenuItem>
                                      <MenuItem value={`2week`}>Past 2 Weeks</MenuItem>
                                      <MenuItem value={`month`}>Past Month</MenuItem>
                                      <MenuItem value={`custom`}>Custom</MenuItem>
                                    </TextField>
                                  </Grid>

                                  <Grid item xl={2} sm={4} xs={12}>
                                    <DatePicker
                                      disabled={disablePickers}
                                      autoOk
                                      maxDate={end}
                                      label='Start Date'
                                      format='MM/dd/yyyy'
                                      orientation='portrait'
                                      size='small'
                                      variant='inline'
                                      inputVariant='outlined'
                                      value={start}
                                      onChange={handleDateChange(`start`)}
                                      className={cls.date}
                                    />
                                  </Grid>

                                  <Grid item xl={2} sm={4} xs={12}>
                                    <DatePicker
                                      disabled={disablePickers}
                                      autoOk
                                      minDate={start}
                                      label='End Date'
                                      format='MM/dd/yyyy'
                                      orientation='portrait'
                                      size='small'
                                      variant='inline'
                                      inputVariant='outlined'
                                      value={end}
                                      onChange={handleDateChange(`end`)}
                                      className={cls.date}
                                    />
                                  </Grid>

                                  <Grid item xl={3} sm={6} xs={12}>
                                    <TextField
                                      fullWidth
                                      select
                                      label='Sort By'
                                      placeholder='Sort moves by...'
                                      variant='outlined'
                                      margin='none'
                                      value={sort}
                                      onChange={handleSort}
                                      className={cls.actionSort}
                                    >
                                      <MenuItem value={`new`}>Pickup Time (Newest)</MenuItem>
                                      <MenuItem value={`old`}>Pickup Time (Oldest)</MenuItem>
                                      <MenuItem value={`status`}>Move Status</MenuItem>
                                      <MenuItem value={`make`}>Vehicle Make</MenuItem>
                                      <MenuItem value={`model`}>Vehicle Model</MenuItem>
                                      <MenuItem value={`year`}>Vehicle Year</MenuItem>
                                      <MenuItem value={`color`}>Vehicle Color</MenuItem>
                                    </TextField>
                                  </Grid>

                                  <Grid item xl={3} sm={6} xs={12}>
                                    <TextField
                                      fullWidth
                                      label='Search'
                                      placeholder='Search for a move...'
                                      variant='outlined'
                                      margin='none'
                                      onChange={handleSearch}
                                      className={cls.actionSearch}
                                      InputProps={{
                                        startAdornment: (
                                          <>
                                            <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                                              <Icon color='disabled' fontSize='small'>
                                                search
                                              </Icon>
                                            </InputAdornment>
                                          </>
                                        ),
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </MuiPickersUtilsProvider>
                            </Grid>
                          </Grid>

                          {conciergeTag &&
                          rooftopTag &&
                          moves &&
                          moves.searchResults &&
                          moves.searchResults.length > 0 ? (
                            <>
                              <DashboardGridByCustomerAndRooftop moves={moves.searchResults} />
                            </>
                          ) : null}

                          {!rooftopTag &&
                          conciergeTag &&
                          moves &&
                          moves.searchResults &&
                          moves.searchResults.length > 0 ? (
                            <>
                              <DashboardGridByCustomer moves={moves.searchResults} />
                            </>
                          ) : null}

                          {!conciergeTag &&
                          rooftopTag &&
                          moves &&
                          moves.searchResults &&
                          moves.searchResults.length > 0 ? (
                            <>
                              <DashboardGridByRooftop moves={moves.searchResults} />
                            </>
                          ) : null}

                          {hangtagTag && moves && moves.searchResults && moves.searchResults.length > 0 ? (
                            <>
                              <DashboardGridByHangtag moves={moves.searchResults} />
                            </>
                          ) : null}

                          {moves && moves.activeMoves && moves.activeMoves.length > 0 ? (
                            <>
                              <Divide spacer tip={`Track moves that are currently in-progress.`}>
                                Active Moves
                              </Divide>
                              <DashboardGrid moves={moves.activeMoves} />
                            </>
                          ) : null}

                          {moves && moves.plannedMoves && moves.plannedMoves.length > 0 ? (
                            <>
                              <Divide
                                spacer
                                tip={`Track moves that will be worked soon (Note: Pickup times may change while being planned).`}
                              >
                                Upcoming Moves
                              </Divide>
                              <DashboardGrid moves={moves.plannedMoves} />
                            </>
                          ) : null}

                          {moves && moves.finishedMoves && moves.finishedMoves.length > 0 ? (
                            <>
                              <Divide
                                spacer
                                tip={`Track moves that have been completed or canceled within your selected date range.`}
                              >
                                Completed Moves
                              </Divide>
                              <DashboardGrid moves={moves.finishedMoves} />
                            </>
                          ) : null}

                          {moves &&
                          moves.activeMoves.length === 0 &&
                          moves.plannedMoves.length === 0 &&
                          moves.finishedMoves.length === 0 ? (
                            <>
                              <Divide spacer tip={`No moves were found within your selected filtering.`}>
                                Moves
                              </Divide>
                              <div className={cls.notFound}>
                                <Typography className={cls.notFoundTxt}>
                                  NO MOVES FOUND WITH SELECTED FILTERS
                                </Typography>
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </>
                  );
                }
                return null;
              }}
            </Query>
          ) : (
            <Loading fixed />
          )}
        </Container>
      </div>
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  contain: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexWrap: 'nowrap',
  },
  filter: {
    verticalAlign: 'top',
    position: 'relative',
    marginRight: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  grid: {
    verticalAlign: 'top',
    position: 'relative',
    width: '100%',
  },
  head: {
    lineHeight: 1,
    fontSize: 24,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 21,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  date: {
    width: '100%',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
  },
  actionSearch: {
    minWidth: '160px',
    '& .MuiOutlinedInput-root': {
      height: '42px',
    },
  },
  actionSort: {
    minWidth: '160px',
    '& .MuiOutlinedInput-root': {
      height: '42px',
    },
  },
  notFound: {
    padding: theme.spacing(4),
    borderRadius: theme.shape.paperRadius,
    marginLeft: 'auto',
    marginRight: 'auto',
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
}));

//////////////////////// GQL ////////////////////////

const GET_SPECIFIC_CUSTOMER_MOVES = gql`
  query get_specific_customer_moves($customerId: bigint!, $start: timestamptz!, $end: timestamptz!) {
    moves(
      where: {
        active: { _eq: 1 }
        move_type: { _eq: "drive" }
        pickup_time: { _gte: $start, _lte: $end }
        _or: [{ customer_id: { _eq: $customerId } }, { payer_id: { _eq: $customerId } }]
      }
      order_by: { pickup_time: desc }
      limit: 100
    ) {
      id
      cancel_reason
      cancel_status
      chargeable
      consumer_at_pickup
      consumer_name
      consumer_phone
      consumer_pickup
      consumer_type
      deliver_by
      delivery_arrived
      delivery_started
      delivery_successful
      delivery_time
      manual_flag
      pickup_arrived
      pickup_started
      pickup_successful
      pickup_time
      ready_by
      reference_num
      status
      tags
      vehicle_color
      vehicle_make
      vehicle_model
      vehicle_stock
      vehicle_vin
      vehicle_year
      customer {
        id
        name
      }
      hangtags(order_by: { updated_at: desc }) {
        id
        hash_id
        rear_code
        status
        type
        updated_at
        workflow_data
      }
      lane {
        id
        description
      }
      movesByReturnRideId {
        id
        consumer_at_pickup
        consumer_name
        consumer_phone
        consumer_pickup
        consumer_type
      }
      eventlogs(where: { actiontype_id: { _eq: 1 } }) {
        user
      }
    }
  }
`;
