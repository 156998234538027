import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

////////// COMPONENT //////////
export default function TypeForm(props) {
  const cls = useStyles();

  const { type, onTypeChange } = props;

  const handleTypeChange = (type, count) => {
    if (onTypeChange) onTypeChange(type, count);
  };

  return (
    <div className={cls.group}>
      <div className={type === `concierge` ? cls.boxActive : cls.box} data-testid='concierge-move' onClick={() => handleTypeChange(`concierge`, 1)}>
        <Typography className={cls.key}>Concierge</Typography>
        <Typography className={cls.val}>
          Retrieve your customer's vehicle and return it to the service&nbsp;lot.
        </Typography>
      </div>

      <div className={type === `loaner` ? cls.boxActive : cls.box} data-testid='loaner-move' onClick={() => handleTypeChange(`loaner`, 2)}>
        <Typography className={cls.key}>Concierge +&nbsp;Loaner</Typography>
        <Typography className={cls.val}>
          Assign a loaner vehicle to the customer and retrieve their vehicle for&nbsp;service.
        </Typography>
      </div>

      <div className={type === `one-way` ? cls.boxActive : cls.box} data-testid='one-way-move' onClick={() => handleTypeChange(`one-way`, 1)}>
        <Typography className={cls.key}>One-Way</Typography>
        <Typography className={cls.val}>Move a single vehicle to a different&nbsp;lot.</Typography>
      </div>

      <div
        className={type === `round-trip` ? cls.boxActive : cls.box}
        data-testid='round-trip-move'
        onClick={() => handleTypeChange(`round-trip`, 2)}
      >
        <Typography className={cls.key}>Round-Trip</Typography>
        <Typography className={cls.val}>Swap two vehicles between&nbsp;lots.</Typography>
      </div>

      {/* <div className={type === `milk-run` ? cls.boxActive : cls.box} onClick={() => handleTypeChange(`milk-run`, null)}>
        <Typography className={cls.key}>Milk-Run</Typography>
        <Typography className={cls.val}>Move several vehicles between a chain&nbsp;of&nbsp;lots.</Typography>
      </div> */}
    </div>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  group: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    margin: '-8px',
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'center',
    width: '180px',
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.border}`,
    borderRadius: `8px`,
    margin: theme.spacing(1),
    background: theme.palette.background.main,
    '&:hover': {
      border: `1px solid #00000000`,
      backgroundColor: theme.palette.action.focus,
    },
    transition: '0.2s',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      width: '160px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '132px',
    },
  },
  boxActive: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'center',
    width: '180px',
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: `8px`,
    margin: theme.spacing(1),
    background: theme.palette.primary.main,
    color: '#fff',
    transition: '0.2s',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      width: '160px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '132px',
    },
  },
  key: {
    marginBottom: theme.spacing(1.25),
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
      fontSize: '21px',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(0.75),
      fontSize: '18px',
    },
  },
  val: {
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
}));
