import React, { useEffect, useState } from 'react';
import { useData } from '../../../DataProvider';
import { getCustomerId } from '../../utils/authHelper.js';
import { makeStyles, TextField, MenuItem, InputAdornment, Tooltip, Icon, Button } from '@material-ui/core';

import gql from 'graphql-tag';

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  main: {
    maxWidth: '256px',
    marginRight: theme.spacing(2),
  },
  textField: {
    borderRadius: '4px',
    margin: 0,
    background: '#ffffff24',
    color: '#fff',
    '&:hover': {
      background: '#ffffff36',
    },
    transition: '0.2s',
    '& label.Mui-focused': {
      color: '#fff',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#fff',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#ffffff00',
      },
      '&:hover fieldset': {
        borderColor: '#ffffff00',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#ffffff00',
      },
    },
  },
  input: {
    color: '#fff',
  },
  icon: {
    color: '#fff',
  },
  //make a class for the button style
  refreshButton: {
    float: 'right',
    marginLeft: theme.spacing(1),
    boxShadow: 'none',
    color: 'white',
    border: '1px solid white',
    borderRadius: '4px',
    padding: '4px 8px',
    fontSize: '12px',
    '&:hover': {
      background: '#ffffff36',
    },
    transition: '0.2s',
  },
}));


////////// COMPONENT //////////
export default function CustomerSelect(props) {
  const ctx = useData();
  const cls = useStyles();

  const [customerId, setCustomerId] = useState(null);
  const [customers, setCustomers] = useState({});

  useEffect(() => {
    fetchCustomerId();
  }, [ctx.firebaseUser]);

  useEffect(() => {
    getCustomers();
  }, [customerId]);

  useEffect(() => {
    if (ctx.customerOverride && customerId && customerId !== parseInt(ctx.customerOverride))
      setCustomerId(parseInt(ctx.customerOverride));
  }, [customerId, ctx.customerOverride]);

  const handleChange = event => {
    const value = event.target && event.target.value ? event.target.value : 0;
    localStorage.setItem('selectedCustomerId', value);
    ctx.setCustomerOverride(value);
  };

  const fetchCustomerId = async () => {
    const id = await getCustomerId();
    let defaultCustomerId;
    if (ctx.customerOverride) {
      defaultCustomerId = parseInt(ctx.customerOverride);
    } else if (id) {
      defaultCustomerId = id;
    } else defaultCustomerId = 0;
    setCustomerId(defaultCustomerId);
  };

  const getCustomers = async () => {
    try {
      const res = await ctx.apolloClient.query({
        query: GET_CUSTOMERS,
      });
      /*********
       * Excluding 3rd party payers from the list of customers
       * Check to see if there are props, if so, filter the customers array based on those to get the name and id
       * Prop values are only the customer ids of the customers that should be displayed
       */
      if (props.customersArray && props.customersArray.length > 0) {
        const filteredCustomers = res.data.customers.filter(customer => props.customersArray.includes(customer.id));
        setCustomers(filteredCustomers);
      } else {
        setCustomers(res.data.customers);
      }
    } catch (error) {
      console.error('Failed to retrieve customers:', error);
    }
  };

  return (
    <>
      {customers && customers.length > 0 ? (
        <div className={cls.main}>
          <TextField
            id='customer-select-dropdown'
            data-testid='customer-select-dropdown'
            select
            fullWidth
            placeholder='Select a dealer...'
            variant='outlined'
            margin='dense'
            value={customerId || 0}
            onChange={handleChange}
            InputProps={{
              className: cls.input,
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Tooltip title='Dealer Select'>
                    <Icon style={{ color: '#fff', cursor: 'default' }}>group</Icon>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            SelectProps={{
              inputProps: {
                classes: {
                  icon: cls.icon,
                },
              },
            }}
            className={cls.textField}
          >
            <MenuItem key={`customer-select-0`} value={0}>
              <i>select a customer...</i>
            </MenuItem>
            {customers.map(c => (
              <MenuItem data-testid='customer-select-item' key={`customer-select-${c.id}`} value={c.id}>
                {c.name}
              </MenuItem>
            ))}
          </TextField>
        </div>
      ) : (
        <div className={cls.main}>
          <Button
            className={cls.refreshButton}
            size='small'
            onClick={() => getCustomers()}
          >{`Refresh\xa0Customers`}</Button>
        </div>
      )}
    </>
  );
}

////////// GRAPHQL //////////
var GET_CUSTOMERS = gql`
  query get_customers {
    customers(where: { active: { _eq: 1 } }, order_by: { name: asc }) {
      id
      name
    }
  }
`;
