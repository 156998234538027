//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, Tooltip, IconButton, Icon } from '@material-ui/core';

//////////////////////// COMPONENT ////////////////////////

export default function IconBack({ className = null, tooltipPlacement = null, ...rest }) {
  const cls = useStyles();
  const history = useHistory();

  const goToPreviousPage = () => {
    history.goBack();
  };

  const getClassNames = () => {
    let classNames = [cls.iconBtn];
    if (className) classNames.push(className);
    return classNames.join(` `);
  };

  return (
    <Tooltip placement={tooltipPlacement || 'top'} title='Back'>
      <IconButton className={getClassNames()} onClick={() => goToPreviousPage()} {...rest}>
        <Icon>arrow_back</Icon>
      </IconButton>
    </Tooltip>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  iconBtn: {
    padding: theme.spacing(1),
  },
}));
