import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import DashboardTile from './DashboardTile';
import Divide from '../../reusable/Divide';
import BoldDivide from '../../reusable/BoldDivide';

////////// COMPONENT //////////
export default function DashboardGridByCustomerAndRooftop(props) {
  const cls = useStyles();

  const { moves } = props;

  const groupBy = (objectArray, ...properties) => {
    return [
      ...Object.values(
        objectArray.reduce((accumulator, object) => {
          const key = JSON.stringify(properties.map(x => object[x] || null));

          if (!accumulator[key]) {
            accumulator[key] = [];
          }
          accumulator[key].push(object);
          return accumulator;
        }, {})
      ),
    ];
  };

  const groupByCustomerId = movesArray => {
    const groupArray = [];
    const customerArray = movesArray.map(move => move.customer);
    //Sets can only contain unique values, so this will remove duplicates
    const uniqueCustomerArray = [...new Set(customerArray)];
    //Create a new array of objects that contain the name, id, and moves for each customer
    uniqueCustomerArray.forEach(customer => {
      if (!customer || !customer.id || !customer.name) return;
      let customerMovesArray = movesArray.filter(move => move && move.customer && move.customer.id === customer.id);
      const customerObj = { id: customer.id, name: customer.name, moves: customerMovesArray };
      groupArray.push(customerObj);
    });
    return groupArray;
  };

  return (
    <>
      {groupByCustomerId(moves).map(customer => (
        <React.Fragment key={`cgg-${customer.id}`}>
          {customer && customer.name && (
            <>
              <BoldDivide spacer color='dark'>
                {customer.name + ' moves'}
              </BoldDivide>
              <Grid container justifyContent='center' alignItems='flex-start' spacing={2}>
                {groupBy(customer.moves, 'consumer_name').map(consumerMoveGroup => (
                  <React.Fragment key={consumerMoveGroup[0].consumer_name}>
                    {/* {consumerMoveGroup[0].consumer_name !== null && ( */}
                    <>
                      <Divide spacer tip={`Track concierge moves for ${consumerMoveGroup[0].consumer_name}.`}>
                        {consumerMoveGroup[0].consumer_name || `No Customer`}
                      </Divide>
                      <Grid container justifyContent='center' alignItems='flex-start' spacing={2}>
                        {consumerMoveGroup.map(move => (
                          <Grid key={move.id} item xs className={cls.tile}>
                            <DashboardTile move={move} />
                          </Grid>
                        ))}
                      </Grid>
                    </>
                    {/* )} */}
                  </React.Fragment>
                ))}
              </Grid>
            </>
          )}
        </React.Fragment>
      ))}
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  tile: {
    position: 'relative',
    minWidth: '480px',
    maxWidth: '540px',
    [theme.breakpoints.down('md')]: {
      minWidth: '360px',
      maxWidth: '420px',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
      maxWidth: '100%',
    },
  },
}));
