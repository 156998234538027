//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { DataProvider } from './DataProvider';
import Layout from './components/Layout';
import Routes from './Routes';
import { withWidth } from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    default: {
      main: `#323640`,
      light: `#646c80`,
      lighter: `#a0a8b4`,
      dark: `#16181c`,
      contrastText: `#ffffff`,
    },

    primary: {
      main: `#f44232`,
      light: `#f88876`,
      lighter: `#ffdad8`,
      dark: `#b43220`,
      darker: `#a02416`,
      contrastText: `#ffffff`,
    },
    secondary: {
      main: `#486496`,
      light: `#7696c0`,
      lighter: `#dae8ff`,
      dark: `#2b3d59`,
      contrastText: `#ffffff`,
    },
    tertiary: {
      main: `#509090`,
      light: `#88b4b4`,
      dark: `#406464`,
      contrastText: `#ffffff`,
    },
    quaternary: {
      main: `#ffc050`,
      light: `#ffda99`,
      dark: `#e8a020`,
      contrastText: `#ffffff`,
    },

    info: {
      main: `#2080ff`,
      light: `#64b5f6`,
      dark: `#1976d2`,
      contrastText: `#ffffff`,
    },
    error: {
      main: `#ff2050`,
      lighter: `#ffe8f0`,
      light: `#ffa0a8`,
      dark: `#d41025`,
      contrastText: `#ffffff`,
    },
    warning: {
      main: `#ffa040`,
      light: `#ffb74d`,
      dark: `#f57c00`,
      contrastText: `#ffffff`,
    },
    success: {
      main: `#20c820`,
      lighter: `#d8f8d8`,
      light: `#80c880`,
      dark: `#3a8e3a`,
      contrastText: `#ffffff`,
    },

    nav: {
      prod: `#304054`,
      test: `#607084`,
    },

    ops: {
      main: `#f05020`,
    },
    concierge: {
      main: `#4080c8`,
    },
    lyft: {
      main: `#ea0b8c`,
    },
    uber: {
      main: `#000000`,
    },
    auto: {
      main: `#20c880`,
    },

    text: {
      primary: `#323640`,
      secondary: `#32364096`,
      disabled: `#32364072`,
      hint: `#32364072`,
      contrast: `#ffffff`,
      contrastFade: `#ffffff96`,
    },

    background: {
      paper: `#ffffff`,
      default: `#fafafa`,
      light: `#f8f8f8`,
      main: `#f4f4f4`,
      dark: `#f0f0f0`,
    },

    action: {
      hover: `#00000010`,
      selected: `#00000020`,
      focus: `#00000030`,
      active: `#00000080`,
      disabled: `#00000060`,
      disabledBackground: `#00000030`,
    },

    divider: `#00000020`,
    dividerStrong: `#e0e0e0`,
    softDivider: `#32323216`,
    hardDivider: `#32323232`,
    drop: `#32364010`,
    border: `#ddd`,

    fade: [
      `#00000010`,
      `#00000020`,
      `#00000030`,
      `#00000040`,
      `#00000050`,
      `#00000060`,
      `#00000080`,
      `#000000a0`,
      `#000000b4`,
      `#000000c8`,
    ],
  },

  border: [`1px solid #00000020`, `1px solid #00000040`, `1px solid #00000060`],

  shape: {
    borderRadius: `4px`,
    paperRadius: `8px`,
  },
  borderRadius: '4px',

  shadow: {
    none: `none`,
    soft: `0 0 16px #00000016`,
    medium: `0 0 16px #00000024`,
    harsh: `0 0 16px #00000040`,
    nav: `0 0 16px #00000040`,
  },
});

//////////////////////// COMPONENT ////////////////////////

function App(props) {

  return (
    // <Sentry.ErrorBoundary fallback={<TopLevelFallback />}>
      
      <DataProvider>
          <MuiThemeProvider theme={theme}>
            <Layout>
              <Routes />
            </Layout>
            </MuiThemeProvider>
      </DataProvider>

    // </Sentry.ErrorBoundary>
  );
}

//////////////////////// EXPORT ////////////////////////

export default withWidth()(App);
