//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { Grid, TextField, InputAdornment, Icon, Tooltip, Switch, FormControlLabel } from '@material-ui/core';
import { Button } from '@hopdrive/storybook';
import { useHistory } from 'react-router-dom';
import { getUserRole } from '../../utils/authHelper';

//////////////////////// COMPONENT ////////////////////////

function UsersFilter({ refetch, search, onSearchChange, allUsersChecked, setAllUsersChecked }) {
  const history = useHistory();

  const [role, setRole] = React.useState(null);

  const handleSwitch = event => {
    setAllUsersChecked(!allUsersChecked);
  };

  const handleSearchChange = event => {
    onSearchChange(event.target.value);
  };

  const fetchUserRole = async () => {
    const userRole = await getUserRole();
    if (userRole) setRole(userRole);
  };

  React.useEffect(() => {
    fetchUserRole();
  }, []);

  return (
    <>
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs>
          <TextField
            fullWidth
            label='Search'
            placeholder='Search table...'
            variant='outlined'
            size='small'
            value={search}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    search
                  </Icon>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

          <Tooltip title={role === 'admin' ? 'Please use Admin Portal to add new dealer users' : 'Click to add a new user to your organization'} placement='bottom'>
          <Grid item>

            <Button
              disabled={role === 'admin'}
              color='primary'
              size='large'
              onClick={() => {
                history.push(`/users/add`);
              }}
            >
              Add User
            </Button>

          </Grid>
          </Tooltip>

        <Grid item>
        <FormControlLabel
                style={{fontSize: '24px', fontWeight: 500}}
                control={
                  <Switch
                    checked={allUsersChecked}
                    onChange={handleSwitch}
                    inputProps={{ 'aria-label': 'all customers checkbox' }}
                  />
                }
                label={allUsersChecked ? 'Hide Inactive Users' : 'Show Inactive Users'}
              />   
        </Grid>
      </Grid>
    </>
  );
}

//////////////////////// EXPORT ////////////////////////

export default UsersFilter;
