import React from 'react';
import { makeStyles, Container } from '@material-ui/core';
import { Loading, Spacer } from '@hopdrive/storybook';

import * as Sentry from '@sentry/react';
import { useData } from '../../../DataProvider';
import { GET_USER_DETAILS} from './gql';

import DefaultEmptyFallback from '../../reusable/Fallbacks/DefaultEmptyFallback';
import DefaultErrorFallback from '../../reusable/Fallbacks/DefaultErrorFallback';
import Toolbar from '../../reusable/Toolbar';

import { UserDetailsProvider } from './UserDetailsProvider';
import UserDetails from './UserDetails';
import { Query } from 'react-apollo';
import { getUserRole } from '../../utils/authHelper';

//////////////////////// COMPONENT ////////////////////////

export default function UserDetailsWrapper(props) {
  const cls = useStyles();
  const ctx = useData();

  const userId = props.match.params.id;
  const [role, setRole] = React.useState(null);
  const fetchRole = async () => {
    let userRole = await getUserRole();
    if (userRole) setRole(userRole);
  }
  React.useEffect(() => {
    fetchRole();
  }, [])

  if (role === 'dealer' || role === 'dealer-admin') {
    return (
      <div className={cls.root}>
        <DefaultEmptyFallback message='RESTRICTED ACCESS' />
      </div>
    )
  } else if (role && role === 'dealer-super-admin'){
  return (
    <div className={cls.root}>
      {ctx && ctx.firebaseUser && userId && (
        <Query
          query={GET_USER_DETAILS}
          variables={{
            userId: userId,
          }}
          onError={error => {
            console.error(error);
            Sentry.captureException(error);
          }}
        >
          {({ loading, error, data, refetch }) => {
            // LOADING STATE //
            if (loading) {
              return (
                <div className={cls.root}>
                  <Container maxWidth='lg'>
                    <Toolbar back title='User Details' />
                    <Loading position='fixed' />
                  </Container>
                </div>
              );
            }

            // ERROR STATE //
            if (error) {
              console.error(`Error getting user details:`, error);
              Sentry.captureException(error);
              return (
                <div className={cls.root}>
                  <Container maxWidth='lg'>
                    <Toolbar back title='User Details' refetch={refetch} />
                    <Spacer />
                    <DefaultErrorFallback message='ERROR GETTING USER DETAILS' />
                  </Container>
                </div>
              );
            }

            // EMPTY STATE //
            if (!data || !data.users || !data.users.length > 0) {
              return (
                <div className={cls.root}>
                  <Container maxWidth='lg'>
                    <Toolbar back title='User Details' refetch={refetch} />
                    <Spacer />
                    <DefaultEmptyFallback message='NO USER FOUND' />
                  </Container>
                </div>
              );
            }

            if (data && data.users && data.users.length > 0) {
                const user = data.users[0];
                return (
                  <UserDetailsProvider user={user} refetch={refetch}>
                      <UserDetails user={user} refetch = {refetch}/>
                  </UserDetailsProvider>
                );
            }
          }}
        </Query>
      )}
    </div>
  )} else {
    return (
      <div className={cls.root}></div>
    )
  }
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(7),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
}));
