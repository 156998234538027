import React, { useState } from 'react';
import { makeStyles, Grid, Button, Icon } from '@material-ui/core';
import MethodCard from './MethodCard';
import MethodAddEditModal from './MethodAddEditModal';

////////// COMPONENT //////////
export default function MethodTable(props) {
  const cls = useStyles();

  const [modal, setModal] = useState({ open: false });
  const [primaryExists, setPrimaryExists] = useState(false)

  const { methods, refetch } = props;

  const handleModalOpen = () => {
    setModal({ ...modal, open: true });
  }
  const handleModalClose = () => {
    setModal({ ...modal, open: false });
    refetch()
  }
  const checkMethodsForPrimary = (methods) => {
    const primaryCard = methods.find(method=>method.primary)
    //double ! will reduce the primary card result to true/false
    setPrimaryExists(!!primaryCard)
  }
  React.useEffect(()=>{
    if(methods && Array.isArray(methods) && methods.length > 0){
      checkMethodsForPrimary(methods)
    }else{
      setPrimaryExists(false)
    }
  },[methods])

  return (<>
    <MethodAddEditModal open={modal.open} onClose={handleModalClose} primaryExists={primaryExists}/>

    <div className={cls.root}>
      <Grid container spacing={3}>
        {methods.length > 0 ?
          methods.map(m =>
            <React.Fragment key={m.id}>
              <Grid item md={6} xs={12}>
                <MethodCard method={m} refetch={refetch} />
              </Grid>
            </React.Fragment>
          ) : null
        }
        <React.Fragment key={0}>
          <Grid item md={6} xs={12}>
            <Button className={cls.addButton} variant="outlined" color="primary" style={{ fontSize: '16px' }} onClick={() => handleModalOpen()}><Icon className={cls.addIcon}>add</Icon>Add Payment Method</Button>
          </Grid>
        </React.Fragment>
      </Grid>
    </div>
  </>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  addButton: {
    width: '100%',
    height: '100%',
    minHeight: '198px',
    padding: '24px',
  },
  addIcon: {
    marginTop: '-2px',
    marginRight: theme.spacing(1),
    fontSize: '16px',
  },
}));