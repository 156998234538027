import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import DashboardTile from './DashboardTile';

const listOfColors = [
  `black`,
  `blue`,
  `brown`,
  `burgundy`,
  `gold`,
  `gray`,
  `green`,
  `orange`,
  `pink`,
  `purple`,
  `red`,
  `silver`,
  `tan`,
  `white`,
  `yellow`,
];

////////// COMPONENT //////////
export default function DashboardGrid(props) {
  const cls = useStyles();

  const { moves } = props;

  const getColor = colorData => {
    let color = colorData ? colorData.toLowerCase() : `default`;
    if (listOfColors.includes(color)) return color;
    else return `default`;
  };

  return (
    <>
      <Grid container justifyContent='center' alignItems='flex-start' spacing={2}>
        {moves.map(move => {
          return (
            <Grid key={move.id} item xs className={cls.tile}>
              <DashboardTile move={move} color={getColor(move.vehicle_color)} />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  tile: {
    position: 'relative',
    minWidth: '480px',
    maxWidth: '540px',
    [theme.breakpoints.down('md')]: {
      minWidth: '360px',
      maxWidth: '420px',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
      maxWidth: '100%',
    },
  },
}));
