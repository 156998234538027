import React from 'react';
import { toast } from 'react-toastify';
import { makeStyles, Container } from '@material-ui/core';
import { Loading, Spacer } from '@hopdrive/storybook';
import { sendPasswordResetEmail } from '../../utils/authHelper';

import { REFRESH_CLAIMS, GET_CUSTOMERS } from './gql';

import { DefaultEmptyFallback } from '../../reusable/Fallbacks';
import Toolbar from '../../reusable/Toolbar';

import sdk from '@hopdrive/sdk';
import { UserDetailsProvider } from './UserDetailsProvider'
import UserDetailsInfo from './UserDetailsInfo';
import UserDetailsToolbar from './UserToolbar';
import TerminationModal from './TerminationModal';
import ReinstateModal from './ReinstateModal';

//////////////////////// COMPONENT ////////////////////////

export default function UserDetails({user, refetch}) {
  const cls = useStyles();

  const [terminationModal, setTerminationModal] = React.useState({ open: false, input: null, output: null });
  const handleTerminationModalOpen = (input = null) => {
    setTerminationModal({ open: true, input: input, output: null });
  };
  const handleTerminationModalClose = async (output = null) => {
    setTerminationModal({ open: false, input: null, output: output });
  };

  const [reinstateModal, setReinstateModal] = React.useState({ open: false, input: null, output: null });
  const handleReinstateModalOpen = (input = null) => {
    setReinstateModal({ open: true, input: input, output: null });
  };
  const handleReinstateModalClose = async (output = null) => {
    setReinstateModal({ open: false, input: null, output: output });
  };

  const refreshClaims = async () => {
    try {
      const refreshRes = await sdk.gql.mutation(REFRESH_CLAIMS,
        { userId: user.id });
        console.log('refreshRes', refreshRes);
        if (refreshRes && refreshRes.success) {
          toast.success('Successfully refreshed claims');
        } else {
          toast.error('Could not refresh claims')
        }
    } catch (err) {
      console.log('Error refreshing claims:', err);
      toast.error('Error refreshing claims');
    }

  } 


  const [customerSelections, setCustomerSelections] = React.useState([])

  React.useEffect(() => {
    const fetchCustomers = async () => {
        try {
            const res = await sdk.gql.query(GET_CUSTOMERS, { customerId: user.customer_id });
            if (res && res.data && res.data.length && res.data.length > 0 && res.data[0].organization && res.data[0].organization.customers) {
              setCustomerSelections(res.data[0].organization.customers)
            }
        } catch (err) {
            console.log('Error fetching customers:', err);
            toast.error('Error fetching customers');
        }
    }
    if (user && user.customer_id) {
      fetchCustomers(user.customer_id);
    }
  
  }, [user])

  // Handle refetch callback to pass as props
  const handleRefetch = () => {
    refetch();
  };

  // EMPTY STATE //
  if (!user ) {
    return (
      <div className={cls.root}>
        <Container maxWidth='lg'>
          <Toolbar back title='User Details' refetch={handleRefetch} />
          <Spacer />
          <DefaultEmptyFallback message='NO USER FOUND' />
        </Container>
      </div>
    );
  }

  // DATA STATE //

  const userActions = [
    {
      label: `Reset Password`,
      handler: async () => {
        await sendPasswordResetEmail('reset password', user.email, user.firebase_id);
      },
    },
    {
      label: `Refresh Auth Claims`,
      handler: async () => {
        await refreshClaims();
      },
    },
    {
      label: `Deactivate User`,
      handler: () => handleTerminationModalOpen({ user, refetch: handleRefetch }),
      disabled: !user.active,
    },
    {
      label: `Reinstate User`,
      handler: () => handleReinstateModalOpen({ user, refetch: handleRefetch }),
      disabled: user.active,
    },
  ];

  return (
    <UserDetailsProvider user={user} refetch={handleRefetch}>
      <TerminationModal
        open={terminationModal.open}
        input={terminationModal.input}
        onClose={handleTerminationModalClose}
      />

      <ReinstateModal
        open={reinstateModal.open}
        input={reinstateModal.input}
        onClose={handleReinstateModalClose}
      />

      <div className={cls.root}>
        <Container maxWidth='lg'>
          <Toolbar back title='User Details' refetch={handleRefetch} actions={userActions}>
            <UserDetailsToolbar user={user}/>
          </Toolbar>

          <Spacer />

          <UserDetailsInfo user={user} customerSelections={customerSelections} setCustomerSelections={setCustomerSelections}/>

          <Spacer />
        </Container>
      </div>
    </UserDetailsProvider>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(7),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
}));