//////////////////////// DEPENDENCIES ////////////////////////

import React, { Children, useRef } from 'react';
import {
  makeStyles,
  TableContainer,
  Table as MUITable,
  Tooltip,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  Checkbox,
  Typography,
  Popover,
  ClickAwayListener,
} from '@material-ui/core';
import { useTools } from '../../hooks/useTools';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';

import { getComparator, stableSort } from '../../utils/tableSort';
import Pagination from './Pagination';

//////////////////////// COMPONENT ////////////////////////
export function Column() {}

export default function Table(props) {
  const {
    data = [],
    columnConfig,
    onRowClick,
    rowActions,
    rowKeyCalculator,
    cellKeyCalculator,
    defaultOrder = 'desc',
    defaultOrderBy = 'id',
    defaultRowsPerPage = 50,
    tableAriaLabel = 'data-table',
    stickyHeader = false,
    padding = 'normal',
    size = 'small',
    classes,
    children,
    //used for pagination
    usePagination = false,
    setPaginationData = () => {},
    paginationData = {},
    totalRecordsCount = data.length,
    //used for header checkbox
    checkedItems = [],
    setCheckedItems = () => {},
    totalRecordLimit = null,
    setTotalRecordLimit = () => {},
  } = props;
  const cls = useStyles();
  const { getReadableText, functionName } = useTools();
  const popoverRef = useRef(null);

  const [order, setOrder] = React.useState(defaultOrder);
  const [orderBy, setOrderBy] = React.useState(defaultOrderBy);
  const [limit, setLimit] = React.useState(null);
  const [offset, setOffset] = React.useState(null);
  const [page, setPage] = React.useState(paginationData  && paginationData.page ? paginationData.page : null);
  const [rowsPerPage, setRowsPerPage] = React.useState(defaultRowsPerPage);
  const [headerChecked, setHeaderChecked] = React.useState(false);
  const [rowDeselect, setRowDeselect] = React.useState(false);
  const [allDeselect, setAllDeselect] = React.useState(false);
  const [allSelect, setAllSelect] = React.useState(false);
  const [pageSelect, setPageSelect] = React.useState(false);
  const [pageDeselect, setPageDeselect] = React.useState(false);
  const [openCheckboxPopover, setOpenCheckboxPopover] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    const handleClickOutside = event => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        handleClosePopover();
      }
    };

    if (anchorEl) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [anchorEl]);

  React.useEffect(() => {
    let checkedItemsSet;
    let rowsToVerify;
    if (checkedItems && checkedItems.length > 0) {
      checkedItemsSet = new Set(checkedItems);
    }
    if (data && data.length > rowsPerPage) {
      rowsToVerify = stableSort(data, getComparator(order, orderBy)).slice(
        (page || 0) * rowsPerPage,
        (page || 0) * rowsPerPage + rowsPerPage
      );
    } else rowsToVerify = data;

    if (allSelect) {
      if (checkedItems && checkedItems.length > 0) {
        let itemsToAdd = [];
        data.forEach(item => {
          if (!checkedItemsSet.has(item)) {
            itemsToAdd.push(item);
          }
        });
        if (itemsToAdd && itemsToAdd.length > 0) {
          setCheckedItems([...checkedItems, ...itemsToAdd]);
        }
      } else {
        setCheckedItems([...data]);
      }
      setHeaderChecked(true);
      setAllSelect(false);
    } else if (allDeselect) {
      setCheckedItems([]);
      setAllDeselect(false);
      setHeaderChecked(false);
    } else if (pageSelect) {
      if (checkedItems && checkedItems.length > 0) {
        rowsToVerify.forEach(item => {
          if (!checkedItemsSet.has(item)) {
            checkedItemsSet.add(item);
          }
        });
        setCheckedItems([...checkedItemsSet]);
        setHeaderChecked(true);
        setPageSelect(false);
      } else {
        setCheckedItems([...rowsToVerify]);
        setHeaderChecked(true);
        setPageSelect(false);
      }
    } else if (pageDeselect) {
      if (checkedItems && checkedItems.length > 0) {
        rowsToVerify.forEach(item => {
          if (checkedItemsSet.has(item)) {
            checkedItemsSet.delete(item);
          }
        });
        setCheckedItems([...checkedItemsSet]);
        setHeaderChecked(false);
        setPageDeselect(false);
      } else {
        // setCheckedItems([...rowsToVerify]);
        setHeaderChecked(false);
        setPageDeselect(false);
      }
    } else {
      let itemsChecked = [];
      if (checkedItems && checkedItems.length > 0) {
        rowsToVerify.forEach(item => {
          if (checkedItemsSet.has(item)) {
            itemsChecked.push(item);
          }
        });
        if (itemsChecked && itemsChecked.length > 0 && (rowsPerPage === itemsChecked.length || itemsChecked.length === rowsToVerify.length)) {
          setHeaderChecked(true);
        } else {
          setHeaderChecked(false);
        }
      } else {
        setHeaderChecked(false);
      }
    }
  }, [checkedItems, data, allSelect, allDeselect, pageSelect, pageDeselect, rowsPerPage, page]);

  React.useEffect(() => {
    let checkedItemsSet = new Set(checkedItems);
    if (rowDeselect) {
      data.forEach(item => {
        if (!checkedItemsSet.has(item)) {
          checkedItemsSet.delete(item);
        }
      });
      setCheckedItems([...checkedItemsSet]);
      setRowDeselect(false);
    }
  }, [rowDeselect]);

  React.useEffect(() => {
    if (rowsPerPage && order && orderBy) {
      const newPaginationData = {
        orderBy: orderBy,
        limit: totalRecordLimit ? totalRecordLimit : rowsPerPage,
        order: order,
        offset: offset ? offset : 0,
      };
      setPaginationData(newPaginationData);
    }
  }, [rowsPerPage, order, orderBy, offset, page, totalRecordLimit]);

  const getDefaultColumnConfig = () => {
    try {
      if (!Array.isArray(data)) return [];
      if (data.length <= 0) return [];

      let newConfig = [];
      const firstRow = data[0];
      const columnNames = Object.keys(firstRow);
      columnNames.forEach(columnName => {
        try {
          let newColumnName = columnName;
          if (columnName.startsWith('__')) return;

          const columnValue = firstRow[columnName];

          // If the column is an object, then try to use the first sub-column (object property)
          if (typeof columnValue == 'object') {
            const subColumns = Object.keys(columnValue).filter(key => !key.startsWith('__'));
            if (!Array.isArray(subColumns)) return;
            if (subColumns.length <= 0) return;
            newColumnName += `.${subColumns[0]}`;
          }

          // If it's an array, just skip it. May be add object treatment for first row?
          if (Array.isArray(columnValue)) return;

          newConfig.push({ name: newColumnName });
        } catch {}
      });
      return newConfig;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const getChildColumnConfig = () => {
    const childComponents = Children.toArray(children);
    if (!Array.isArray(childComponents)) return;
    if (childComponents.length <= 0) return;
    const childColumns = childComponents.filter(c => typeof c.type == 'function');
    if (!Array.isArray(childColumns)) return;
    if (childColumns.length <= 0) return;
    return childColumns.map(childColumn => ({ ...childColumn.props }));
  };

  const defaultColumnConfig = getDefaultColumnConfig();
  const childColumnConfig = getChildColumnConfig();

  let config = columnConfig || childColumnConfig || defaultColumnConfig;

  const rows = data.map(dataRow => {
    let row = {};
    config.forEach(column => {
      // Assume we always have a name property in the column config
      let cellValue = dataRow[column.name || 0];
      if (column.value) {
        cellValue = column.value(dataRow);
      }
      row[column.name] = cellValue;
    });
    row.data = dataRow;
    return row;
  });

  const handleChangeSort = property => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const defaultRowKeyCalculator = row => {
    return row.data.id;
  };

  const defaultCellKeyCalculator = column => {
    return column.name;
  };

  const handleHeaderChecked = event => {
    setOpenCheckboxPopover(true);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setOpenCheckboxPopover(false);
    setAnchorEl(null);
  };

  const handleSelectAllPage = () => {
    setAnchorEl(null);
    setPageSelect(true);
  };

  const handleSelectAllTotal = () => {
    setAnchorEl(null);
    setTotalRecordLimit(totalRecordsCount);
    setAllSelect(true);
  };

  const handleDeselectAllTotal = () => {
    setAnchorEl(null);
    setAllDeselect(true);
  };

  const handleDeselectAllPage = () => {
    setAnchorEl(null);
    setPageDeselect(true);
  };

  // Header checkbox component that checks/unchecks all filtered rows if clicked.
  const HeaderCheckbox = () => {
    return (
      <div>
        <Checkbox className={cls.checkbox} color='primary' onClick={handleHeaderChecked} checked={headerChecked} />
        {anchorEl ? (
          <ClickAwayListener onClickAway={handleClosePopover}>
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              ref={popoverRef}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              {openCheckboxPopover && !headerChecked ? (
                <>
                  <Typography style={{ padding: '8px' }} onClick={handleSelectAllPage}>
                    Select all on page
                  </Typography>
                  {!checkedItems || !checkedItems.length || checkedItems.length < totalRecordsCount ? (
                    <Typography style={{ padding: '8px' }} onClick={handleSelectAllTotal}>
                      Select all {totalRecordsCount} records
                    </Typography>
                  ) : null}

                  {checkedItems.length > 0 ? (
                    <Typography style={{ padding: '8px' }} onClick={handleDeselectAllTotal}>
                      Deselect {checkedItems.length} records
                    </Typography>
                  ) : null}
                </>
              ) : openCheckboxPopover && headerChecked ? (
                <>
                  <Typography style={{ padding: '8px' }} onClick={handleDeselectAllPage}>
                    Deselect all on page
                  </Typography>
                  <Typography style={{ padding: '8px' }} onClick={handleDeselectAllTotal}>
                    Deselect all {checkedItems.length} records
                  </Typography>
                </>
              ) : null}
            </Popover>
          </ClickAwayListener>
        ) : null}
      </div>
    );
  };

  // Row checkbox component that individually selects this row if clicked
  const RowCheckbox = item => {
    let checkedItemsSet = new Set(checkedItems);

    const handleCheckboxChange = (event, item) => {
      event.stopPropagation();
      if (checkedItemsSet.has(item)) {
        checkedItemsSet.delete(item);
        if (headerChecked) {
          setHeaderChecked(false);
          setRowDeselect(true);
        }
      } else {
        checkedItemsSet.add(item);
      }
      setCheckedItems([...checkedItemsSet]);
    };

    return (
      <Checkbox
        className={cls.checkbox}
        color='primary'
        onClick={event => handleCheckboxChange(event, item)}
        checked={checkedItemsSet.has(item)}
        disabled={!item || !item.id}
        indeterminate={!item || !item.id}
      />
    );
  };

  const RenderValue = ({ row, column }) => {
    try {
      if (column.checkbox) return RowCheckbox(row.data);
      if (column.renderer) return column.renderer(row.data);
      if (column.value) return column.value(row.data);
      return getPropValue(row.data, column.name);
    } catch (error) {
      console.error('RenderValue error', error.message, row.data, column.name);
      return 'unknown';
    }
  };

  return (
    <div className={cls.paper}>
      <div className={cls.pagination}>
        <Pagination
          checkedItemsLength={checkedItems.length}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setLimit={setLimit}
          setOffset={setOffset}
          totalRecords={totalRecordsCount || data.length}
          page={page}
          setPage={setPage}
        />
      </div>

      <div className={cls.tableSeparator} />

      <TableContainer className={cls.table}>
        <MUITable
          size={size}
          aria-label={tableAriaLabel}
          padding={padding}
          stickyHeader={stickyHeader}
          classes={classes}
        >
          <TableHead>
            <TableRow>
              {config.map(column => {
                if (!column.hide)
                  return (
                    <React.Fragment key={`header-${column.name}`}>
                      <Tooltip title={column.tooltip || ''} placement='top' disableHoverListener={!column.tooltip}>
                        <TableCell
                          key={column.name}
                          align={column.align || `left`}
                          padding={column.padding || `normal`}
                          sortDirection={orderBy === column.name ? order : false}
                        >
                          {column.component ? (
                            column.component()
                          ) : column.checkbox ? (
                            HeaderCheckbox()
                          ) : (
                            <TableSortLabel
                              className={cls.headTxt}
                              hideSortIcon={orderBy === column.name || column.disableSort}
                              active={orderBy === column.name}
                              direction={orderBy === column.name ? order : `asc`}
                              onClick={() => handleChangeSort(column.name)}
                            >
                              {column.label || getReadableText(column.name, true) || `Label`}
                            </TableSortLabel>
                          )}
                        </TableCell>
                      </Tooltip>
                    </React.Fragment>
                  );
                else return null;
              })}
            </TableRow>
          </TableHead>

          {usePagination && !totalRecordLimit ? (
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy)).map((row, i) => {
                const rowKey = rowKeyCalculator ? rowKeyCalculator(row) : defaultRowKeyCalculator(row);
                return (
                  <React.Fragment key={rowKey}>
                    <ContextMenuTrigger
                      id={`${tableAriaLabel}-context-menu-${rowKey}`}
                      source={`${tableAriaLabel}-context-menu-${rowKey}`}
                      holdToDisplay={1000}
                      // collect={() => props}
                      disableIfShiftIsPressed={true}
                      renderTag='tr'
                      attributes={{ className: `MuiTableRow-root ${i % 2 ? cls.rowEven : cls.rowOdd}` }}
                    >
                      {config.map((column, i) => (
                        <TableCell
                          key={cellKeyCalculator ? cellKeyCalculator(column) : defaultCellKeyCalculator(column)}
                          align={column.align || `left`}
                          style={column.style || {}}
                          onClick={() => {
                            if (!column.disableOnRowClick) {
                              if (column.onClick) {
                                column.onClick(row.data);
                              } else {
                                if (onRowClick) onRowClick(row.data);
                              }
                            }
                          }}
                        >
                          <RenderValue row={row} column={column} />
                        </TableCell>
                      ))}
                    </ContextMenuTrigger>

                    {rowActions && rowActions.length > 0 && (
                      <ContextMenu id={`${tableAriaLabel}-context-menu-${rowKey}`}>
                        {rowActions.map((action, i) =>
                          !action.hide || !action.hide(row.data) ? (
                            <MenuItem
                              key={`${tableAriaLabel}-context-menu-action-${i}`}
                              disabled={action.disabled || (action.disabled && action.disabled(row.data))}
                              onClick={() => {
                                if (action.handler) action.handler(row.data);
                              }}
                            >
                              {action.label || `Action ${i + 1}`}
                            </MenuItem>
                          ) : null
                        )}
                      </ContextMenu>
                    )}
                  </React.Fragment>
                );
              })}
            </TableBody>
          ) : (
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice((page || 0) * rowsPerPage, (page || 0) * rowsPerPage + rowsPerPage)
                .map((row, i) => {
                  const rowKey = rowKeyCalculator ? rowKeyCalculator(row) : defaultRowKeyCalculator(row);
                  return (
                    <React.Fragment key={rowKey}>
                      <ContextMenuTrigger
                        id={`${tableAriaLabel}-context-menu-${rowKey}`}
                        source={`${tableAriaLabel}-context-menu-${rowKey}`}
                        holdToDisplay={1000}
                        // collect={() => props}
                        disableIfShiftIsPressed={true}
                        renderTag='tr'
                        attributes={{ className: `MuiTableRow-root ${i % 2 ? cls.rowEven : cls.rowOdd}` }}
                      >
                        {config.map((column, i) => (
                          <TableCell
                            key={cellKeyCalculator ? cellKeyCalculator(column) : defaultCellKeyCalculator(column)}
                            align={column.align || `left`}
                            style={column.style || {}}
                            onClick={() => {
                              if (!column.disableOnRowClick) {
                                if (column.onClick) {
                                  column.onClick(row.data);
                                } else {
                                  if (onRowClick) onRowClick(row.data);
                                }
                              }
                            }}
                          >
                            <RenderValue row={row} column={column} />
                          </TableCell>
                        ))}
                      </ContextMenuTrigger>

                      {rowActions && rowActions.length > 0 && (
                        <ContextMenu id={`${tableAriaLabel}-context-menu-${rowKey}`}>
                          {rowActions.map((action, i) =>
                            !action.hide || !action.hide(row.data) ? (
                              <MenuItem
                                key={`${tableAriaLabel}-context-menu-action-${i}`}
                                disabled={action.disabled || (action.disabled && action.disabled(row.data))}
                                onClick={() => {
                                  if (action.handler) action.handler(row.data);
                                }}
                              >
                                {action.label || `Action ${i + 1}`}
                              </MenuItem>
                            ) : null
                          )}
                        </ContextMenu>
                      )}
                    </React.Fragment>
                  );
                })}
            </TableBody>
          )}
        </MUITable>
      </TableContainer>

      <div className={cls.pagination}>
        <Pagination
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setLimit={setLimit}
          setOffset={setOffset}
          totalRecords={totalRecordsCount || data.length}
          page={page}
          setPage={setPage}
        />
      </div>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  table: {
    overflowY: `hidden`,
  },
  tableSeparator: {
    borderTop: theme.border[0],
  },
  pagination: {
    paddingRight: theme.spacing(2),
  },
  headTxt: {
    fontSize: 12,
    fontWeight: 600,
  },
  rowOdd: {
    background: theme.palette.background.paper,
    '&:hover, &:active': {
      background: `${theme.palette.action.hover} !important`,
    },
    cursor: 'pointer',
    transition: '0.1s',
  },
  rowEven: {
    background: theme.palette.background.light,
    '&:hover, &:active': {
      background: `${theme.palette.action.hover} !important`,
    },
    cursor: 'pointer',
    transition: '0.1s',
  },
}));
