//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { toast } from 'react-toastify';
import uuid from 'uuid/v1';
import { makeStyles, Grid, Typography, Icon } from '@material-ui/core';
import { Button, Spacer } from '@hopdrive/storybook';

import { useData } from '../../../DataProvider';

import * as Sentry from '@sentry/react';
import { Subscription } from 'react-apollo';
import { SUBSCRIBE_TO_BATCH_JOBS } from './gql';

import useMoveImport from './useMoveImport';
import { UploadedTable } from './index';

const log = false;

//////////////////////// COMPONENT ////////////////////////

export default function UploadedMovesTable({ headers, entries, moves }) {
  const ctx = useData();
  const cls = useStyles();

  const { insertBatchJobs, downloadErrorMovesCSV } = useMoveImport();

  const [entriesEdit, setEntriesEdit] = React.useState([]);
  const [movesCreateLoading, setMovesCreateLoading] = React.useState(false);
  const [movesCreateDone, setMovesCreateDone] = React.useState(false);
  const [movesCreateSuccess, setMovesCreateSuccess] = React.useState(false);
  const [moveCreateErrors, setMovesCreateErrors] = React.useState([]);
  const [successCount, setSuccessCount] = React.useState(0);
  const [errorCount, setErrorCount] = React.useState(0);
  const [batchId, setBatchId] = React.useState(``);

  // Detect initial errors after building the move objects
  React.useEffect(() => {
    if (moves && moves.length > 0) {
      const detectErrors = () => {
        setMovesCreateErrors([]);
        let newErrors = [];
        moves.forEach((move, i) => {
          if (move && move.errors && move.errors.length > 0) {
            move.errors.forEach(err => {
              newErrors.push({ index: i, message: err });
            });
          }
        });
        setMovesCreateErrors(newErrors);
      };
      detectErrors();
    }
  }, [moves]);

  // Set editable entries
  React.useEffect(() => {
    if (entries) setEntriesEdit(entries);
    else setEntriesEdit([]);
    setBatchId(``);
    setMovesCreateLoading(false);
    setMovesCreateDone(false);
    setMovesCreateSuccess(false);
  }, [entries]);

  // Handler for inserting moves
  const handleInsertBatchJobs = async () => {
    // Set state variables
    setBatchId(``);
    setMovesCreateLoading(true);
    setMovesCreateErrors([]);

    // Get customer ID
    const customerId = parseInt(
      ctx.customerOverride || ctx.customerId
    );

    // Setting batchId will automatically resubscribe the subscription
    const newBatchId = uuid();

    // Insert all moves thru batch jobs and set the batch id so the subscription can look for it
    const res = await insertBatchJobs(newBatchId, moves, customerId, null);
    if (res && res.success && res.batchJobs && res.batchJobs.length > 0) {
      setBatchId(newBatchId);
    }
  };

  // Handler for when the move creation data is found
  const handleSubscriptionData = batchJobs => {
    // Set a bool and check to make sure all responses were resolved
    let allResponsesResolved = true;
    batchJobs.forEach(bj => {
      if (bj.status === `pending`) allResponsesResolved = false;
    });

    // Make sure all the criteria is met to rerender with data
    if (allResponsesResolved && movesCreateLoading && !movesCreateDone) {
      const movesRes = batchJobs.map(bj => bj.output);
      log && console.log(`Move response outputs:`, movesRes);

      // Check the movesRes output
      if (movesRes && movesRes.length > 0) {
        // Initialize variable overrides
        let newErrors = [];
        let newEntries = [];
        let newSuccessCount = 0;
        let newErrorCount = 0;

        // Loop over each move response
        movesRes.forEach((moveRes, i) => {
          let newEntry = entries[i];

          // Handle errors
          if (moveRes && moveRes.errors && moveRes.errors.length > 0) {
            moveRes.errors.forEach(err => newErrors.push({ index: i, ...err }));
            newEntry.success = false;
            newErrorCount++;
          } else if (!moveRes || (moveRes && moveRes.success === false)) {
            newErrors.push({
              index: i,
              message: `Unexpected error while building the move. Please contact our dispatch team.`,
            });
            newEntry.success = false;
            newErrorCount++;
          }

          // Handle successes
          if (moveRes && moveRes.success === true) {
            const reqType = moveRes.request_type;
            if (reqType === `concierge`) newEntry.move_ids = [moveRes.customer_move.id];
            if (reqType === `one-way`) newEntry.move_ids = [moveRes.move.id];
            if (reqType === `concierge-loaner`) newEntry.move_ids = [moveRes.loaner_move.id, moveRes.customer_move.id];
            if (reqType === `round-trip`) newEntry.move_ids = [moveRes.move_one.id, moveRes.move_two.id];
            newEntry.success = true;
            newSuccessCount++;
          }

          // Always push the entry
          newEntries.push(newEntry);
        });

        // Set state variables
        setMovesCreateErrors(newErrors);
        setEntriesEdit(newEntries);
        setSuccessCount(newSuccessCount);
        setErrorCount(newErrorCount);

        // Notify the user if the insert was successful
        if (newErrors.length > 0) {
          toast.error(`Failed to create move(s)! Please take a look at the errors within the rows.`);
        } else {
          toast.success(`Successfully created all listed move(s)!`);
          setMovesCreateSuccess(true);
        }
      } else {
        toast.error(`Failed to create move(s)! An unexpected error has occurred. Please contact our dispatch team.`);
      }

      // Set loading and done
      setMovesCreateLoading(false);
      setMovesCreateDone(true);
    }
  };

  return (
    <Subscription
      subscription={SUBSCRIBE_TO_BATCH_JOBS}
      variables={{ batchId: batchId }}
      onError={error => {
        console.error(error);
        Sentry.captureException(error);
      }}
    >
      {({ loading, data }) => {
        if (loading) log && console.log(`Subscription detected changes, loading batch job data...`);

        if (data && data.batch_jobs && data.batch_jobs.length > 0) {
          const mutableBatchJobs = JSON.parse(JSON.stringify(data.batch_jobs));
          log && console.log(`Subscription found batch jobs data, detecting output...`, mutableBatchJobs);
          handleSubscriptionData(mutableBatchJobs);
        }

        return (
          <div className={cls.paper}>
            <Typography className={cls.titleTxt}>Moves Imported from CSV</Typography>

            <Spacer size='xs' />

            <Typography className={cls.subtitleTxt}>
              Please review and verify the imported moves information before finalizing.
            </Typography>

            <Spacer size='xs' />

            <UploadedTable headers={headers} entries={entriesEdit} errors={moveCreateErrors} />

            {movesCreateSuccess ? (
              <>
                <Spacer size='lg' />

                <div className={cls.success}>
                  <Grid container spacing={2} alignItems='center'>
                    <Grid item xs>
                      <Typography className={cls.titleTxt}>
                        All {successCount} move(s) were created successfully!
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Icon className={cls.notifyIcon}>check_circle</Icon>
                    </Grid>
                  </Grid>
                </div>

                <Spacer size='xs' />
              </>
            ) : null}

            {moveCreateErrors.length > 0 ? (
              <>
                <Spacer size='lg' />

                <div className={cls.error}>
                  <Grid container spacing={2} alignItems='center'>
                    <Grid item xs>
                      {successCount > 0 ? (
                        <Typography className={cls.titleTxt}>Successfully created {successCount} move(s)!</Typography>
                      ) : null}
                      <Typography className={cls.titleTxt}>Failed to create {errorCount} move(s)!</Typography>
                    </Grid>
                    <Grid item>
                      <Icon className={cls.notifyIcon}>error</Icon>
                    </Grid>
                  </Grid>
                </div>

                <div className={cls.errorOutline}>
                  <Spacer size='xs' />

                  <Grid container spacing={2} alignItems='center'>
                    <Grid item>
                      <Button
                        color='error'
                        variant='outlined'
                        onClick={() => downloadErrorMovesCSV(headers, entriesEdit)}
                      >
                        <Icon className={cls.btnIconL}>download</Icon>
                        Download Failed Moves CSV
                      </Button>
                    </Grid>
                    <Grid item xs>
                      <Typography className={cls.notifyTxt}>
                        Click on a failed row to see what it needs to be valid. Use the button to download the failed
                        moves CSV. Then fix those moves and try again. Please <b>DO NOT</b> edit your original CSV and
                        reupload it. This may cause duplicate moves to be created.
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </>
            ) : null}

            {!movesCreateDone ? (
              <>
                <Spacer />

                <Grid container>
                  <Grid item xs />
                  <Grid item>
                    <Button
                      disabled={movesCreateLoading}
                      loading={movesCreateLoading}
                      color='primary'
                      onClick={() => handleInsertBatchJobs()}
                    >
                      Finalize
                    </Button>
                  </Grid>
                </Grid>
              </>
            ) : null}
          </div>
        );
      }}
    </Subscription>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  titleTxt: {
    lineHeight: 1.25,
    fontSize: 21,
    fontWeight: 500,
  },
  subtitleTxt: {
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },

  btnIconL: {
    marginTop: -2,
    marginRight: 8,
    fontSize: 16,
  },

  success: {
    position: 'relative',
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },
  error: {
    position: 'relative',
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderRadius: `8px 8px 0 0`,
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  errorOutline: {
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.error.main}`,
    borderRadius: `0 0 8px 8px`,
    color: theme.palette.error.main,
  },

  notifyIcon: {
    display: 'block',
    fontSize: 26,
  },
  notifyTxt: {
    fontSize: 14,
    fontWeight: 400,
  },
}));
