import React from 'react';
import { makeStyles, Grid, Typography, Icon } from '@material-ui/core';
import { Modal, ModalHeader, ModalContent } from '../components/reusable/Modal';
import Divide from '../components/reusable/Divide';
import Loading from '../components/utils/Loading';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import * as Sentry from '@sentry/react';
import { useData } from '../DataProvider';

////////// COMPONENT //////////
export default function ContactModal(props) {
  const cls = useStyles();
  const ctx = useData();

  const { open, onClose } = props;

  const customerId = ctx.customerOverride || 0;

  const handleClose = () => {
    if (onClose) onClose();
  };

  const formatPhoneNumber = phoneNumberString => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  };

  const DefaultContact = () => {
    return (
      <Grid container>
        <Grid item xs={6}>
          <Typography style={{ fontWeight: 400, fontSize: '18px' }}>Urgent Move Issues</Typography>
        </Grid>
        <Grid item xs={1}>
          <Icon style={{ color: '#A0A0A0' }}>email</Icon>
        </Grid>
        <Grid item xs={5}>
          <Typography className={cls.link}>
            <a href={`mailto:dispatchers@hopdrive.com`}>dispatchers@hopdrive.com</a>
          </Typography>
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={1}>
          <Icon style={{ color: '#A0A0A0' }}>phone</Icon>
        </Grid>
        <Grid item xs={5}>
          <Typography className={cls.link}>
            <a href={`tel:8009137674`}>(800) 913-7674</a>
          </Typography>
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={1}>
          <Icon style={{ color: '#A0A0A0' }}>chat</Icon>
        </Grid>
        <Grid item xs={5}>
          <Typography className={cls.link}>
            <a href={`sms:8049448548`}>(804) 944-8548</a>
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const MarketManagerContact = user => {
    return (
      <>
        <Grid item xs={6}>
          <Typography style={{ fontWeight: 400, fontSize: '18px' }}>Non-Urgent Operational Questions</Typography>
        </Grid>
        {user.display_name && (
          <>
            <Grid item xs={1}>
              <Icon style={{ color: '#A0A0A0' }}>person</Icon>
            </Grid>
            <Grid item xs={5}>
              <Typography>{user.display_name}, Market Manager</Typography>
            </Grid>
          </>
        )}

        {user.email && (
          <>
            <Grid item xs={6} />
            <Grid item xs={1}>
              <Icon style={{ color: '#A0A0A0' }}>email</Icon>
            </Grid>
            <Grid item xs={5}>
              <Typography className={cls.link}>
                <a href={`mailto:${user.email}`}>{user.email}</a>
              </Typography>
            </Grid>
          </>
        )}

        {user.phone && (
          <>
            <Grid item xs={6} />
            <Grid item xs={1}>
              <Icon style={{ color: '#A0A0A0' }}>phone</Icon>
            </Grid>
            <Grid item xs={5}>
              <Typography className={cls.link}>
                <a href={`tel:${user.phone}`}>{formatPhoneNumber(user.phone)}</a>
              </Typography>
            </Grid>
          </>
        )}
      </>
    );
  };

  const AccountManagerContact = user => {
    return (
      <>
        <Grid item xs={6}>
          <Typography style={{ fontWeight: 400, fontSize: '18px' }}>Partnership Questions</Typography>
        </Grid>

        {user.display_name && (
          <>
            <Grid item xs={1}>
              <Icon style={{ color: '#A0A0A0' }}>person</Icon>
            </Grid>
            <Grid item xs={5}>
              <Typography>{user.display_name}, Account Manager</Typography>
            </Grid>
          </>
        )}

        {user.email && (
          <>
            <Grid item xs={6} />
            <Grid item xs={1}>
              <Icon style={{ color: '#A0A0A0' }}>email</Icon>
            </Grid>
            <Grid item xs={5}>
              <Typography className={cls.link}>
                <a href={`mailto:${user.email}`}>{user.email}</a>
              </Typography>
            </Grid>
          </>
        )}

        {user.phone && (
          <>
            <Grid item xs={6} />
            <Grid item xs={1}>
              <Icon style={{ color: '#A0A0A0' }}>phone</Icon>
            </Grid>
            <Grid item xs={5}>
              <Typography className={cls.link}>
                <a href={`tel:${user.phone}`}>{formatPhoneNumber(user.phone)}</a>
              </Typography>
            </Grid>
          </>
        )}
      </>
    );
  };

  const SalesRepContact = user => {
    return (
      <>
        <Grid item xs={6}>
          <Typography style={{ fontWeight: 400, fontSize: '18px' }}>Sales Questions</Typography>
        </Grid>
        {user.display_name && (
          <>
            <Grid item xs={1}>
              <Icon style={{ color: '#A0A0A0' }}>person</Icon>
            </Grid>
            <Grid item xs={5}>
              <Typography>{user.display_name}, Sales Representative</Typography>
            </Grid>
          </>
        )}

        {user.email && (
          <>
            <Grid item xs={6} />
            <Grid item xs={1}>
              <Icon style={{ color: '#A0A0A0' }}>email</Icon>
            </Grid>
            <Grid item xs={5}>
              <Typography className={cls.link}>
                <a href={`mailto:${user.email}`}>{user.email}</a>
              </Typography>
            </Grid>
          </>
        )}

        {user.phone && (
          <>
            <Grid item xs={6} />
            <Grid item xs={1}>
              <Icon style={{ color: '#A0A0A0' }}>phone</Icon>
            </Grid>
            <Grid item xs={5}>
              <Typography className={cls.link}>
                <a href={`tel:${user.phone}`}>{formatPhoneNumber(user.phone)}</a>
              </Typography>
            </Grid>
          </>
        )}
      </>
    );
  };

  const ARRepContact = user => {
    return (
      <>
        <Grid item xs={6}>
          <Typography style={{ fontWeight: 400, fontSize: '18px' }}>Accounting Questions</Typography>
        </Grid>
        {user.display_name && (
          <>
            <Grid item xs={1}>
              <Icon style={{ color: '#A0A0A0' }}>person</Icon>
            </Grid>
            <Grid item xs={5}>
              <Typography>{user.display_name}, Accounts Receivable Representative</Typography>
            </Grid>
          </>
        )}

        {user.email && (
          <>
            <Grid item xs={6} />
            <Grid item xs={1}>
              <Icon style={{ color: '#A0A0A0' }}>email</Icon>
            </Grid>
            <Grid item xs={5}>
              <Typography className={cls.link}>
                <a href={`mailto:${user.email}`}>{user.email}</a>
              </Typography>
            </Grid>
          </>
        )}

        {user.phone && (
          <>
            <Grid item xs={6} />
            <Grid item xs={1}>
              <Icon style={{ color: '#A0A0A0' }}>phone</Icon>
            </Grid>
            <Grid item xs={5}>
              <Typography className={cls.link}>
                <a href={`tel:${user.phone}`}>{formatPhoneNumber(user.phone)}</a>
              </Typography>
            </Grid>
          </>
        )}
      </>
    );
  };

  return (
    <>
      <Query
        query={GET_CUSTOMER_CONFIG}
        variables={{
          id: customerId,
        }}
        onError={error => {
          console.error(error);
          Sentry.captureException(error);
        }}
      >
        {({ loading, data, refetch }) => {
          let userIds = [];
          let marketManagerId;
          let accountManagerId;
          let salesRepId;
          let arRepId;
          if (loading) return <Loading />;
          if (data && data.customers && data.customers.length > 0) {
            const config = data.customers[0].config;
            const orgConfig = data.customers[0].organization ? data.customers[0].organization.config : null;

            if (config && config.hopdrive_contacts && config.hopdrive_contacts.market_manager) {
              userIds.push(config.hopdrive_contacts.market_manager);
              marketManagerId = config.hopdrive_contacts.market_manager;
            } else if (orgConfig && orgConfig.hopdrive_contacts && orgConfig.hopdrive_contacts.market_manager) {
              userIds.push(orgConfig.hopdrive_contacts.market_manager);
              marketManagerId = orgConfig.hopdrive_contacts.market_manager;
            }

            if (config && config.hopdrive_contacts && config.hopdrive_contacts.account_manager) {
              userIds.push(config.hopdrive_contacts.account_manager);
              accountManagerId = config.hopdrive_contacts.account_manager;
            } else if (orgConfig && orgConfig.hopdrive_contacts && orgConfig.hopdrive_contacts.account_manager) {
              userIds.push(orgConfig.hopdrive_contacts.account_manager);
              accountManagerId = orgConfig.hopdrive_contacts.account_manager;
            }

            if (config && config.hopdrive_contacts && config.hopdrive_contacts.sales_rep) {
              userIds.push(config.hopdrive_contacts.sales_rep);
              salesRepId = config.hopdrive_contacts.sales_rep;
            } else if (orgConfig && orgConfig.hopdrive_contacts && orgConfig.hopdrive_contacts.sales_rep) {
              userIds.push(orgConfig.hopdrive_contacts.sales_rep);
              salesRepId = orgConfig.hopdrive_contacts.sales_rep;
            }

            if (config && config.hopdrive_contacts && config.hopdrive_contacts.ar_rep) {
              userIds.push(config.hopdrive_contacts.ar_rep);
              arRepId = config.hopdrive_contacts.ar_rep;
            } else if (orgConfig && orgConfig.hopdrive_contacts && orgConfig.hopdrive_contacts.ar_rep) {
              userIds.push(orgConfig.hopdrive_contacts.ar_rep);
              arRepId = orgConfig.hopdrive_contacts.ar_rep;
            }

            if (userIds.length > 0) {
              return (
                <>
                  <Query query={GET_USERS_FROM_CONFIG} variables={{ userIds }}>
                    {({ loading: usersLoading, error: usersError, data: usersData }) => {
                      if (usersLoading) return <p>Loading contacts...</p>;
                      if (usersError)
                        return (
                          <Modal open={open} width={'md'}>
                            <ModalHeader handleClose={handleClose}>Contact Your Hopdrive Team</ModalHeader>
                            <ModalContent>
                              <DefaultContact />
                            </ModalContent>
                          </Modal>
                        );

                      let marketManager;
                      let accountManager;
                      let salesRep;
                      let arRep;
                      if (
                        usersData &&
                        usersData.dealer_viewable_admin_info &&
                        usersData.dealer_viewable_admin_info.length > 0
                      ) {
                        marketManager = usersData.dealer_viewable_admin_info.find(
                          user => user.user_id === marketManagerId
                        );
                        accountManager = usersData.dealer_viewable_admin_info.find(
                          user => user.user_id === accountManagerId
                        );
                        salesRep = usersData.dealer_viewable_admin_info.find(user => user.user_id === salesRepId);
                        arRep = usersData.dealer_viewable_admin_info.find(user => user.user_id === arRepId);
                      }

                      return (
                        <Modal open={open} width={'md'}>
                          <ModalHeader handleClose={handleClose}>Contact Your Hopdrive Team</ModalHeader>

                          <ModalContent>
                            <Grid container>
                              <DefaultContact />

                              <Grid item xs={12} className={cls.bigBreak} />

                              {marketManager && (
                                <>
                                  <Divide />
                                  <Grid item xs={12} className={cls.bigBreak} />
                                  {MarketManagerContact(marketManager)}
                                  <Grid item xs={12} className={cls.bigBreak} />
                                </>
                              )}

                              {accountManager && (
                                <>
                                  <Divide />
                                  <Grid item xs={12} className={cls.bigBreak} />
                                  {AccountManagerContact(accountManager)}

                                  <Grid item xs={12} className={cls.bigBreak} />
                                </>
                              )}

                              {salesRep && (
                                <>
                                  <Divide />
                                  <Grid item xs={12} className={cls.bigBreak} />
                                  {SalesRepContact(salesRep)}

                                  <Grid item xs={12} className={cls.bigBreak} />
                                </>
                              )}

                              {arRep && (
                                <>
                                  <Divide />
                                  <Grid item xs={12} className={cls.bigBreak} />
                                  {ARRepContact(arRep)}

                                  <Grid item xs={12} className={cls.bigBreak} />
                                </>
                              )}
                            </Grid>
                          </ModalContent>
                        </Modal>
                      );
                    }}
                  </Query>
                </>
              );
            } else {
              return (
                <Modal open={open} width={'md'}>
                  <ModalHeader handleClose={handleClose}>Contact Your Hopdrive Team</ModalHeader>
                  <ModalContent>
                    <DefaultContact />
                  </ModalContent>
                </Modal>
              );
            }
          } else
            return (
              <Modal open={open} width={'md'}>
                <ModalHeader handleClose={handleClose}>Contact Your Hopdrive Team</ModalHeader>
                <ModalContent>
                  <DefaultContact />
                </ModalContent>
              </Modal>
            );
        }}
      </Query>
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  break: {
    width: '100%',
    height: theme.spacing(1.5),
  },
  bigBreak: {
    width: '100%',
    height: theme.spacing(2.5),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

////////// QUERIES //////////
const GET_CUSTOMER_CONFIG = gql`
  query get_customer_config($id: bigint!) {
    customers(where: { id: { _eq: $id } }) {
      config
      organization {
        config
      }
    }
  }
`;

const GET_USERS_FROM_CONFIG = gql`
  query getDealerViewableAdmins($userIds: [bigint]!) {
    dealer_viewable_admin_info(where: { user_id: { _in: $userIds } }) {
      user_id
      display_name
      email
      phone
    }
  }
`;
