//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Container, Grid } from '@material-ui/core';
import { Loading, Spacer } from '@hopdrive/storybook';

import * as Sentry from '@sentry/react';
import { Query } from 'react-apollo';
import { GET_INVOICE_DETAILS, GET_USERS_FROM_CONFIG } from './gql';
import sdk from '@hopdrive/sdk';

import { useInvoices } from './useInvoices';

import Toolbar from '../../reusable/Toolbar';

import DefaultErrorFallback from '../../reusable/Fallbacks/DefaultErrorFallback';
import DefaultEmptyFallback from '../../reusable/Fallbacks/DefaultEmptyFallback';
import { InvoiceInfo, InvoiceProducts, InvoiceTable, InvoicePayments, InvoiceCalculations } from './index';
import PayNowModal from './PayNowModal';
import DisputeModal from './DisputeModal';

//////////////////////// COMPONENT ////////////////////////

export default function InvoiceContent({ invoiceId }) {
  const cls = useStyles();
  const { buildAmountObject, generateCSV, generateEnhancedCSV, generatePDF } = useInvoices();

  const [checkedArmoves, setCheckedArmoves] = React.useState([]);

  const [payNowModal, setPayNowModal] = React.useState({ open: false });
  const [disputeModal, setDisputeModal] = React.useState({ open: false });

  // Pay-Now Modal
  const handlePayNowModalOpen = (initalFormState = `pay-now`, input = null) => {
    setPayNowModal({ ...payNowModal, open: true, input: input, initalFormState: initalFormState });
  };
  const handlePayNowModalClose = () => {
    setPayNowModal({ ...payNowModal, open: false });
  };

  // Dispute Modal
  const handleDisputeModalOpen = (initalFormState = `dispute-add`, input = null) => {
    setDisputeModal({ ...disputeModal, open: true, input: input, initalFormState: initalFormState });
  };
  const handleDisputeModalClose = () => {
    setDisputeModal({ ...disputeModal, open: false });
  };

  const buildAndGeneratePDF = async invoice => {
    let contactIds = [];
    let accountManagerId;
    let arRepId;
    let accountManager;
    let arRep;

    if (invoice && invoice.customer && invoice.customer.config && invoice.customer.config.hopdrive_contacts && invoice.customer.config.hopdrive_contacts.account_manager) {
      contactIds.push(invoice.customer.config.hopdrive_contacts.account_manager);
      accountManagerId = invoice.customer.config.hopdrive_contacts.account_manager;
    } else if (invoice && invoice.customer && invoice.customer.organization && invoice.customer.organization.config && invoice.customer.organization.config.hopdrive_contacts && invoice.customer.organization.config.hopdrive_contacts.account_manager) {
      contactIds.push(invoice.customer.organization.config.hopdrive_contacts.account_manager);
      accountManagerId = invoice.customer.organization.config.hopdrive_contacts.account_manager;
    }

    if (invoice && invoice.customer && invoice.customer.config && invoice.customer.config.hopdrive_contacts && invoice.customer.config.hopdrive_contacts.ar_rep) { 
      contactIds.push(invoice.customer.config.hopdrive_contacts.ar_rep);
      arRepId = invoice.customer.config.hopdrive_contacts.ar_rep;
    } else if (invoice && invoice.customer && invoice.customer.organization && invoice.customer.organization.config && invoice.customer.organization.config.hopdrive_contacts && invoice.customer.organization.config.hopdrive_contacts.ar_rep) {
      contactIds.push(invoice.customer.organization.config.hopdrive_contacts.ar_rep);
      arRepId = invoice.customer.organization.config.hopdrive_contacts.ar_rep;
    }

    if (contactIds && contactIds.length > 0) {
      const contactsRes = await sdk.gql.query(GET_USERS_FROM_CONFIG, { userIds: contactIds });
      if (contactsRes && contactsRes.data && contactsRes.data.length > 0) {
        accountManager = contactsRes.data.find(user => user.user_id === accountManagerId);
        arRep = contactsRes.data.find(user => user.user_id === arRepId);
      }
    }
    const contacts = {
      accountManager: accountManager,
      arRep: arRep,
    };
    generatePDF(invoice, contacts);
  };

  return (
    <Query
      query={GET_INVOICE_DETAILS}
      variables={{ invoiceId: invoiceId || 0 }}
      onError={error => {
        console.error(error);
        Sentry.captureException(error);
      }}
    >
      {({ loading, error, data, refetch }) => {
        const handleRefetch = () => refetch();

        // LOADING STATE //
        if (loading) {
          return (
            <div className={cls.content}>
              <Container maxWidth='lg'>
                <Toolbar back title='Invoice Details' />
                <Spacer />
                <Loading position='absolute' />
              </Container>
            </div>
          );
        }

        // ERROR STATE //
        if (error) {
          console.error(`Error fetching invoice details:`, error);
          Sentry.captureException(error);
          return (
            <div className={cls.content}>
              <Container maxWidth='lg'>
                <Toolbar back title='Invoice Details' />
                <Spacer />
                <DefaultErrorFallback paper message='ERROR FETCHING INVOICE DETAILS' />
              </Container>
            </div>
          );
        }

        // EMPTY STATE //
        if (!data || !data.arinvoices || !data.arinvoices.length > 0) {
          return (
            <div className={cls.content}>
              <Container maxWidth='lg'>
                <Toolbar back title='Invoice Details' refetch={handleRefetch} />
                <Spacer />
                <DefaultEmptyFallback paper message='NO INVOICE DETAILS FOUND' />
              </Container>
            </div>
          );
        }

        // DATA STATE //
        const invoice = data.arinvoices[0];
        const amount = buildAmountObject(invoice);

        // Separate ARMoves and Products
        let armoves = invoice.armoves;
        let products = invoice.armoves;
        if (armoves.length > 0) armoves = armoves.filter(armove => armove.type === `move`);
        if (products.length > 0)
          products = products.filter(
            armove => armove.type === `product` || armove.type === `one-time` || armove.type === `prepaid`
          );

        const invoiceActions = [
          {
            label: `Pay Now`,
            handler: () => handlePayNowModalOpen(`pay-now`, { selectedInvoice: invoice, ...amount }),
            disabled: amount.due <= 0 || invoice.status !== `closed`,
          },
          {
            label: `Generate CSV`,
            handler: () => generateCSV(invoice),
          },
          {
            label: `Generate Enhanced CSV`,
            handler: () => generateEnhancedCSV(invoice),
          },
          {
            label: `Generate PDF`,
            handler: () => buildAndGeneratePDF(invoice),
          },
        ];

        return (
          <Sentry.ErrorBoundary fallback={<DefaultErrorFallback message='ERROR DISPLAYING INVOICE CONTENT' />}>
            <PayNowModal
              open={payNowModal.open}
              onClose={handlePayNowModalClose}
              payNowInput={payNowModal.input}
              initialFormState={payNowModal.initalFormState}
              refetch={handleRefetch}
            />
            <DisputeModal
              open={disputeModal.open}
              onClose={handleDisputeModalClose}
              disputeInput={disputeModal.input}
              initialFormState={disputeModal.initalFormState}
            />

            <div className={cls.content}>
              <Container maxWidth='lg'>
                <Toolbar back title='Invoice Details' refetch={handleRefetch} actions={invoiceActions} />

                <Spacer />

                <InvoiceInfo invoice={invoice} armoves={armoves} />

                <Spacer />

                {products && products.length > 0 ? (
                  <>
                    <InvoiceProducts invoice={invoice} products={products} />
                    <Spacer />
                  </>
                ) : null}

                {armoves && armoves.length > 0 ? (
                  <>
                    <InvoiceTable
                      invoice={invoice}
                      armoves={armoves}
                      amount={amount}
                      checkedArmoves={checkedArmoves}
                      setCheckedArmoves={setCheckedArmoves}
                      handlePayNowModalOpen={handlePayNowModalOpen}
                      handleDisputeModalOpen={handleDisputeModalOpen}
                    />
                    <Spacer />
                  </>
                ) : null}

                <Grid container spacing={2}>
                  <Grid item md={8} sm={6} xs={12}>
                    <InvoicePayments invoice={invoice} />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <InvoiceCalculations invoice={invoice} />
                  </Grid>
                </Grid>
              </Container>
            </div>
          </Sentry.ErrorBoundary>
        );
      }}
    </Query>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  content: {
    position: 'relative',
    width: '100%',
    height: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    overflow: typeof InstallTrigger !== 'undefined' ? 'auto' : 'overlay',
  },
}));
