import React from 'react';
import { withRouter } from 'react-router';
import { useData } from '../../../DataProvider';
import { toast } from 'react-toastify';

import gql from 'graphql-tag';
import * as Sentry from '@sentry/react';

import { makeStyles, Grid, IconButton, Icon, Tooltip, Typography } from '@material-ui/core';

const log = false;

function cleansePhoneNumber(num) {
  var cleaned = ('' + num).replace(/\D/g, '');
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
}

//////////////////////////////////////// COMPONENT ////////////////////////////////////////
function AppraisalDetailsHeader(props) {
  const ctx = useData();
  const cls = useStyles();

  const { appraisal } = props;

  // Back to previous page
  const goBack = () => {
    props.history.goBack();
  };

  // Get the inline vehicle text
  const getVehicle = () => {
    let vehicleName = null;

    if (appraisal.vehicle_year && appraisal.vehicle_make && appraisal.vehicle_model)
      vehicleName = `${appraisal.vehicle_year} ${appraisal.vehicle_make} ${appraisal.vehicle_model}`;
    else if (appraisal.vehicle_make && appraisal.vehicle_model)
      vehicleName = `${appraisal.vehicle_make} ${appraisal.vehicle_model}`;

    return vehicleName;
  };

  // Called when the "FAVORITE APPRAISAL" button is clicked
  const favoriteAppraisal = async () => {
    let res = await ctx.apolloClient.mutate({
      mutation: FAVORITE_APPRAISAL,
      variables: {
        id: appraisal.id,
        favorite: appraisal.favorite ? false : true,
      },
      onError: err => {
        Sentry.captureException(err);
        console.error(`Error favoriting appraisal record:`, err);
      },
    });

    if (
      res.data.update_appraisals &&
      res.data.update_appraisals.returning &&
      res.data.update_appraisals.returning.length > 0
    ) {
      let favRes = res.data.update_appraisals.returning[0];
      log && console.log(`FAVORITED Appraisal #${favRes.id}`);
    } else {
      console.error(`Failed to favorite appraisal record!`);
      toast.error(`Failed to favorite appraisal record!`);
    }
  };

  return (
    <>
      <div className={cls.bar}>
        <Grid container spacing={4} alignItems='center' wrap='nowrap' style={{ overflow: typeof InstallTrigger !== 'undefined' ? 'auto' : 'overlay', }}>
          <Grid item>
            <Tooltip title='Back'>
              <IconButton onClick={() => goBack()}>
                <Icon>arrow_back</Icon>
              </IconButton>
            </Tooltip>
            <Tooltip title={appraisal.favorite ? `Unfavorite Appraisal` : `Favorite Appraisal`}>
              <IconButton className={cls.heartParent} onClick={() => favoriteAppraisal()}>
                <Icon className={appraisal.favorite ? cls.heartActive : cls.heart}>
                  {appraisal.favorite ? `favorite` : `favorite_border`}
                </Icon>
              </IconButton>
            </Tooltip>
          </Grid>
          {getVehicle() && (
            <Grid item>
              <Typography className={cls.keyTxt}>Vehicle</Typography>
              <Typography className={cls.valTxt}>{getVehicle()}</Typography>
            </Grid>
          )}
          {appraisal.vin && (
            <Grid item>
              <Typography className={cls.keyTxt}>VIN</Typography>
              <Typography className={cls.valTxt}>{appraisal.vin}</Typography>
            </Grid>
          )}
          {appraisal.vehicle_odometer || appraisal.vehicle_odometer === 0 ? (
            <Grid item>
              <Typography className={cls.keyTxt}>Mileage</Typography>
              <Typography className={cls.valTxt}>{appraisal.vehicle_odometer} mi</Typography>
            </Grid>
          ) : null}
          {appraisal.consumer_name && props.width === `xl` ? (
            <Grid item>
              <Typography className={cls.keyTxt}>Customer&nbsp;Name</Typography>
              <Typography className={cls.valTxt}>{appraisal.consumer_name}</Typography>
            </Grid>
          ) : null}
          {appraisal.consumer_phone && props.width === `xl` ? (
            <Grid item>
              <Typography className={cls.keyTxt}>Customer&nbsp;Phone</Typography>
              <Typography className={cls.valTxt}>{cleansePhoneNumber(appraisal.consumer_phone)}</Typography>
            </Grid>
          ) : null}
          <Grid item xs />
          {/* <Grid item>
            <Tooltip title='Settings'>
              <IconButton>
                <Icon>settings</Icon>
              </IconButton>
            </Tooltip>
          </Grid> */}
        </Grid>
      </div>
    </>
  );
}

//////////////////////////////////////// STYLES ////////////////////////////////////////
const useStyles = makeStyles(theme => ({
  bar: {
    position: 'relative',
    padding: theme.spacing(2),
    background: '#fff',
    boxShadow: '0 0 16px #00000032',
    overflow: typeof InstallTrigger !== 'undefined' ? 'auto' : 'overlay',
  },
  heartParent: {
    '&:hover $heart': {
      color: theme.palette.error.main,
    },
    '&:hover $heartActive': {
      color: theme.palette.error.light,
    },
  },
  heart: {
    transition: '0.2s',
  },
  heartActive: {
    color: theme.palette.error.main,
    transition: '0.2s',
  },
  keyTxt: {
    marginBottom: theme.spacing(0.5),
    lineHeight: 1,
    color: theme.palette.text.secondary,
    fontSize: 12,
    fontWeight: 500,
  },
  valTxt: {
    lineHeight: 1,
    fontSize: 16,
    fontWeight: 500,
  },
}));

//////////////////////////////////////// GRAPHQL ////////////////////////////////////////
const FAVORITE_APPRAISAL = gql`
  mutation favorite_appraisal($id: bigint!, $favorite: Boolean!) {
    update_appraisals(where: { id: { _eq: $id } }, _set: { favorite: $favorite }) {
      affected_rows
      returning {
        id
        favorite
      }
    }
  }
`;

//////////////////////////////////////// EXPORT ////////////////////////////////////////
export default withRouter(AppraisalDetailsHeader);
