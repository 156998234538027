//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, TableCell } from '@material-ui/core';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';

import { useTools } from '../../hooks/useTools';

import ArmoveFold from '../../reusable/Folds/ArmoveFold';

//////////////////////// COMPONENT ////////////////////////

export default function InvoiceTableRow(props) {
  const { row, index, foldId, setFoldId, handleDisputeModalOpen } = props;
  const armove = row.armove || {};
  const move = armove.move || {};

  const cls = useStyles();
  const { goToMoveDetails, copyToClipboard } = useTools();

  // Handle fold row state
  const handleFoldRow = (id = 0) => {
    let newFoldId = id === foldId ? 0 : id;
    localStorage.setItem(`invoice-fold-id`, newFoldId);
    setFoldId(newFoldId);
  };

  const formatWeightClass = inputString => {
    if (!inputString) return '-';
    let formattedString = inputString.replace(/-/g, ' ');
    formattedString = formattedString.replace(/\s+\S+$/, '');
    formattedString = formattedString.replace(/^\w/, firstWord => firstWord.toUpperCase());
    return formattedString;
  };

  // Set a list of cells to render the row
  const rowCells = [
    {
      value: row.CHECKBOX || `-`,
      align: `left`,
    },
    {
      value: row.MOVE_ID || `-`,
      align: `left`,
      onClick: () => handleFoldRow(armove.id),
    },
    {
      value: row.REF_NUM || `-`,
      align: `left`,
      onClick: () => handleFoldRow(armove.id),
    },
    {
      value: row.MOVE_DATE || `-`,
      align: `left`,
      onClick: () => handleFoldRow(armove.id),
    },
    {
      value: row.LANE || `-`,
      align: `left`,
      onClick: () => handleFoldRow(armove.id),
    },
    {
      value: row.DLV_REP || `-`,
      align: `left`,
      onClick: () => handleFoldRow(armove.id),
      hide: armove.move && armove.move.customer && armove.move.customer.id !== 2,
    },
    {
      value: row.DISTANCE || `-`,
      align: `right`,
      onClick: () => handleFoldRow(armove.id),
    },
    {
      value: formatWeightClass(row.WEIGHT_CLASS) || `-`,
      align: `right`,
      onClick: () => handleFoldRow(armove.id),
    },
    {
      value: row.COST || `-`,
      align: `right`,
      onClick: () => handleFoldRow(armove.id),
    },
  ];

  // Set a list of actions after right-clicking on a row
  const rowActions = [
    {
      label: `Go To Move Details`,
      handler: () => goToMoveDetails(move.id || null),
    },
    {
      label: armove.disputed === false ? `Dispute Charges` : `Cancel Dispute`,
      handler: () => handleDisputeModalOpen(armove.disputed === false ? `dispute-add` : `dispute-cancel`, armove),
      disabled: armove.paid_amount >= armove.due_amount,
    },
    {
      label: `Copy Reference #`,
      handler: () => copyToClipboard(move.reference_num),
      hide: !move.reference_num || (move.customer && move.customer.id !== 2),
    },
    {
      label: `Copy Delivery Timestamp`,
      handler: () => copyToClipboard(move.delivery_successful),
      hide: !move.delivery_successful,
    },
  ];

  return (
    <>
      <ContextMenuTrigger
        id={`atr-row-${armove.id}-cm`}
        source={`atr-row-${armove.id}-cm`}
        holdToDisplay={1000}
        collect={() => props}
        disableIfShiftIsPressed={true}
        renderTag='tr'
        attributes={{
          className: `MuiTableRow-root ${foldId === armove.id ? cls.rowActive : cls.row}`,
        }}
      >
        {rowCells && rowCells.length > 0
          ? rowCells.map((cell, i) => {
              if (!cell.hide)
                return (
                  <TableCell
                    key={`atr-row-${armove.id}-col-${i}`}
                    className={foldId === armove.id ? cls.cellActive : armove.disputed ? cls.cellDisputed : cls.cell}
                    align={cell.align || `left`}
                    onClick={cell.onClick || null}
                  >
                    {cell.value || null}
                  </TableCell>
                );
              else return null;
            })
          : null}
      </ContextMenuTrigger>

      {rowActions && rowActions.length > 0 && (
        <ContextMenu id={`atr-row-${armove.id}-cm`}>
          {rowActions.map(
            (action, i) =>
              !action.hide && (
                <MenuItem
                  key={`atr-row-action-${i}`}
                  disabled={action.disabled || false}
                  onClick={action.handler || null}
                >
                  {action.label || `Action ${i + 1}`}
                </MenuItem>
              )
          )}
        </ContextMenu>
      )}

      <ArmoveFold armove={row.armove} actions={rowActions} rowCellCount={rowCells.length} foldId={foldId} />
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  row: {
    background: theme.palette.background.paper,
    '&:hover, &:active': {
      background: `${theme.palette.action.hover} !important`,
    },
    cursor: 'pointer',
    transition: '0.15s',
  },
  rowActive: {
    background: theme.palette.primary.main,
    '&:hover, &:active': {
      background: `${theme.palette.primary.main} !important`,
    },
    cursor: 'pointer',
    transition: '0.15s',
  },
  cell: {
    color: theme.palette.text.primary,
    transition: '0.15s',
  },
  cellDisputed: {
    color: theme.palette.text.secondary,
    transition: '0.15s',
  },
  cellActive: {
    color: theme.palette.text.contrast,
    transition: '0.15s',
  },
}));
