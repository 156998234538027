//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import dayjs from 'dayjs';
import { makeStyles, Grid, Tooltip, Typography } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

import { useTools } from '../../hooks/useTools';
import { useInvoices } from './useInvoices';

//////////////////////// COMPONENT ////////////////////////

export default function InvoiceInfo({ invoice, armoves }) {
  const cls = useStyles();
  const { capFirst, getMonetaryValue } = useTools();
  const { getTotalMileageFromMoves } = useInvoices();

  const mileage = getTotalMileageFromMoves(armoves.map(armove => armove.move));
  const prepaidAmount = invoice.armoves
    .filter(arm => arm.type === `prepaid`)
    .map(arm => arm.due_amount)
    .reduce((total, current) => total + current, 0);

  return (
    <div className={cls.paper}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Tooltip placement='top-start' title='Invoice ID'>
            <Typography className={cls.titleTxt} style={{ textAlign: 'left' }}>
              {invoice.id ? `Invoice #${invoice.id}` : `Unknown ID`}
            </Typography>
          </Tooltip>
        </Grid>
        <Grid item xs={4}>
          <Tooltip placement='top' title='Customer Name'>
            <Typography className={cls.titleTxt} style={{ textAlign: 'center' }}>
              {invoice.customer ? invoice.customer.name : `Unknown Customer`}
            </Typography>
          </Tooltip>
        </Grid>
        <Grid item xs={4}>
          <Tooltip placement='top-end' title='Invoice Status'>
            <Typography className={cls.titleTxt} style={{ textAlign: 'right' }}>
              {invoice.status ? capFirst(invoice.status) : `Unknown Status`}
            </Typography>
          </Tooltip>
        </Grid>
      </Grid>

      <Spacer />

      <Grid container spacing={2}>
        <Grid item sm={4} xs={12}>
          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Prepaid&nbsp;Package:&nbsp;</Typography>
            <Typography className={cls.valTxt}>{getMonetaryValue(prepaidAmount, true) || `-`}</Typography>
          </div>
          <div className={cls.lineBreak} />
          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Total&nbsp;Moves:&nbsp;</Typography>
            <Typography className={cls.valTxt}>
              {armoves.length === 1 ? armoves.length + ` Move` : armoves.length + ` Moves`}
            </Typography>
          </div>
          <div className={cls.lineBreak} />
          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Total&nbsp;Milage:&nbsp;</Typography>
            <Typography className={cls.valTxt}>{Number(mileage).toFixed(1)}&nbsp;mi</Typography>
          </div>
        </Grid>
        <Grid item sm={4} xs={12}>
          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Billing&nbsp;Frequency:&nbsp;</Typography>
            <Typography className={cls.valTxt}>{capFirst(invoice.customer.billing_frequency)}</Typography>
          </div>
          <div className={cls.lineBreak} />
          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Auto&nbsp;Pay:&nbsp;</Typography>
            <Typography className={cls.valTxt}>{invoice.customer.auto_pay ? `Activated` : `Not Activated`}</Typography>
          </div>
          <div className={cls.lineBreak} />
          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Notify:</Typography>
            <Typography className={cls.valTxt}>
              {invoice.customer.notify_billing ? `Activated` : `Not Activated`}
            </Typography>
          </div>
        </Grid>
        <Grid item sm={4} xs={12}>
          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Start&nbsp;Date:&nbsp;</Typography>
            <Typography className={cls.valTxt}>
              {dayjs.utc(dayjs(invoice.start_datetime)).format('MM/DD/YYYY')}
            </Typography>
          </div>
          <div className={cls.lineBreak} />
          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>End&nbsp;Date:&nbsp;</Typography>
            <Typography className={cls.valTxt}>
              {dayjs.utc(dayjs(invoice.end_datetime)).format('MM/DD/YYYY')}
            </Typography>
          </div>
          <div className={cls.lineBreak} />
          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Due&nbsp;Date:&nbsp;</Typography>
            <Typography className={cls.valTxt}>
              {dayjs.utc(dayjs(invoice.end_datetime).add(invoice.customer.payment_terms, `day`)).format('MM/DD/YYYY')}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  titleTxt: {
    lineHeight: 1,
    fontSize: 21,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
    cursor: 'default',
  },
  listItem: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  keyTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  valTxt: {
    width: '100%',
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: '6px',
    marginBottom: '8px',
    background: theme.palette.dividerStrong,
  },
}));
