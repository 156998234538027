import { makeStyles, Container, Typography } from '@material-ui/core';
import React from 'react';

export default function DisabledMovePlanner() {
  const cls = useStyles();
  return (
    <div className={cls.restricted}>
      <Container maxWidth='md'>
        <Typography className={cls.restrictedText}>
          Your company has surpassed its monthly prepaid move amount. If you wish to increase your monthly package
          amount, or if you have any questions or concerns, contact a hopdrive sales representative by sending an email
          to: <a className={cls.emailLink} href={'mailto: sales@hopdrive.com'}>sales@hopdrive.com</a>
        </Typography>
      </Container>
    </div>
  );
}
//////////////////////////////////////// STYLES ////////////////////////////////////////
const useStyles = makeStyles(theme => ({
  restricted: {
    marginTop: '100px',
    width: '100%',
    paddingTop: theme.spacing(4),
    textAlign: 'center',
  },
  restrictedText: {
    lineHeight: 1.333,
    fontSize: 22,
    fontWeight: 400,
  },
  emailLink: {
    color: 'blue',
  }
}));
