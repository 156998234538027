//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import clsx from 'clsx';
import { makeStyles, Icon, Typography, Tooltip } from '@material-ui/core';

//////////////////////// COMPONENT ////////////////////////

export default function SettingsResetToDefault({ value, checkReset, onReset, resetText, resetTooltip }) {
  const cls = useStyles();

  const [resetState, setResetState] = React.useState(checkReset ? checkReset() : true);

  const handleReset = async () => {
    if (onReset) await onReset();
    if (checkReset) setResetState(checkReset());
  };

  React.useEffect(() => {
    if (checkReset) setResetState(checkReset());
  }, [value]);

  return (
    <Tooltip title={resetTooltip || `Restore value to default`} placement={`top`}>
      <div className={clsx(cls.reset, { [cls.resetDisabled]: resetState })} onClick={handleReset}>
        <Icon className={cls.resetIcon}>replay</Icon>
        <Typography className={cls.resetTxt}>{resetText || `Restore Default`}</Typography>
      </div>
    </Tooltip>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  reset: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    top: 0,
    right: 0,
    padding: 4,
    borderRadius: '0 8px 0 8px',
    backgroundColor: theme.palette.default.light,
    color: theme.palette.default.contrastText,
    cursor: 'pointer',
    transition: '0.15s all ease-in-out',
    '&:hover': {
      backgroundColor: theme.palette.default.main,
    },
  },
  resetDisabled: {
    backgroundColor: '#ddd',
    color: theme.palette.text.disabled,
    userSelect: 'none',
    pointerEvents: 'none',
  },
  resetIcon: {
    display: 'block',
    lineHeight: 1,
    fontSize: 12,
    marginRight: theme.spacing(0.5),
  },
  resetTxt: {
    lineHeight: 1,
    fontSize: 12,
    fontWeight: 500,
  },
}));