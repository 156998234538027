//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { toast } from 'react-toastify';
import {
  useTheme,
  makeStyles,
  TableRow,
  TableCell,
  Grid,
  Tooltip,
  Icon,
  Collapse,
  Typography,
} from '@material-ui/core';

//////////////////////// COMPONENT ////////////////////////

export default function UploadedTableRow({ row, errors, index }) {
  const cls = useStyles();
  const theme = useTheme();

  const [collapse, setCollapse] = React.useState(false);

  // Get the keys and values
  const rowKeys = Object.keys(row);
  const rowValues = Object.values(row);
  const success = row.success === true ? true : row.success === false ? false : null;

  // Set a list of cells to render the row
  const rowCells = rowKeys.map((key, i) => {
    return { value: rowValues[i], align: `left`, onClick: () => setCollapse(success === false ? !collapse : false) };
  });

  /** Copy a string to the clipboard */
  const copyToClipboard = (str = null, useToast = true) => {
    try {
      if (str) {
        navigator.clipboard.writeText(str);
        if (useToast) toast.info(`Copied to clipboard: "${str}"`, { autoClose: 2500 });
      } else if (useToast) toast.warning(`No text was found to copy!`);
    } catch (err) {
      if (useToast) toast.error(`Failed to copy text!`);
      console.error(`Failed to copy text:`, err);
    }
  };

  // Get the display className based on a number of factors
  const getRowClassName = () => {
    // if (collapse && success === true) return cls.rowSuccessActive;
    if (collapse && success === false) return cls.rowErrorActive;
    return index % 2 ? cls.rowEven : cls.rowOdd;
  };

  // Get the display className based on a number of factors
  const getCellClassName = () => {
    if (success === true) {
      // if (collapse) return cls.cellActive;
      return cls.cellSuccess;
    }
    if (success === false) {
      if (collapse) return cls.cellActive;
      return cls.cellError;
    }
    return cls.cell;
  };

  const getCellRowId = id => {
    return (
      <Grid container alignItems='center'>
        {success === false ? (
          <Grid item>
            <Icon
              className={cls.iconCollapse}
              style={{ color: collapse ? theme.palette.text.contrast : theme.palette.error.main }}
            >
              {collapse ? `expand_less` : `expand_more`}
            </Icon>
          </Grid>
        ) : null}

        <Grid item>
          <Typography className={cls.rowTxt}>{id}</Typography>
        </Grid>
      </Grid>
    );
  };

  const getCellMoveIds = ids => {
    return (
      <>
        {ids && Array.isArray(ids) && ids.length > 0
          ? ids.map((item, i) => (
              <Grid key={`csv-move-id-${i}`} container alignItems='center' wrap='nowrap'>
                <Grid item>
                  <Typography className={cls.rowTxt}>{item}</Typography>
                </Grid>
                <Grid item>
                  <Icon className={cls.iconCopy} onClick={() => copyToClipboard(item)}>
                    content_copy
                  </Icon>
                </Grid>
              </Grid>
            ))
          : null}
      </>
    );
  };

  const getCellSuccessIcon = () => {
    if (success === true)
      return (
        <Tooltip title='This move passed through our system successfully!'>
          <Icon className={cls.iconSuccess}>check_circle</Icon>
        </Tooltip>
      );
    if (success === false)
      return (
        <Tooltip title='This move failed to pass through our system!'>
          <Icon className={cls.iconError}>report</Icon>
        </Tooltip>
      );
    return (
      <Tooltip title='This move has not yet been run through our system.'>
        <Icon className={cls.iconNeutral}>remove_circle_outline</Icon>
      </Tooltip>
    );
  };

  const getCellRender = (cell, i) => {
    if (i === 0) return getCellRowId(cell.value);
    if (i === 1) return getCellMoveIds(cell.value);
    if (i === 2) return getCellSuccessIcon();
    return cell.value || `-`;
  };

  return (
    <>
      <TableRow className={getRowClassName()}>
        {rowCells && rowCells.length > 0
          ? rowCells.map((cell, i) => {
              return (
                <TableCell
                  key={`atr-row-${index}-col-${i}`}
                  className={getCellClassName()}
                  align={cell.align || `left`}
                  onClick={cell.onClick || null}
                >
                  {getCellRender(cell, i)}
                </TableCell>
              );
            })
          : null}
      </TableRow>

      {errors && errors.length > 0 ? (
        <TableRow>
          <TableCell
            style={{ borderBottom: collapse ? theme.border[0] : 0 }}
            align='left'
            padding='none'
            colSpan={rowCells.length}
          >
            <Collapse in={collapse} timeout='auto'>
              <div className={cls.collapseErrors}>
                {errors.map((err, i) => (
                  <Typography key={`csv-error-${err.index}-${i}`} className={cls.collapseTxt}>
                    &#8226; {err.message}
                  </Typography>
                ))}
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      ) : null}
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  rowOdd: {
    background: theme.palette.background.paper,
    '&:hover, &:active': {
      background: `${theme.palette.action.hover} !important`,
    },
    transition: '0.1s',
  },
  rowEven: {
    background: theme.palette.background.light,
    '&:hover, &:active': {
      background: `${theme.palette.action.hover} !important`,
    },
    transition: '0.1s',
  },
  rowSuccessActive: {
    background: theme.palette.success.main,
    '& $iconSuccess': {
      color: theme.palette.text.contrast,
    },
    cursor: 'pointer',
    transition: '0.1s',
  },
  rowErrorActive: {
    background: theme.palette.error.main,
    '& $iconError': {
      color: theme.palette.text.contrast,
    },
    cursor: 'pointer',
    transition: '0.1s',
  },

  cell: {
    color: theme.palette.text.primary,
  },
  cellSuccess: {
    color: theme.palette.success.main,
  },
  cellError: {
    color: theme.palette.error.main,
    cursor: 'pointer',
  },
  cellActive: {
    color: theme.palette.text.contrast,
    cursor: 'pointer',
  },

  iconSuccess: {
    display: 'block',
    marginTop: 1,
    color: theme.palette.success.main,
  },
  iconError: {
    display: 'block',
    marginTop: 1,
    color: theme.palette.error.main,
  },
  iconNeutral: {
    display: 'block',
    marginTop: 1,
    color: theme.palette.text.disabled,
  },

  iconCollapse: {
    display: 'block',
    marginTop: -2,
    marginLeft: -6,
    marginRight: 4,
  },

  iconCopy: {
    display: 'block',
    marginTop: -2,
    marginLeft: 4,
    fontSize: 16,
    cursor: 'pointer',
  },

  collapseErrors: {
    padding: theme.spacing(2),
    background: theme.palette.error.lighter,
  },
  collapseTxt: {
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.error.main,
  },

  rowTxt: {
    lineHeight: 1.43,
    fontSize: 14,
    fontWeight: 500,
  },
}));
