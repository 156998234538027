import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function Loading(props) {
  return (
    <CircularProgress
      disableShrink
      style={{
        position: props.fixed ? 'fixed' : props.relative ? 'relative' : 'absolute',
        display: 'block',
        top: props.top || 0,
        left: props.left || 0,
        bottom: props.bottom || 0,
        right: props.right || 0,
        margin: 'auto',
      }}
    />
  );
}
