import React from 'react';
import { makeStyles, TextField, MenuItem, InputAdornment, Icon, Typography } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

//////////////////////// COMPONENT ////////////////////////

export default function WorkflowForm({ workflowSets, workflow, onWorkflowChange, isConcierge, conciergeSla }) {
  const cls = useStyles();

  const handleWorkflowChange = value => {
    if (onWorkflowChange && workflowSets && workflowSets.length) {
      const newWorkflowSet = workflowSets.find(ws => ws.id === value);
      onWorkflowChange(newWorkflowSet || {});
    }
  };

  // Only show workflow selection if there are multiple workflows available
  if (workflowSets && workflowSets.length > 1) {
    return (
      <>
        <Spacer />

        <TextField
          required
          select
          fullWidth
          label='Workflow Selection'
          variant='outlined'
          size='small'
          value={workflow.id || 1}
          onChange={e => handleWorkflowChange(e.target.value)}
          InputProps={{
            startAdornment: (
              <>
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    file_copy
                  </Icon>
                </InputAdornment>
              </>
            ),
          }}
        >
          {workflowSets.map((ws, i) => (
            <MenuItem key={`allowed-workflow-${i}`} className={cls.menuItem} value={ws.id}>
              <Typography className={cls.labelTxt}>{ws.name}</Typography>
              <Typography className={cls.descriptionTxt}>{ws.description}</Typography>
              {isConcierge && conciergeSla ? (
                <Typography className={cls.slaTxt}>
                  SLA - {conciergeSla.label} ({conciergeSla.duration_hrs}
                  {conciergeSla.duration_hrs === 1 ? 'hr' : 'hrs'})
                </Typography>
              ) : null}
              {!isConcierge && ws.sla ? (
                <Typography className={cls.slaTxt}>
                  SLA - {ws.sla.label} ({ws.sla.duration_hrs}
                  {ws.sla.duration_hrs === 1 ? 'hr' : 'hrs'})
                </Typography>
              ) : null}
            </MenuItem>
          ))}
        </TextField>
      </>
    );
  }

  return null;
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  menuItem: {
    display: 'block',
  },
  labelTxt: {
    marginBottom: theme.spacing(0.5),
    lineHeight: 1,
    fontSize: 16,
    fontWeight: 400,
  },
  descriptionTxt: {
    whiteSpace: 'wrap',
    lineHeight: 1.25,
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  slaTxt: {
    whiteSpace: 'wrap',
    lineHeight: 1.25,
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.secondary.main,
  },
}));
