import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { makeStyles } from '@material-ui/core';
import * as Sentry from '@sentry/react';
import DefaultErrorFallback from '../../reusable/Fallbacks/DefaultErrorFallback';
import DefaultEmptyFallback from '../../reusable/Fallbacks/DefaultEmptyFallback';
import Loading from '../../../components/utils/Loading';
import Toolbar from '../../reusable/Toolbar';
import { getUserRole, getAllowedCustomers } from '../../utils/authHelper';

import { UsersFilter, UsersTable } from './index';

//////////////////////// COMPONENT ////////////////////////
export default function Users(props) {
  const cls = useStyles();

  const [search, setSearch] = React.useState(null);
  const [defaultRole, setDefaultRole] = React.useState(null);
  const [customerIds, setCustomerIds] = React.useState([]);
  const [allUsersChecked, setAllUsersChecked] = React.useState(false);

  const fetchUserRole = async () => {
    const role = await getUserRole();
    setDefaultRole(role);
  };

  const fetchAllowedCustomers = async () => {
    const customersList = await getAllowedCustomers();
    if (customersList && customersList.length > 0) {
      setCustomerIds(customersList);
    }
  };

  React.useEffect(() => {
    // Fetch data in sequence
    const initialize = async () => {
      await fetchUserRole();
      await fetchAllowedCustomers();
    };

    initialize();
  }, []);

  if (!defaultRole || !customerIds.length) return <Loading fixed />;
  return (
    <Query query={GET_USERS_BY_ORG} variables={{ customerIds: customerIds }} fetchPolicy='no-cache'>
      {({ loading, error, data, refetch }) => {
        if (loading) return <Loading fixed />;
        if (error) return <Sentry.ErrorBoundary fallback={<DefaultErrorFallback message='ERROR FETCHING USERS' />} />;

        let users = [];
        if (data) {
          if (data.defaultCustomer && data.defaultCustomer.length > 0) {
            users = data.defaultCustomer;
          }
          if (data.userToCustomers && data.userToCustomers.length > 0) {
            users =
              users.length > 0
                ? users.concat(data.userToCustomers.map(u => u.user))
                : data.userToCustomers.map(u => u.user);
          }

          // Remove duplicates based on the user id
          users = users.filter((user, index, self) => index === self.findIndex(u => u.id === user.id));
          if (!allUsersChecked) {
            users = users.filter(u => u.active);
          }
        }

        if (defaultRole !== 'dealer-super-admin') return <DefaultEmptyFallback message='RESTRICTED ACCESS' />;

        return (
          <div className={cls.root}>
            <Toolbar fullscreen title='Users'>
              <UsersFilter
                search={search}
                onSearchChange={setSearch}
                allUsersChecked={allUsersChecked}
                setAllUsersChecked={setAllUsersChecked}
              />
            </Toolbar>
            {users && users.length ? (
              <Sentry.ErrorBoundary fallback={<DefaultErrorFallback message='ERROR DISPLAYING USERS TABLE' />}>
                <UsersTable
                  users={users}
                  search={search}
                  allUsersChecked={allUsersChecked}
                  setAllUsersChecked={setAllUsersChecked}
                />
              </Sentry.ErrorBoundary>
            ) : (
              <DefaultEmptyFallback message='COULD NOT FIND USERS' />
            )}
          </div>
        );
      }}
    </Query>
  );
}

//////////////////////// STYLES ////////////////////////
const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(7),
  },
}));

const GET_USERS_BY_ORG = gql`
  query getUsersByOrg($customerIds: [bigint!]!) {
    userToCustomers: usertocustomers(
      where: { _and: [{ customer_id: { _in: $customerIds } }, { user: { default_role: { _ilike: "%dealer%" } } }] }
    ) {
      user_id
      user {
        id
        default_role
        active
        display_name
        customer_id
        email
        phone
        created_at
        updated_at
        avatar_url
        customer {
          name
        }
      }
    }
    defaultCustomer: users(
      where: { _and: [{ customer_id: { _in: $customerIds } }, { default_role: { _ilike: "%dealer%" } }] }
    ) {
      id
      default_role
      active
      display_name
      customer_id
      email
      phone
      avatar_url
      created_at
      updated_at
      customer {
        name
      }
    }
  }
`;
