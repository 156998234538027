//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import {
  useTheme,
  makeStyles,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from '@material-ui/core';

import { useTools } from '../../hooks/useTools';

//////////////////////// COMPONENT ////////////////////////

export default function InvoiceProducts({ invoice, products }) {
  const theme = useTheme();
  const cls = useStyles();

  const { getMonetaryValue } = useTools();

  const headers = [
    { id: `PRODUCT`, align: `left`, label: `Product` },
    { id: `DESCRIPTION`, align: `left`, label: `Description` },
    { id: `COST`, align: `right`, label: `Cost` },
  ];

  const rows = products.map((product, i) => {
    return {
      PRODUCT: product.details && product.details.length > 0 ? product.details[0].name : null,
      DESCRIPTION: product.details && product.details.length > 0 ? product.details[0].notes : null,
      COST: getMonetaryValue(product.due_amount, true) || null,
      product: product,
    };
  });

  if (products) {
    return (
      <div className={cls.paper}>
        <div className={cls.title}>
          <Typography className={cls.titleTxt}>Products List</Typography>
        </div>

        <TableContainer>
          <Table size='small'>
            <TableHead>
              <TableRow>
                {headers.map((header, i) => {
                  if (!header.hide)
                    return (
                      <TableCell
                        key={`products-head-cell-${header.id}`}
                        align={header.align || `left`}
                        padding={header.padding || `normal`}
                      >
                        <Typography className={cls.headLabelTxt}>{header.label || `Label`}</Typography>
                      </TableCell>
                    );
                  else return null;
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {rows.map((row, i) => {
                // Set a list of cells to render the row
                const rowCells = [
                  {
                    value: row.PRODUCT || `-`,
                    align: `left`,
                  },
                  {
                    value: row.DESCRIPTION || `-`,
                    align: `left`,
                  },
                  {
                    value: row.COST || `-`,
                    align: `right`,
                  },
                ];

                // Main rows of the table
                return (
                  <React.Fragment key={`product-row-${i}`}>
                    <TableRow className={cls.row}>
                      {rowCells && rowCells.length > 0
                        ? rowCells.map((cell, i2) => {
                            if (!cell.hide)
                              return (
                                <TableCell
                                  key={`atr-row-${i}-col-${i2}`}
                                  align={cell.align || `left`}
                                  onClick={cell.onClick || null}
                                  style={{ borderBottom: i >= rows.length - 1 ? `none` : theme.border[3] }}
                                >
                                  {cell.value || null}
                                </TableCell>
                              );
                            else return null;
                          })
                        : null}
                    </TableRow>
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }

  return null;
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
    overflow: 'hidden',
  },
  title: {
    width: '100%',
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  titleTxt: {
    lineHeight: 1,
    fontSize: 21,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
    cursor: 'default',
  },
  headLabelTxt: {
    fontSize: 12,
    fontWeight: 600,
  },
  row: {
    background: theme.palette.background.paper,
    '&:hover, &:active': {
      background: `${theme.palette.action.hover} !important`,
    },
    cursor: 'pointer',
    transition: '0.15s',
  },
}));
