import React from 'react';
import { makeStyles, TextField} from '@material-ui/core';
import * as Sentry from '@sentry/react';

import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { Autocomplete } from '@material-ui/lab';
import { useData } from '../../../DataProvider';

////////// COMPONENT //////////
export default function CustomerForm(props) {
  const cls = useStyles();
  const ctx = useData();

  const { customerId, onCustomerIdChange, customerIdArray } = props;

  const handleCustomerChange = (event, value) => {
    const selectedCustomerId = value && value.id ? value.id : null;
    if (selectedCustomerId && onCustomerIdChange) {
      if (onCustomerIdChange) onCustomerIdChange(selectedCustomerId);
    } else if (onCustomerIdChange) onCustomerIdChange(ctx.customerId);

  };

  const renderOptions = (option) => {
    return <div key={'customer-select-potion-'+option.id}>{option.name}</div>;
  };

  return (
    <div className={cls.paper}>
      <Query
        query={GET_CUSTOMERS}
        variables={{
          customerIdArr: customerIdArray,
        }}
        onError={error => {
          console.error(error);
          Sentry.captureException(error);
        }}
      >
        {({ loading, error, data }) => {
          if (error) console.error(`Failed to retrieve customers:`, error);
          if (data && data.customers && data.customers.length > 0) {
            const allowedCustomers = data.customers && data.customers.length > 0 ? data.customers : [];
            const selectedCustomer = allowedCustomers.find(customer => customer.id === customerId);

            return (
              <Autocomplete
                id='option-multi-select'
                options={allowedCustomers}
                value={selectedCustomer}
                popupIcon={''}
                getOptionLabel={option => option.name || ''}
                onChange={(event, value) => {
                  handleCustomerChange(event, value);
                }}
                renderTags={() => {
                  return null;
                }}
                renderOption={(option) => renderOptions(option)}
                renderInput={params => (
                  <TextField
                    {...params}
                    data-testid='option-multiselect-textfield'
                    name='rooftop'
                    label={'Rooftops'}
                    placeholder={'Rooftops'}
                    size='small'
                    variant='outlined'
                    onKeyDown={event => {
                      if (event.key === 'Backspace') {
                        event.stopPropagation();
                      }
                    }}
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
              />
            );
          }
          return null;
        }}
      </Query>
    </div>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  paper: {
    display: 'block',
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
}));

////////// GQL //////////
const GET_CUSTOMERS = gql`
  query move_planner_get_customers($customerIdArr: [bigint!]) {
    customers(where: { id: { _in: $customerIdArr } }, order_by: { name: asc }) {
      id
      name
    }
  }
`;
