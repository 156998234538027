import { gql } from 'graphql-tag';

const GET_USER_DETAILS = gql`
  query get_user_details($userId: bigint!) {
    users(where: { id: { _eq: $userId } }) {
      id
      display_name
      email
      phone
      avatar_url
      default_role
      updated_at
      created_at
      active
      customer_id
      customer {
        id
        name
      }
      driver {
        id
      }
    }
  }
`;

const GET_CUSTOMERS = gql`
  query get_customer($customerId: bigint!) {
    customers(where: { id: { _eq: $customerId } }) {
      id
      name
      organization {
        customers {
          id
          name
        }
      }
    }
  }
`

// const GET_CUSTOMERS = gql`
// query GetOrganizationAndOtherCustomers($orgId: bigint!) {
//   customer(organization_id: $orgId) {
//     id
//     name
//     organization {
//       id
//       name
//       customers {
//         id
//         name
//       }
//     }
//   }
// }
// `

const GET_ALLOWED_CUSTOMERS = gql`
  query admin_userDetails_getAllowedCustomers($userId: bigint!) {
    usertocustomers(where: { user_id: { _eq: $userId } }, order_by: { customer_id: desc }) {
      customer_id
      customer {
        name
      }
    }
  }
`;

const GET_ALLOWED_PAYER_PAYEES = gql`
  query admin_userDetails_getPayersPayees($customerId: bigint!) {
    payertocustomer(where: { _or: [{ payee_id: { _eq: $customerId } }, { payer_id: { _eq: $customerId } }] }) {
      id
      payee_id
      payer_id
      payer {
        name
      }
      payee {
        name
      }
    }
  }
`;

const GET_ROLETYPES = gql`
  query admin_userDetails_getRoletypes {
    roletypes(order_by: { name: asc }) {
      id
      name
    }
  }
`;

const GET_ALLOWED_ROLES = gql`
  query admin_userDetails_getAllowedRoles($userId: bigint!) {
    usertoroles(where: { user_id: { _eq: $userId } }, order_by: { role_id: desc }) {
      role_id
      roletype {
        name
      }
    }
  }
`;

const UPSERT_ALLOWED_ROLES = gql`
  mutation admin_userDetails_upsertAllowedRoles($allowedRolesObj: [usertoroles_insert_input!]!) {
    insert_usertoroles(
      objects: $allowedRolesObj
      on_conflict: { constraint: usertoroles_user_id_role_id_key, update_columns: [] }
    ) {
      affected_rows
      returning {
        user_id
        role_id
      }
    }
  }
`;

const UPSERT_ALLOWED_CUSTOMERS = gql`
  mutation admin_userDetails_upsertAllowedCustomers($allowedCustomersObj: [usertocustomers_insert_input!]!) {
    insert_usertocustomers(
      objects: $allowedCustomersObj
      on_conflict: { constraint: usertocustomers_user_id_customer_id_key, update_columns: [] }
    ) {
      affected_rows
      returning {
        user_id
        customer_id
      }
    }
  }
`;

const SAVE_USER_DETAILS = gql`
  mutation save_user_details($userId: bigint!, $userObj: users_set_input!) {
    update_users(where: { id: { _eq: $userId } }, _set: $userObj) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

const REFRESH_CLAIMS = gql`
  mutation refresh_user_claims($userId: bigint!) {
    update_users(where: { id: { _eq: $userId } }, _set: { updated_at: "now()" }) {
      affected_rows
      returning {
        id
        updated_at
      }
    }
  }
`;

const TERMINATE_USER = gql`
  mutation admin_userDetails_terminateUser($userId: bigint!) {
    update_users(where: { id: { _eq: $userId } }, _set: { active: false }) {
      affected_rows
      returning {
        id
        active
      }
    }
  }
`;

const REINSTATE_USER = gql`
  mutation admin_userDetails_reinstateUser($userId: bigint!) {
    update_users(where: { id: { _eq: $userId } }, _set: { active: true }) {
      affected_rows
      returning {
        id
        active
      }
    }
  }
`;

const CREATE_USER = gql`
  mutation admin_userEntry_createUser($userObj: users_insert_input!) {
    insert_users(objects: [$userObj]) {
      affected_rows
      returning {
        id
        active
        created_at
      }
    }
  }
`;

const REMOVE_ALLOWED_ROLE = gql`
  mutation admin_userDetails_removeAllowedRole($roleId: bigint!, $userId: bigint!) {
    delete_usertoroles(where: {_and: {role_id: {_eq: $roleId}, user_id: {_eq: $userId}}}) {
      affected_rows
      returning {
        role_id
        user_id
      }
    }
  }
`

const REMOVE_ALLOWED_CUSTOMER = gql`
  mutation admin_userDetails_removeAllowedCustomer($customerId: bigint!, $userId: bigint!) {
    delete_usertocustomers(where: {_and: {customer_id: {_eq: $customerId}, user_id: {_eq: $userId}}}) {
      affected_rows
      returning {
        customer_id
        user_id
      }
    }
  }
`
const GET_ORG_CUSTOMERS = gql`
  query get_org_customers($customerId: bigint!) {
    organizations(where: { customers: { id: { _eq: $customerId } } }) {
      id
      name
      customers {
        id
        name
      }
    }
  }
`;


export {
  GET_USER_DETAILS,
  SAVE_USER_DETAILS,
  GET_CUSTOMERS,
  GET_ALLOWED_CUSTOMERS,
  GET_ALLOWED_PAYER_PAYEES,
  GET_ALLOWED_ROLES,
  REFRESH_CLAIMS,
  GET_ROLETYPES,
  TERMINATE_USER,
  REINSTATE_USER,
  UPSERT_ALLOWED_CUSTOMERS,
  UPSERT_ALLOWED_ROLES,
  CREATE_USER,
  REMOVE_ALLOWED_CUSTOMER,
  REMOVE_ALLOWED_ROLE,
  GET_ORG_CUSTOMERS
};
