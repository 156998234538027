import React from 'react';
import dayjs from 'dayjs';
import moment from 'moment';
import { toast } from 'react-toastify';
import { ExportToCsv } from 'export-to-csv';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';
import { getUserName } from '../../utils/authHelper.js';
import { useData } from '../../../DataProvider';
import useFormat from '../../hooks/useFormat';

import { INSERT_BATCH_JOBS } from './gql';

const log = false;

//////////////////////// HOOK ////////////////////////

export default function useMoveImport(props) {
  const ctx = useData();

  const { formatPhone } = useFormat();

  /** Parse the manual flag on the imported value */
  const parseBoolString = value => {
    if (value && typeof value === `string`) {
      let newValue = value.toLowerCase();
      if (newValue === `true` || newValue === `t` || newValue === `yes` || newValue === `y`) return true;
    }
    return false;
  };

  /** Convert built move entry to be used with the SDK
   ** Be sure to add any new fields to the extraction list below
   */
  const convertMoveEntry = async (entry, index, dupeStr = null) => {
    // Check for an entry object
    if (entry) {
      // Pull out standard entry fields and assign the rest to pickup_workflow_data
      const {
        // Dev dependency fields
        move_ids,
        order,
        success,
        // Standard fields
        request_type,
        ready_by_time_utc,
        appointment_time_utc,
        duplicate_check,
        customer_name,
        customer_phone,
        customer_action,
        pickup_location_full_address,
        pickup_location_address_one,
        pickup_location_address_two,
        pickup_location_city,
        pickup_location_state,
        pickup_location_zip,
        delivery_location_full_address,
        delivery_location_address_one,
        delivery_location_address_two,
        delivery_location_city,
        delivery_location_state,
        delivery_location_zip,
        move_one_type,
        move_one_reference_num,
        move_one_workflowset_id,
        move_one_dealer_contact,
        move_one_special_instructions,
        move_one_vehicle_stock,
        move_one_vehicle_vin,
        move_one_vehicle_year,
        move_one_vehicle_make,
        move_one_vehicle_model,
        move_one_vehicle_color,
        move_one_vehicle_manual,
        move_two_type,
        move_two_reference_num,
        move_two_workflowset_id,
        move_two_dealer_contact,
        move_two_special_instructions,
        move_two_vehicle_stock,
        move_two_vehicle_vin,
        move_two_vehicle_year,
        move_two_vehicle_make,
        move_two_vehicle_model,
        move_two_vehicle_color,
        move_two_vehicle_manual,
        // Config fields
        hide_service_location,
        // Extra fields
        ...extra
      } = entry;

      // Build pickup location object
      const pickupLocation = {
        full_address: pickup_location_full_address,
        address_one: pickup_location_address_one,
        address_two: pickup_location_address_two,
        city: pickup_location_city,
        state: pickup_location_state,
        zip: pickup_location_zip,
      };

      // Build delivery location object
      const deliveryLocation = {
        full_address: delivery_location_full_address,
        address_one: delivery_location_address_one,
        address_two: delivery_location_address_two,
        city: delivery_location_city,
        state: delivery_location_state,
        zip: delivery_location_zip,
      };

      // Build move one vehicle object
      const moveOneVehicle = {
        stock: move_one_vehicle_stock,
        vin: move_one_vehicle_vin,
        year: move_one_vehicle_year,
        make: move_one_vehicle_make,
        model: move_one_vehicle_model,
        color: move_one_vehicle_color,
        manual: parseBoolString(move_one_vehicle_manual),
      };

      // Build move two vehicle object
      const moveTwoVehicle = {
        stock: move_two_vehicle_stock,
        vin: move_two_vehicle_vin,
        year: move_two_vehicle_year,
        make: move_two_vehicle_make,
        model: move_two_vehicle_model,
        color: move_two_vehicle_color,
        manual: parseBoolString(move_two_vehicle_manual),
      };

      // Set additional config objects for each move
      let moveOneConfig = null;
      let moveTwoConfig = null;

      // Define the formatted request_type
      let formattedRequestType = typeof request_type === `string` ? request_type.toLowerCase().trim() : null;

      // Check for hiding service location on concierge moves
      if (
        (formattedRequestType === `concierge` || formattedRequestType === `concierge-loaner`) &&
        hide_service_location
      ) {
        moveOneConfig.hide_service_location = parseBoolString(hide_service_location);
        moveTwoConfig.hide_service_location = parseBoolString(hide_service_location);
      }

      // Set additional config objects for each move
      let moveOnePickupExtra = {};
      let moveOneDeliveryExtra = {};
      let moveTwoPickupExtra = {};
      let moveTwoDeliveryExtra = {};

      // If any extra fields exist, add them to the move(s) pickup and delivery workflow data
      if (extra && typeof extra === `object` && Object.keys(extra).length > 0) {
        let extraKeys = Object.keys(extra);
        let extraVals = Object.values(extra);

        // Hard map workflow data with string prefixes
        extraKeys.forEach((key, i) => {
          if (key.includes(`move_one_pickup_`)) {
            moveOnePickupExtra[key.split(`move_one_pickup_`)[1]] = extraVals[i];
          }
          if (key.includes(`move_one_delivery_`)) {
            moveOneDeliveryExtra[key.split(`move_one_delivery_`)[1]] = extraVals[i];
          }
          if (key.includes(`move_two_pickup_`)) {
            moveTwoPickupExtra[key.split(`move_two_pickup_`)[1]] = extraVals[i];
          }
          if (key.includes(`move_two_delivery_`)) {
            moveTwoDeliveryExtra[key.split(`move_two_delivery_`)[1]] = extraVals[i];
          }
        });
      }

      // Reset workflow data to null if empty object to save on db space and make code easier to handle
      if (!Object.keys(moveOnePickupExtra).length) moveOnePickupExtra = null;
      if (!Object.keys(moveOneDeliveryExtra).length) moveOneDeliveryExtra = null;
      if (!Object.keys(moveTwoPickupExtra).length) moveTwoPickupExtra = null;
      if (!Object.keys(moveTwoDeliveryExtra).length) moveTwoDeliveryExtra = null;

      // Build move one object
      const moveOne = {
        reference_num: move_one_reference_num,
        workflowset_id: move_one_workflowset_id,
        dealer_contact: move_one_dealer_contact,
        special_instructions: move_one_special_instructions,
        vehicle: moveOneVehicle,
      };

      // Build move two object
      const moveTwo = {
        reference_num: move_two_reference_num,
        workflowset_id: move_two_workflowset_id,
        dealer_contact: move_two_dealer_contact,
        special_instructions: move_two_special_instructions,
        vehicle: moveTwoVehicle,
      };

      // Build the base request object
      const request = {
        request_type: formattedRequestType,
        ready_by_time_utc: ready_by_time_utc ? dayjs(ready_by_time_utc).format() : null,
        appointment_time_utc: appointment_time_utc ? dayjs(appointment_time_utc).format() : null,
        duplicate_check: dupeStr || null,
        customer_name: customer_name,
        customer_phone: formatPhone(customer_phone),
        customer_action: typeof customer_action === `string` ? customer_action.toLowerCase() : null,
      };

      // Set prop names for each move
      let moveOnePropName = `move_one`;
      let moveTwoPropName = `move_two`;
      if (formattedRequestType === `concierge`) {
        moveOnePropName = `customer_move`;
      }
      if (formattedRequestType === `one-way`) {
        moveOnePropName = `move`;
      }
      if (formattedRequestType === `concierge-loaner`) {
        moveOnePropName = `${move_one_type}_move`;
        moveTwoPropName = `${move_two_type}_move`;
      }

      // Return single-move object
      if (formattedRequestType === `concierge` || formattedRequestType === `one-way`) {
        return {
          ...request,
          [moveOnePropName]: {
            ...moveOne,
            pickup_location: pickupLocation,
            delivery_location: deliveryLocation,
            config: moveOneConfig,
            pickup_workflow_data: moveOnePickupExtra,
            delivery_workflow_data: moveOneDeliveryExtra,
          },
        };
      }

      // Return multi-move object
      if (formattedRequestType === `concierge-loaner` || formattedRequestType === `round-trip`) {
        return {
          ...request,
          [moveOnePropName]: {
            ...moveOne,
            pickup_location: pickupLocation,
            config: moveOneConfig,
            pickup_workflow_data: moveOnePickupExtra,
            delivery_workflow_data: moveOneDeliveryExtra,
          },
          [moveTwoPropName]: {
            ...moveTwo,
            pickup_location: deliveryLocation,
            config: moveTwoConfig,
            pickup_workflow_data: moveTwoPickupExtra,
            delivery_workflow_data: moveTwoDeliveryExtra,
          },
        };
      }
    }

    // Otherwise, return null
    return null;
  };

  /** Convert uploaded csv data into a usable moves object array */
  const buildMovesFromCsvData = async (csvData, dupeStr) => {
    // Check for proper csv data
    if (csvData && csvData.data && Array.isArray(csvData.data)) {
      // Separate out the headers
      let headerKeysArray = csvData.data[0];
      let entriesArray = csvData.data;
      let entriesObjArray = [];

      // For-loop to build entry objects
      for (let i = 1; i < entriesArray.length; i++) {
        const entry = entriesArray[i];
        const entryObj = Object.fromEntries(headerKeysArray.map((header, index) => [header, entry[index] || null]));
        entriesObjArray.push(entryObj);
      }

      // Add ID field to the header keys and entries
      headerKeysArray.splice(0, 0, `order`);
      headerKeysArray.splice(1, 0, `move_ids`);
      headerKeysArray.splice(2, 0, `success`);
      entriesObjArray = entriesObjArray.map((entry, i) => {
        return { order: i + 1, move_ids: null, success: null, ...entry };
      });

      // Convert entries to the move object structure used with the sdk
      const moves = await Promise.all(
        entriesObjArray.map(async (entry, i) => await convertMoveEntry(entry, i + 1, dupeStr))
      );

      // Log out each set of data
      // log && console.log(`CSV Data:`, csvData);
      // log && console.log(`Headers:`, headerKeysArray);
      log && console.log(`Entries:`, entriesObjArray);
      log && console.log(`Moves:`, moves);

      // Return all necessary data
      return { headers: headerKeysArray, entries: entriesObjArray, moves };
    }

    // Fallback to empty array
    return { headers: [], entries: [], moves: [] };
  };

  const insertBatchJobs = async (batchId, moveReqs, customerId, options) => {
    // Check for moves
    if (moveReqs && Array.isArray(moveReqs) && moveReqs.length > 0) {
      // Check for customer ID
      if (customerId) {
        // Pass in moves to the batch jobs to be created
        try {
          const batchJobsToInsert = moveReqs.map((moveReq, i) => {
            return {
              batch_id: batchId,
              input: { moveReq: moveReq, customerId: customerId, options: options },
              sequence: i,
              trigger_type: 'csv',
              createdat: 'now()',
              createdby: getUserName()
            };
          });
          log && console.log(`Batch jobs to insert:`, batchJobsToInsert);

          const batchJobsRes = await ctx.apolloClient.mutate({
            mutation: INSERT_BATCH_JOBS,
            variables: { jobs: batchJobsToInsert },
          });
          log && console.log(`Batch jobs response:`, batchJobsRes);

          const batchJobsResArray = getPropValue(batchJobsRes, `data.insert_batch_jobs.returning`);
          const batchJobsResAffectedRows = getPropValue(batchJobsRes, `data.insert_batch_jobs.affected_rows`);

          if (batchJobsResAffectedRows > 0 && batchJobsResArray.length > 0) {
            log && console.log(`Batch jobs successful!`);
            toast.info(`Creating move(s), please wait...`, { autoClose: 2500 });
            return { success: true, batchJobs: batchJobsResArray };
          } else {
            console.error(`Failed to create moves: Move(s) did not build as expected!`);
            toast.error(`Failed to create moves: Move(s) did not build as expected!`);
            return { success: false };
          }
        } catch (err) {
          console.error(`Failed to create moves: Move(s) did not build as expected:`, err);
          toast.error(`Failed to create moves: Move(s) did not build as expected!`);
        }
      } else {
        console.error(`Failed to create moves: No Customer is selected!`);
        toast.error(`Failed to create moves: No Customer is selected!`);
      }
    } else {
      console.error(`Failed to create moves: No moves were provided!`);
      toast.error(`Failed to create moves: No moves were provided!`);
    }

    // Default return success false
    return { success: false };
  };

  /** Download a csv of moves that errored out before getting created */
  const downloadErrorMovesCSV = (headers, entries) => {
    if (headers && headers.length > 0 && entries && entries.length > 0) {
      // Remove order, move_ids and success fields from each array and filter
      let newHeaders = JSON.parse(JSON.stringify(headers));
      newHeaders.splice(0, 3);
      let newEntries = JSON.parse(JSON.stringify(entries));
      newEntries = newEntries.filter(entry => entry.success === false);
      newEntries = newEntries.map((entry, i) => {
        delete entry.order;
        delete entry.move_ids;
        delete entry.success;
        return entry;
      });
      log && console.log(`Headers & Entries to Export:`, newHeaders, newEntries);

      // Function that builds a single CSV row
      const createCsvRow = entry => {
        let row = {};
        newHeaders.forEach(header => (row[header] = entry[header] || ``));
        return row;
      };

      // Build the csv rows
      const csvRows = newEntries.map(entry => createCsvRow(entry));
      log && console.log(`csvRows`, csvRows);

      // Set csv options
      const csvOptions = {
        filename: `revised_move_import_${moment().format()}`,
        useKeysAsHeaders: true,
      };

      // Create and generate the CSV
      const csvExporter = new ExportToCsv(csvOptions);
      csvExporter.generateCsv(csvRows);
    }
  };

  /** Explanation for each column field */
  const explanations = [
    {
      label: `request_type`,
      required: [`Concierge`, `Concierge-\u2060Loaner`, `One-\u2060Way`, `Round-\u2060Trip`],
      acceptedValues: [`concierge`, `concierge-\u2060loaner`, `one-\u2060way`, `round-\u2060trip`],
      desc: `Move type supported by our system. "concierge" = Pick up your customer's vehicle and bring it in for service, or return it to them. "concierge-loaner" = Swap your customer's vehicle for a loaner and bring theirs in for service, or return their vehicle and bring back a loaner. "one-way" = Move a single vehicle from one location to another. "round-trip" = Swap two vehicles between locations. We'll deliver your first vehicle, then hop in your second and bring it right back.`,
    },
    {
      label: `ready_by_time_utc`,
      required: [`One-\u2060Way`, `Round-\u2060Trip`],
      desc: `The date and time the move(s) will be ready (non-concierge). We recommend the ISO\xa08601 format (e.g., "${dayjs().format()}"). Other formats may work, but are not supported. If no timezone is specified, your machine's local timezone will be used. Further information: https://en.wikipedia.org/wiki/ISO_8601 (Date and time in UTC).`,
    },
    {
      label: `appointment_time_utc`,
      required: [`Concierge`, `Concierge-\u2060Loaner`],
      desc: `The date and time of the appointment with your customer (concierge). We recommend the ISO\xa08601 format (e.g., "${dayjs().format()}"). Other formats may work, but are not supported. If no timezone is specified, your machine's local timezone will be used. Further information: https://en.wikipedia.org/wiki/ISO_8601 (Date and time in UTC).`,
    },
    {
      label: `customer_name`,
      required: [`Concierge`, `Concierge-\u2060Loaner`],
      desc: `The name of the customer whose vehicle is being moved.`,
    },
    {
      label: `customer_phone`,
      required: [],
      desc: `The phone number of the customer (automated SMS updates will be sent to this number if provided).`,
    },
    {
      label: `customer_action`,
      required: [`Concierge`, `Concierge-\u2060Loaner`],
      acceptedValues: [`pickup`, `return`],
      desc: `The type of concierge action being performed. "pickup" = Taking the vehicle to the service location. "return" = Returning the vehicle to the customer.`,
    },
    {
      label: `pickup_location_full_address`,
      required: [`Concierge`, `Concierge-\u2060Loaner`, `One-\u2060Way`, `Round-\u2060Trip`],
      requiredNote: `Required ONLY if no address pieces are provided.`,
      desc: `The full address of the pickup location. Only required when the required address pieces are not filled. Overwrites all address pieces if present.`,
    },
    {
      label: `pickup_location_address_one`,
      required: [`Concierge`, `Concierge-\u2060Loaner`, `One-\u2060Way`, `Round-\u2060Trip`],
      requiredNote: `Required ONLY if no full address is provided.`,
      desc: `The street address of the pickup location. Only required when full address is not present.`,
    },
    {
      label: `pickup_location_address_two`,
      required: [],
      desc: `Optional sub-address of the pickup location (This includes Apt, Suite, Unit, etc.).`,
    },
    {
      label: `pickup_location_city`,
      required: [`Concierge`, `Concierge-\u2060Loaner`, `One-\u2060Way`, `Round-\u2060Trip`],
      requiredNote: `Required ONLY if no full address is provided.`,
      desc: `The city that the pickup address is associated with. Only required when full address is not present.`,
    },
    {
      label: `pickup_location_state`,
      required: [`Concierge`, `Concierge-\u2060Loaner`, `One-\u2060Way`, `Round-\u2060Trip`],
      requiredNote: `Required ONLY if no full address is provided.`,
      desc: `The state that the pickup address is associated with. Only required when full address is not present.`,
    },
    {
      label: `pickup_location_zip`,
      required: [],
      desc: `The 5-digit zip code that the pickup address is associated with.`,
    },
    {
      label: `delivery_location_full_address`,
      required: [`Concierge`, `Concierge-\u2060Loaner`, `One-\u2060Way`, `Round-\u2060Trip`],
      requiredNote: `Required ONLY if no address pieces are provided.`,
      desc: `The full address of the delivery location. Only required when the required address pieces are not filled. Overwrites all address pieces if present.`,
    },
    {
      label: `delivery_location_address_one`,
      required: [`Concierge`, `Concierge-\u2060Loaner`, `One-\u2060Way`, `Round-\u2060Trip`],
      requiredNote: `Required ONLY if no full address is provided.`,
      desc: `The street address of the delivery location. Only required when full address is not present.`,
    },
    {
      label: `delivery_location_address_two`,
      required: [],
      desc: `Optional sub-address of the delivery location (This includes Apt, Suite, Unit, etc.).`,
    },
    {
      label: `delivery_location_city`,
      required: [`Concierge`, `Concierge-\u2060Loaner`, `One-\u2060Way`, `Round-\u2060Trip`],
      requiredNote: `Required ONLY if no full address is provided.`,
      desc: `The city that the delivery address is associated with. Only required when full address is not present.`,
    },
    {
      label: `delivery_location_state`,
      required: [`Concierge`, `Concierge-\u2060Loaner`, `One-\u2060Way`, `Round-\u2060Trip`],
      requiredNote: `Required ONLY if no full address is provided.`,
      desc: `The state that the delivery address is associated with. Only required when full address is not present.`,
    },
    {
      label: `delivery_location_zip`,
      required: [],
      desc: `The 5-digit zip code that the delivery address is associated with.`,
    },
    {
      label: `move_one_type`,
      required: [`Concierge-\u2060Loaner`],
      acceptedValues: [`loaner`, `customer`],
      desc: `For concierge-loaner type moves, we ask that you specify which vehicle is which. Generally, we prefer move_one to be 'loaner' type. This means the service location is the pickup.`,
    },
    {
      label: `move_one_reference_num`,
      required: [],
      desc: `Internal reference ID, such as a repair order # ("move_one" is always the first move in the sequence).`,
    },
    {
      label: `move_one_workflowset_id`,
      required: [],
      desc: `Workflows allow us to gather additional information not in the default workflow. If you want to setup a custom workflow, please contact your dispatcher.`,
    },
    {
      label: `move_one_dealer_contact`,
      required: [],
      desc: `Point of contact for our driver when arriving at the pickup location.`,
    },
    {
      label: `move_one_special_instructions`,
      required: [],
      desc: `Additional information such as where the keys are, where to park, or a number to call upon arrival.`,
    },
    {
      label: `move_one_vehicle_stock`,
      required: [],
      desc: `Internal stock number associated with the vehicle.`,
    },
    {
      label: `move_one_vehicle_vin`,
      required: [],
      desc: `Vehicle Identification Number associated with the vehicle.`,
    },
    {
      label: `move_one_vehicle_year`,
      required: [],
      desc: `Year the vehicle was manufactured.`,
    },
    {
      label: `move_one_vehicle_make`,
      required: [`Concierge`, `Concierge-\u2060Loaner`, `One-\u2060Way`, `Round-\u2060Trip`],
      desc: `The make of the vehicle (e.g., "Ford").`,
    },
    {
      label: `move_one_vehicle_model`,
      required: [`Concierge`, `Concierge-\u2060Loaner`, `One-\u2060Way`, `Round-\u2060Trip`],
      desc: `The model of the vehicle (e.g., "F-150").`,
    },
    {
      label: `move_one_vehicle_color`,
      required: [],
      desc: `The color of the vehicle.`,
    },
    {
      label: `move_one_vehicle_manual`,
      required: [`Concierge`, `Concierge-\u2060Loaner`, `One-\u2060Way`, `Round-\u2060Trip`],
      acceptedValues: [`true`, `false`],
      desc: `Whether or not the vehicle's transmission is manual (true) or automatic (false) - not case sensitive.`,
    },
    {
      label: `move_two_type`,
      required: [`Concierge-\u2060Loaner`],
      requiredNote: `ONLY used with Concierge-\u2060Loaner`,
      acceptedValues: [`loaner`, `customer`],
      desc: `For concierge-loaner type moves, we ask that you specify which vehicle is which. Generally, we prefer move_two to be 'customer' type. This means the customer's location is the delivery.`,
    },
    {
      label: `move_two_reference_num`,
      required: [],
      requiredNote: `ONLY used with Concierge-\u2060Loaner & Round-\u2060Trip.`,
      desc: `Internal reference ID, such as a repair order # ("move_two" is always the second move in the sequence - Only required multi-move requests like Concierge-Loaner & Round-Trip).`,
    },
    {
      label: `move_two_workflowset_id`,
      required: [],
      requiredNote: `ONLY used with Concierge-\u2060Loaner & Round-\u2060Trip.`,
      desc: `Workflows allow us to gather additional information not in the default workflow. If you want to setup a custom workflow, please contact your dispatcher.`,
    },
    {
      label: `move_two_dealer_contact`,
      required: [],
      requiredNote: `ONLY used with Concierge-\u2060Loaner & Round-\u2060Trip.`,
      desc: `Point of contact for our driver when arriving at the delivery location.`,
    },
    {
      label: `move_two_special_instructions`,
      required: [],
      requiredNote: `ONLY used with Concierge-\u2060Loaner & Round-\u2060Trip.`,
      desc: `Additional information such as where the keys are, where to park, or a number to call upon arrival.`,
    },
    {
      label: `move_two_vehicle_stock`,
      required: [],
      requiredNote: `ONLY used with Concierge-\u2060Loaner & Round-\u2060Trip.`,
      desc: `Internal stock number associated with the vehicle.`,
    },
    {
      label: `move_two_vehicle_vin`,
      required: [],
      requiredNote: `ONLY used with Concierge-\u2060Loaner & Round-\u2060Trip.`,
      desc: `Vehicle Identification Number associated with the vehicle.`,
    },
    {
      label: `move_two_vehicle_year`,
      required: [],
      requiredNote: `ONLY used with Concierge-\u2060Loaner & Round-\u2060Trip.`,
      desc: `Year the vehicle was manufactured.`,
    },
    {
      label: `move_two_vehicle_make`,
      required: [`Concierge-\u2060Loaner`, `Round-\u2060Trip`],
      requiredNote: `ONLY used with Concierge-\u2060Loaner & Round-\u2060Trip.`,
      desc: `The make of the vehicle (e.g., "Ford").`,
    },
    {
      label: `move_two_vehicle_model`,
      required: [`Concierge-\u2060Loaner`, `Round-\u2060Trip`],
      requiredNote: `ONLY used with Concierge-\u2060Loaner & Round-\u2060Trip.`,
      desc: `The model of the vehicle (e.g., "F-150").`,
    },
    {
      label: `move_two_vehicle_color`,
      required: [],
      requiredNote: `ONLY used with Concierge-\u2060Loaner & Round-\u2060Trip.`,
      desc: `The color of the vehicle.`,
    },
    {
      label: `move_two_vehicle_manual`,
      required: [`Concierge-\u2060Loaner`, `Round-\u2060Trip`],
      requiredNote: `ONLY used with Concierge-\u2060Loaner & Round-\u2060Trip.`,
      acceptedValues: [`true`, `false`],
      desc: `Whether or not the vehicle's transmission is manual (true) or automatic (false) - not case sensitive.`,
    },
  ];

  const configExplanations = [
    {
      label: `hide_service_location`,
      required: [],
      requiredNote: `ONLY used with Concierge & Concierge-\u2060Loaner.`,
      acceptedValues: [`true`, `false`],
      desc: `Don't show the service/delivery location to your customers (true) or allow them to see the name and address (false) - not case sensitive - defaults to "false".`,
    },
  ];

  // Return hook logic
  return { buildMovesFromCsvData, insertBatchJobs, downloadErrorMovesCSV, explanations, configExplanations };
}
