import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Typography, Tooltip, Icon } from '@material-ui/core';
import { useData } from '../../DataProvider';
import OverviewHeader from './organizationOverview/OverviewHeader';
import OverviewBody from './organizationOverview/OverviewBody';
import { DateTime } from 'luxon';
import fragments from '../utils/graphQL/fragments';

const log = false;

export default function OrganizationOverview(props) {
  const cls = useStyles();
  const ctx = useData();

  const today = localStorage.getItem('overview-date') || DateTime.now().toString();

  const [date, setDate] = useState(today);
  const [customers, setCustomers] = useState([]);

  const queryCustomersAndMoves = async () => {
    log && console.log(date);
    let dt = DateTime.fromISO(date);
    //create start and end of day ISO strings so that the query will only select one day's worth of moves
    let startOfDate = dt.startOf('day').toString();
    let endOfDate = dt.endOf('day').toString();
    log && console.log('dates', date, startOfDate, endOfDate);

    try {
      await ctx.apolloClient
        .query({
          query: gql`
            query get_customers_and_moves($start: timestamptz!, $end: timestamptz!) {
              customers(order_by: { id: desc }) {
                id
                name
                moves(
                  where: {
                    active: { _eq: 1 }
                    move_type: {_ilike: "drive"}
                    _or: [{consumer_type: {_is_null: true}}, {consumer_type: {_nilike: "loaner"}}]
                    _and: [{ ready_by: { _gte: $start } }, { ready_by: { _lte: $end } }]
                  }
                  order_by: { ready_by: asc }
                ) {
                  ...Move
                }
              }
            }
            ${fragments.move}
          `,
          variables: { start: startOfDate, end: endOfDate },
        })
        .then(res => {
          log && console.log('RES', res);
          //TODO: add type-error checks
          if (res && res.data && res.data.customers) {
            let customers = res.data.customers;
            log && console.log('customers', customers);
            setCustomers(customers);
          } else {
            console.error('no customers found');
          }
        });
    } catch (err) {
      console.error('Unexpected error quering customers:', err);
    }
  };

  //Query allowed customers
  useEffect(() => {
    //Had to add this check at the top because otherwise app would attempt to run the query before ctx.apollo was initialized
    if (ctx && ctx.apolloClient && ctx.apolloClient.query) queryCustomersAndMoves();
  }, [date]);

  return (
    customers &&
    customers.length > 1 && (
      <Paper className={cls.root}>
        <OverviewHeader customers={customers} date={date} setDate={setDate} />
        <OverviewBody customers={customers} history={props.history} />
      </Paper>
    )
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    width: 'fit-content',
  },
  container: {
    // color: theme.palette.text.primary,
    /* grid container settings */
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  row: {
    flex: 1,
    height: '60px',
    display: 'flex',
  },
  timeBlock: {
    flex: '0, 0, 25em',
    wordBreak: 'normal',
    padding: '10px',
    margin: '10px',
  },
  moveBlock: {
    flex: 1,
    textAlign: 'center',
  },
}));
