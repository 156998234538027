import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useData } from '../../../DataProvider';
import { getUserToken } from '../../utils/authHelper'

import gql from 'graphql-tag';
import { Subscription } from 'react-apollo';

import { useTools } from '../../hooks/useTools';

import { makeStyles, Typography, Grid, TextField, MenuItem, Link } from '@material-ui/core';
import { Modal, ModalHeader, ModalContent, ModalFooter, ModalAction } from '../../reusable/Modal';

import Loading from '../../utils/Loading';
import Success from '../../utils/Success';

const log = false;

////////// COMPONENT //////////
export default function PayNowModal(props) {
  const ctx = useData();
  const cls = useStyles();

  const { getMonetaryValue } = useTools();

  const { open, onClose, payNowInput, initialFormState, refetch } = props;

  let methods = [];
  let processingFee = payNowInput && payNowInput.due ? payNowInput.due * 0.03 : 0;

  // Controls which form should render ('pay-now', 'pay-processing', 'pay-success')
  const [formState, setFormState] = useState(`pay-now`);

  const [methodIndex, setMethodIndex] = useState(0);
  const [methodObj, setMethodObj] = useState(null);
  const [payError, setPayError] = useState(null);

  useEffect(() => {
    if (initialFormState) setFormState(initialFormState);
    log && console.log(`Selected method obj:`, methodObj);
  }, [initialFormState, methodObj]);

  const handleMethodChange = event => {
    if (event) {
      setMethodIndex(event.target.value);
      setPayError(null);
    }
  };

  const handleClose = () => {
    if (onClose) onClose();
    setMethodIndex(0);
    setMethodObj(null);
    setPayError(null);
    refetch();
  };

  // Call netlify function
  const handleProcessPayment = async () => {
    const token = await getUserToken();
    if (methodObj) {
      try {
        setFormState(`pay-processing`);
        axios({
          method: `POST`,
          url: `/.netlify/functions/processPaymentAR`,
          data: {
            armoves: payNowInput.selectedInvoice.armoves,
            callMethod: `dealer-pay-button`,
            paymentMethod: methodObj,
            invoiceId: payNowInput.selectedInvoice.id,
          },
          headers: {
            authorization: `Bearer ${token}`,
          },
        })
          .then(res => {
            console.log('RES', res);
            if (res.data === 'success') {
              log && console.log(`Process payment success:`, res.data);
              setFormState(`pay-success`);
            } else {
              console.error(`Process payment failure:`, res.data);
              setPayError(`Payment failed to process!`);
              setFormState(`pay-now`);
            }
          })
          .catch(err => {
            console.error(`An error occurred while processing the payment:`, err);
            setPayError(`An error occurred while processing your payment!`);
            setFormState(`pay-now`);
          });
      } catch (err) {
        console.error(`An error occurred when attempting to POST payment:`, err);
        setPayError(`An error occurred when attempting to POST payment!`);
      }
    } else {
      setPayError(`Error finding a payment method. Please select a valid payment method to continue.`);
    }
  };

  return (
    <>
      <Modal open={open}>
        {formState === `pay-now` && payNowInput && (
          <>
            <ModalHeader handleClose={handleClose}>Pay Now</ModalHeader>

            <ModalContent
              subtitle={`By clicking "Make Payment", your method of payment will be charged for the amount displayed as the "Amount Due". If there are any disputed moves, the invoice will be considered partially paid until an agreement\xa0is\xa0reached.`}
            >
              {payError !== null ? (
                <>
                  <Typography style={{ color: 'red', fontWeight: 500 }}>{payError}</Typography>
                  <div className={cls.break} />
                </>
              ) : null}
              <Subscription
                subscription={GET_PAYMENT_METHODS}
                variables={{
                  customerId: parseInt(
                    ctx.customerOverride || ctx.customerId
                  ),
                }}
              >
                {({ loading, error, data }) => {
                  if (loading) return <Loading />;
                  if (error) console.log(`Error finding payment methods:`, error);
                  if (data && data.arpaymentmethods && data.arpaymentmethods.length > 0) {
                    methods = data.arpaymentmethods;
                    setMethodObj(methods[methodIndex]);
                    return (
                      <>
                        <div className={cls.break} />
                        <TextField
                          fullWidth
                          required
                          select
                          label='Payment Method'
                          helperText={
                            methodObj && methodObj.type === 'card'
                              ? `NOTE: A processing fee will be included when using a card.`
                              : ``
                          }
                          margin='none'
                          variant='outlined'
                          value={methodIndex}
                          onChange={handleMethodChange}
                        >
                          <MenuItem key={'Select Payment Method'} value={``}>
                            <em>Select Payment Method</em>
                          </MenuItem>
                          {methods
                            ? methods.map((m, i) => (
                                <MenuItem key={m.id} value={i}>
                                  {m.name}
                                  {m.primary === true ? ` (Primary)` : null}
                                </MenuItem>
                              ))
                            : null}
                        </TextField>
                        <div className={cls.smallBreak} />
                        <Typography style={{ fontSize: '12px' }}>
                          <Link href='/billing'>Manage Payment Methods</Link>
                        </Typography>
                        <div className={cls.bigBreak} />
                      </>
                    );
                  } else {
                    return (
                      <>
                        <div className={cls.smallBreak} />
                        <Typography style={{ fontSize: '12px' }}>
                          <Link href='/billing'>Enter a Payment Method to pay Invoice</Link>
                        </Typography>
                        <div className={cls.bigBreak} />
                      </>
                    );
                  }
                }}
              </Subscription>
              <Grid container spacing={0}>
                {payNowInput.subtotal && (payNowInput.discounted || payNowInput.disputed) ? (
                  <>
                    <Grid item xs={6}>
                      <Typography className={cls.key}>Subtotal</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className={cls.val}>{getMonetaryValue(payNowInput.subtotal)}</Typography>
                    </Grid>

                    {payNowInput.discounted ? (
                      <>
                        <Grid item xs={6}>
                          <Typography className={cls.key}>Discounted</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography className={cls.val}>({getMonetaryValue(payNowInput.discounted)})</Typography>
                        </Grid>
                      </>
                    ) : null}

                    {payNowInput.disputed ? (
                      <>
                        <Grid item xs={6}>
                          <Typography className={cls.key}>Disputed</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography className={cls.val}>({getMonetaryValue(payNowInput.disputed)})</Typography>
                        </Grid>
                      </>
                    ) : null}

                    <div className={cls.lineBreak} />
                  </>
                ) : null}

                {payNowInput.total || payNowInput.total === 0 ? (
                  <>
                    <Grid item xs={6}>
                      <Typography className={cls.keyDemi}>Total</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className={cls.valDemi}>{getMonetaryValue(payNowInput.total)}</Typography>
                    </Grid>
                  </>
                ) : null}

                {methodObj && methodObj.type === 'card' && processingFee ? (
                  <>
                    <Grid item xs={6}>
                      <Typography className={cls.keyDemi}>Processing Fee</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className={cls.valDemi}>{getMonetaryValue(processingFee)}</Typography>
                    </Grid>
                  </>
                ) : null}

                {payNowInput.paid || payNowInput.paid === 0 ? (
                  <>
                    <Grid item xs={6}>
                      <Typography className={cls.keyDemi}>Amount Paid</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className={cls.valDemi}>({getMonetaryValue(payNowInput.paid)})</Typography>
                    </Grid>
                  </>
                ) : null}

                <div className={cls.lineBreak} />

                {payNowInput.due || payNowInput.due === 0 ? (
                  <>
                    <Grid item xs={6}>
                      <Typography className={cls.keyGrand}>Amount Due</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      {methodObj && methodObj.type === 'card' && processingFee ? (
                        <Typography className={cls.valGrand}>
                          {getMonetaryValue(payNowInput.due + processingFee)}
                        </Typography>
                      ) : (
                        <Typography className={cls.valGrand}>{getMonetaryValue(payNowInput.due)}</Typography>
                      )}
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </ModalContent>

            <ModalFooter>
              <ModalAction onClick={() => handleProcessPayment()} disabled={!methodObj || payError}>
                Make Payment
              </ModalAction>
              <ModalAction onClick={() => handleClose()} color='secondary'>
                Cancel
              </ModalAction>
            </ModalFooter>
          </>
        )}

        {formState === `pay-processing` && (
          <>
            <ModalHeader>Processing Payment...</ModalHeader>

            <ModalContent>
              <div className={cls.break} />
              <Loading relative />
              <div className={cls.break} />
            </ModalContent>
          </>
        )}

        {formState === `pay-success` && (
          <>
            <ModalHeader handleClose={handleClose}>Payment Approved!</ModalHeader>

            <ModalContent>
              <Success />
            </ModalContent>

            <ModalFooter>
              <ModalAction onClick={() => handleClose()} color='secondary'>
                Done
              </ModalAction>
            </ModalFooter>
          </>
        )}
      </Modal>
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  key: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    fontSize: '16px',
    fontWeight: 400,
  },
  val: {
    color: theme.palette.text.secondary,
    textAlign: 'right',
    fontSize: '16px',
    fontWeight: 400,
  },
  keyDemi: {
    color: theme.palette.text.tertiary,
    textAlign: 'left',
    fontSize: '16px',
    fontWeight: 400,
  },
  valDemi: {
    color: theme.palette.text.tertiary,
    textAlign: 'right',
    fontSize: '16px',
    fontWeight: 400,
  },
  keyBold: {
    textAlign: 'left',
    fontSize: '16px',
    fontWeight: 400,
  },
  valBold: {
    textAlign: 'right',
    fontSize: '16px',
    fontWeight: 400,
  },
  keyGrand: {
    textAlign: 'left',
    fontSize: '20px',
    fontWeight: 600,
  },
  valGrand: {
    textAlign: 'right',
    fontSize: '20px',
    fontWeight: 600,
  },

  block: {
    display: 'block',
  },
  smallBreak: {
    width: '100%',
    height: theme.spacing(0.5),
  },
  break: {
    width: '100%',
    height: theme.spacing(1.5),
  },
  bigBreak: {
    width: '100%',
    height: theme.spacing(2.5),
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    margin: '8px 0',
    background: '#ddd',
  },
}));

const GET_PAYMENT_METHODS = gql`
  subscription get_payment_methods($customerId: bigint!) {
    arpaymentmethods(
      where: { customer_id: { _eq: $customerId }, active: { _eq: true }, type: { _neq: "manual" } }
      order_by: { primary: desc, name: asc }
    ) {
      id
      customer_id
      gateway_token
      active
      primary
      account_type
      account_number
      name
      type
      card_type
      expiration
      routing_number
      source
    }
  }
`;
