import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { DateTime } from 'luxon';
import OverviewMoveItem from './OverviewMoveItem';

const log = false;

export default function OverviewBody(props) {
  const cls = useStyles();
  const { customers } = props;

  const [hours, setHours] = useState(['12am']);

  //Generate array of hour objects
  useEffect(() => {
    let hours = generateHours();
    setHours(hours);
    log && console.log('hours', hours);
  }, []);

  useEffect(() => {
    if (hours.length > 1) {
      //scrolls 6am row to the top, making 8am the top visible row on load
      document.getElementById('row6').scrollIntoView();
    }
  }, [hours]);

  //Loop to generate hours, each objcet contains hour as integer and string for rows
  const generateHours = () => {
    let hours = [{ value: 0, label: '12:00am' }];
    for (var i = 1; i < 24; i++) {
      if (i < 12) {
        hours.push({ value: i, label: `${i}:00am` });
      } else if (i === 12) {
        hours.push({ value: i, label: `12:00pm` });
      } else {
        hours.push({ value: i, label: `${i - 12}:00pm` });
      }
    }
    return hours;
  };

  //Convert moves pickup time into an integer representing the hour
  const getMoveHour = move => {
    let moveISO = move.ready_by;
    let moveHour = DateTime.fromISO(moveISO).hour;
    return moveHour;
  };

  //Generate cell with the correct moves (filtered by customer and hour)
  const timeSlotBox = (hour, moves) => {
    if (moves.length > 0) {
      const hoursMoves = moves.filter(move => getMoveHour(move) === hour.value);
      log && console.log('move col', moves);
      return hoursMoves.map(move => {
        return <OverviewMoveItem key={move.id+'move-item-cell'} move={move} history={props.history} />;
      });
    } else {
      return null;
    }
  };

  return (
    hours &&
    hours.map(hour => {
      return (
        <div id={'row' + hour.value} key={'row' + hour.value} className={cls.hourRow}>
          <Typography key={'cell' + hour.value} className={cls.timeBlock}>
            {hour.label}
          </Typography>
          {customers.map(customer => {
            let customerMoves = customer.moves;
            log && console.log('customerMove', customerMoves);
            return (
              <div key={'column' + customer.name + hour.value} className={cls.column}>
                {timeSlotBox(hour, customerMoves)}
              </div>
            );
          })}
        </div>
      );
    })
  );
}

const useStyles = makeStyles(theme => ({
  hourRow: {
    flex: '1, 1, content',
    display: 'flex',
    '&:nth-of-type(odd)': {
      backgroundColor: 'rgb(229, 232, 232)',
    },
    '&:nth-of-type(even)': {
      backgroundColor: 'rgb(247, 249, 249)',
    },
  },
  timeBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '160px',
    wordBreak: 'normal',
    padding: '10px',
    textAlign: 'center',
    position: 'sticky',
    left: '0',
    borderRight: '1px solid #bbb',
    backgroundColor: 'inherit',
    zIndex: 2,
  },
  column: {
    flex: 1,
    textAlign: 'center',
    borderRight: '1px solid #bbb',
    padding: '4px',
    minWidth: '164px',
  },
}));
