//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { useData } from '../../../DataProvider';
import dayjs from 'dayjs';
import { makeStyles, Typography } from '@material-ui/core';
import { Loading } from '@hopdrive/storybook';

import * as Sentry from '@sentry/react';
import { Query } from 'react-apollo';
import { GET_INVOICES } from './gql';

import { useInvoices } from './useInvoices';

import DefaultEmptyFallback from '../../reusable/Fallbacks/DefaultEmptyFallback';
import DefaultErrorFallback from '../../reusable/Fallbacks/DefaultErrorFallback';
import { InvoicesListFilter, InvoicesListItem } from './index';
import { getCustomerId } from '../../utils/authHelper'

//////////////////////// COMPONENT ////////////////////////

export default function InvoicesList({ invoiceId, setInvoiceId }) {
  const cls = useStyles();
  const ctx = useData();

  const { getDefaultStart, getDefaultEnd } = useInvoices();


  const [selectedCustomerId, setSelectedCustomerId] = React.useState(ctx.customerOverride || ctx.customerId);
  const [start, setStart] = React.useState(getDefaultStart());
  const [end, setEnd] = React.useState(getDefaultEnd());
  const [customerId, setCustomerId] = React.useState(ctx.customerId)

  React.useEffect(() => {
    if (ctx.customerId) setCustomerId(ctx.customerId)
    if (ctx.customerOverride) {
      setSelectedCustomerId(ctx.customerOverride)
    } else if (ctx.customerId) {
      setSelectedCustomerId(ctx.customerId)
    }
  }, [ctx.customerId, ctx.customerOverride])

  React.useEffect(() => {
    // setSelectedCustomerId(customerId);
    setInvoiceId(0);
  }, [customerId, selectedCustomerId]);

  const handleDateChange = (value, name) => {
    const newDate = dayjs(value).format();
    if (name === `start`) {
      localStorage.setItem(`invoice-list-start`, newDate);
      setStart(newDate);
    } else {
      localStorage.setItem(`invoice-list-end`, newDate);
      setEnd(newDate);
    }
  };

  return (
    <Query
      query={GET_INVOICES}
      variables={{ customerId: selectedCustomerId, start: start, end: end }}
      onError={error => {
        console.error(error);
        Sentry.captureException(error);
      }}
    >
      {({ loading, error, data, refetch }) => {
        // LOADING STATE //
        if (loading) {
          return (
            <div className={cls.list}>
              <InvoicesListFilter start={start} end={end} onDateChange={handleDateChange} />
              <Loading absolute />
            </div>
          );
        }

        // ERROR STATE //
        if (error) {
          console.error(`Error fetching invoices:`, error);
          Sentry.captureException(error);
          return (
            <div className={cls.list}>
              <InvoicesListFilter start={start} end={end} onDateChange={handleDateChange} />
              <DefaultErrorFallback message='ERROR FETCHING INVOICES' />
            </div>
          );
        }

        // EMPTY STATE //
        if (!data || !data.arinvoices || !data.arinvoices.length > 0) {
          return (
            <div className={cls.list}>
              <InvoicesListFilter start={start} end={end} onDateChange={handleDateChange} />
              <DefaultEmptyFallback message='NO INVOICES FOUND' />
            </div>
          );
        }

        // DATA STATE //
        const invoices = data.arinvoices;
        return (
          <div className={cls.list}>
            <InvoicesListFilter start={start} end={end} onDateChange={handleDateChange} />
            {invoices.map((invoice, i) => (
              <React.Fragment key={`invoice-list-item-${i}`}>
                <InvoicesListItem invoice={invoice} selectedInvoiceId={invoiceId} setSelectedInvoiceId={setInvoiceId} />
              </React.Fragment>
            ))}
            <Typography className={cls.footnote}>*All datetimes are presented in UTC</Typography>
          </div>
        );
      }}
    </Query>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  list: {
    position: 'relative',
    width: 320,
    height: '100%',
    borderLeft: theme.border[0],
    background: theme.palette.background.main,
    overflow: 'auto',
  },
  footnote: {
    padding: theme.spacing(1),
    fontSize: 12,
  },
}));
