import React from 'react';
import { makeStyles } from '@material-ui/core';

import DefaultEmptyFallback from '../../reusable/Fallbacks/DefaultEmptyFallback';
import UserAddToolbar from './UserAddToolbar';

import { UserDetailsProvider } from '../UserDetails/UserDetailsProvider';
import UserAddForm from './UserAddForm';
import { getUserRole } from '../../utils/authHelper';

//////////////////////// COMPONENT ////////////////////////

export default function UserAddWrapper(props) {
  const cls = useStyles();
  const user = {};

  const [role, setRole] = React.useState(null);
  const fetchRole = async () => {
    let userRole = await getUserRole();
    if (userRole) setRole(userRole);
  };
  React.useEffect(() => {
    fetchRole();
  }, []);
  if (role !== 'dealer-super-admin') {
    return (
      <div className={cls.root}>
        <DefaultEmptyFallback message='RESTRICTED ACCESS' />
      </div>
    );
  } else {
    return (
      <UserDetailsProvider user={user}>
        <div className={cls.root}>
          <UserAddToolbar />
          <UserAddForm user={user} />
        </div>
      </UserDetailsProvider>
    );
  }
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(7),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
}));
