import React from 'react';
import { makeStyles, Grid, Typography, Checkbox, Tooltip, IconButton, Icon } from '@material-ui/core';
import ReusableMultiselect from '../../reusable/selectors/ReusableMultiselect';
import { getUserEmail } from '../../utils/authHelper';

const log = false;

////////// COMPONENT //////////
export default function DashboardFilter(props) {
  const cls = useStyles();

  const { moves, handleClearFilters, tags, setTags, customerIsThirdPartyPayer = false } = props;

  let filter = [];

  const showConciergeCheck = moves.filter(move => move.consumer_pickup === true).length > 0;
  const showHangtagCheck = moves.filter(move => move.hangtags && move.hangtags.length).length > 0;

  React.useEffect(() => {
    log && console.log('tags', tags);
  }, [tags]);

  React.useEffect(() => {
    //If customer is not third party payer, we need to clear out this grouping option
    if (!customerIsThirdPartyPayer) {
      localStorage.setItem(`dashboard-group-rooftop`, false);
      setTags['rooftop'](false);
    }
  }, [customerIsThirdPartyPayer]);

  const getFilters = () => {
    if (moves && moves.length > 0) {
      let statuses = [];
      let makes = [];
      let models = [];
      let years = [];
      let colors = [];
      let customerNames = [];
      let userNames = []

      statuses = [`Planning`, `Pickup`, `Delivery`, `Completed`, `Canceled`];

      makes = moves
        .map(move => move.vehicle_make)
        .filter((make, i) => make !== null && make !== ``)
        .sort();
      makes = makes.filter((make, i) => makes.indexOf(make) === i);

      models = moves
        .map(move => move.vehicle_model)
        .filter((model, i) => model !== null && model !== ``)
        .sort();
      models = models.filter((model, i) => models.indexOf(model) === i);

      years = moves
        .map(move => move.vehicle_year)
        .filter((year, i) => year !== null && year !== ``)
        .sort();
      years = years.filter((year, i) => years.indexOf(year) === i);

      colors = moves
        .map(move => move.vehicle_color)
        .filter((color, i) => color !== null && color !== ``)
        .sort();
      colors = colors.filter((color, i) => colors.indexOf(color) === i);

      const moveCustomers = moves
        .filter(move => (move.customer && move.customer.name ? move.customer.name : false))
        .map(move => move.customer.name);
      //Sets can only contain unique values, so this will remove duplicates
      customerNames = [...new Set(moveCustomers)];

      let userSet = new Set();
      moves.forEach(move => {
        userSet.add(getUserEmail())
        if (move.eventlogs && move.eventlogs.length > 0) {
          userSet.add(move.eventlogs[0].user)
        }
      });

      userNames = Array.from(userSet).sort();

      log && console.log(`Statuses`, statuses);
      log && console.log(`Makes`, makes);
      log && console.log(`Models`, models);
      log && console.log(`Years`, years);
      log && console.log(`Colors`, colors);
      log && console.log(`Rooftops`, customerNames);
      log && console.log(`Users`, userNames);

      return { statuses, makes, models, years, colors, customerNames, userNames};
    } else return null;
  };

  filter = getFilters();

  const handleTagsArray = (name, arr) => {
    console.log('handleTagsArray', name, arr);
    if (arr && arr.length) {
      let newTags = arr.map(item => item.name);
      setTags[name]([...newTags]);
    } else {
      setTags[name]([]);
    }
  };

  const handleConcierge = () => {
    localStorage.setItem(`dashboard-group-concierge`, !tags.concierge);
    setTags['concierge'](!tags.concierge);
    //Only one grouping can be active at a time, so force the rooftops grouping off
    // localStorage.setItem(`dashboard-group-rooftop`, false);
    // setTags['rooftop'](false);
  };

  const handleRooftop = () => {
    localStorage.setItem(`dashboard-group-rooftop`, !tags.rooftop);
    setTags['rooftop'](!tags.rooftop);
    //Only one grouping can be active at a time, so force the customer grouping off
    // localStorage.setItem(`dashboard-group-concierge`, false);
    // setTags['concierge'](false);
  };

  const handleHangtag = () => {
    localStorage.setItem(`dashboard-group-hangtag`, !tags.hangtag);
    setTags['hangtag'](!tags.hangtag);
    //Only one grouping can be active at a time, so force the customer grouping off
    // localStorage.setItem(`dashboard-group-hangtags`, false);
    // setTags['hangtags'](false);
  };

  return (
    <>
      <div className={cls.paper}>
        <div className={cls.clear}>
          <Tooltip title='Clear Filters'>
            <IconButton onClick={handleClearFilters}>
              <Icon>cached</Icon>
            </IconButton>
          </Tooltip>
        </div>

        <Typography className={cls.head}>Filtering Options</Typography>

        {showConciergeCheck || customerIsThirdPartyPayer || showHangtagCheck ? (
          <Typography className={cls.sub}>Groups</Typography>
        ) : null}

        {showConciergeCheck ? (
          <Grid container alignItems='center'>
            <Grid item>
              <Checkbox
                className={cls.optionCheck}
                color='primary'
                checked={tags.concierge}
                onChange={handleConcierge}
              />
            </Grid>
            <Grid item xs>
              <Typography className={cls.optionTxt}>Group By Customer</Typography>
            </Grid>
          </Grid>
        ) : null}

        {customerIsThirdPartyPayer ? (
          <Grid container alignItems='center'>
            <Grid item>
              <Checkbox className={cls.optionCheck} color='primary' checked={tags.rooftop} onChange={handleRooftop} />
            </Grid>
            <Grid item xs>
              <Typography className={cls.optionTxt}>Group By Rooftop</Typography>
            </Grid>
          </Grid>
        ) : null}

        {showHangtagCheck ? (
          <Grid container alignItems='center'>
            <Grid item>
              <Checkbox className={cls.optionCheck} color='primary' checked={tags.hangtag} onChange={handleHangtag} />
            </Grid>
            <Grid item xs>
              <Typography className={cls.optionTxt}>Moves With Hangtags</Typography>
            </Grid>
          </Grid>
        ) : null}

        {customerIsThirdPartyPayer && filter && filter.customerNames && filter.customerNames.length > 0 ? (
          <>
            <Typography className={cls.sub}>Rooftops</Typography>
            {/* I put in this hacky incline map function so the reusable multiselect component works with the existing tag arrays in Dashboard's state.
                In future I'd like to refactor the "tags" arrays to be objects with ids instead of strings.*/}
            <ReusableMultiselect
              dataArray={filter.customerNames.map(item => ({ id: item, name: item }))}
              selectedArray={tags.customerNames}
              label={'Rooftops'}
              handleOutput={item => handleTagsArray(`customerNames`, item)}
              noIdInLabel={true}
            />
          </>
        ) : null}

        {filter && filter.userNames && filter.userNames.length > 0 ? (
          <>
            <Typography className={cls.sub}>Users</Typography>
            <ReusableMultiselect
              dataArray={filter.userNames.map(item => ({ id: item, name: item }))}
              selectedArray={tags.userNames}
              label={'Users'}
              handleOutput={item => handleTagsArray(`userNames`, item)}
              noIdInLabel={true}
            />
          </>
        ) : null}

        {filter && filter.statuses.length > 0 ? (
          <>
            <Typography className={cls.sub}>Move Status</Typography>
            <ReusableMultiselect
              dataArray={filter.statuses.map(item => ({ id: item, name: item }))}
              selectedArray={tags.status}
              label={'Statuses'}
              handleOutput={item => handleTagsArray(`status`, item)}
              noIdInLabel={true}
            />
          </>
        ) : null}

        {filter && filter.makes.length > 0 ? (
          <>
            <Typography className={cls.sub}>Vehicle Make</Typography>
            <ReusableMultiselect
              dataArray={filter.makes.map(item => ({ id: item, name: item }))}
              selectedArray={tags.make}
              label={'Makes'}
              handleOutput={item => handleTagsArray(`make`, item)}
              noIdInLabel={true}
            />
          </>
        ) : null}

        {filter && filter.models.length > 0 ? (
          <>
            <Typography className={cls.sub}>Vehicle Model</Typography>
            <ReusableMultiselect
              dataArray={filter.models.map(item => ({ id: item, name: item }))}
              selectedArray={tags.model}
              label={'Models'}
              handleOutput={item => handleTagsArray(`model`, item)}
              noIdInLabel={true}
            />
          </>
        ) : null}

        {!filter ? (
          <>
            <Typography className={cls.notFound}>No filters found.</Typography>
          </>
        ) : null}
      </div>
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: 240,
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  clear: {
    position: 'absolute',
    top: '5px',
    right: '5px',
  },
  head: {
    marginBottom: theme.spacing(1),
    lineHeight: 1.25,
    fontSize: 21,
    fontWeight: 600,
  },
  sub: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    lineHeight: 1.25,
    fontSize: 18,
    fontWeight: 600,
  },
  optionCheck: {
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  optionTxt: {
    marginTop: 2,
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
  },
  notFound: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    fontSize: 18,
    fontWeight: 500,
  },
}));
