import React from 'react';
import dayjs from 'dayjs';
import { useTools } from '../../hooks/useTools';
import { useHistory } from 'react-router-dom';
import { makeStyles, Grid, Typography, Tooltip, TextField, Button } from '@material-ui/core';

import Divide from '../../reusable/Divide';
const placeholder = require('../../../static/vehicle-placeholder.jpg');

////////// COMPONENT //////////
export default function MoveDetailsInfo(props) {
  const { move, mainImageUrl, showImages = true, editMode = false, notes, setNotes, width, driver } = props;
  const cls = useStyles();
  const history = useHistory();

  const {
    getDriverInitialsFromMove,
    getFormattedCombinedStatusFromMove,
    getFormattedVehicleFromMove,
    getCleansedPhoneNumber,
    getWorkflowData,
    getCancelReasonFromMove,
  } = useTools();

  const handleInputChange = setHandler => event => {
    if (setHandler) setHandler(event.target.value);
  };

  const goToMoveDetails = moveId => {
    history.push(`/moves/${moveId}`);
  };

  let relatedMoveIds = [];

  (move.childMoves || []).forEach(childMove => {
    if (childMove.move_type !== 'ride') {
      relatedMoveIds.push(childMove.id);
    }
  });

  if (move.parentMove && move.parentMove.move_type !== 'ride') {
    relatedMoveIds.push(move.parentMove.id);
  }

  // Pickup workflow data
  const pickupWorkflowData = getWorkflowData(
    `pickup`,
    move.pickup_workflow_data,
    `move_details`,
    true,
    move.workflowset
  );

  const pickupWorkflowEven = [];
  const pickupWorkflowOdd = [];

  pickupWorkflowData &&
    pickupWorkflowData.length &&
    pickupWorkflowData
      .filter(wd => !wd.key.includes(`Damages`))
      .forEach((wd, i) => {
        if (i % 2) pickupWorkflowEven.push(wd);
        else pickupWorkflowOdd.push(wd);
      });

  // Delivery workflow data
  const deliveryWorkflowData = getWorkflowData(
    `delivery`,
    move.delivery_workflow_data,
    `move_details`,
    true,
    move.workflowset
  );

  const deliveryWorkflowEven = [];
  const deliveryWorkflowOdd = [];

  deliveryWorkflowData &&
    deliveryWorkflowData.length &&
    deliveryWorkflowData
      .filter(wd => !wd.key.includes(`Damages`))
      .forEach((wd, i) => {
        if (i % 2) deliveryWorkflowEven.push(wd);
        else deliveryWorkflowOdd.push(wd);
      });

  return (
    <>
      <div className={cls.main}>
        {showImages ? (
          <div className={cls.info}>
            <Typography className={cls.infoTxt}>VEHICLE</Typography>
            <div className={cls.smallBreak} />
            <Tooltip title={getFormattedVehicleFromMove(move)}>
              {mainImageUrl ? (
                <img className={cls.infoImage} src={mainImageUrl} alt='vehicle' />
              ) : (
                <img className={cls.infoImage} src={placeholder} alt='placeholder vehicle' />
              )}
            </Tooltip>
          </div>
        ) : null}

        {width === `sm` || width === `xs` ? <div className={cls.bigBreak} /> : null}

        <div className={cls.paper}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Tooltip placement='top-start' title='Move ID'>
                <Typography className={cls.headTxt} style={{ textAlign: 'left' }}>
                  {move.id ? `Move #${move.id}` : `No Move ID`}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={4}>
              <Tooltip placement='top' title='Move Date'>
                <Typography className={cls.headTxt} style={{ textAlign: 'center' }}>
                  {dayjs(move.pickup_time).format(`MM/DD/YYYY`)}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={4}>
              <Tooltip placement='top-end' title='Move Status'>
                <Typography className={cls.headTxt} style={{ textAlign: 'right' }}>
                  {getFormattedCombinedStatusFromMove(move)}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>

          <div className={cls.break} />

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Vehicle:&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>{getFormattedVehicleFromMove(move)}</Typography>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Transmission:&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>{move.manual_flag ? `Manual` : `Automatic`}</Typography>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Odometer:&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>
                  {move.vehicle_odometer ? move.vehicle_odometer + ' miles' : '-'}
                </Typography>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Stock:&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>{move.vehicle_stock ? move.vehicle_stock : `-`}</Typography>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>VIN:&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>{move.vehicle_vin ? move.vehicle_vin : `-`}</Typography>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Reference&nbsp;#:&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>{move.reference_num ? move.reference_num : `-`}</Typography>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Related&nbsp;Moves:&nbsp;&nbsp;</Typography>
                {relatedMoveIds.length ? (
                  relatedMoveIds.map((id, i) => (
                    <div className={cls.relatedMoveBtn}>
                      <Button
                        key={`related-move-btn-${i}`}
                        variant='outlined'
                        size='small'
                        color='primary'
                        style={{ maxHeight: '20px', marginLeft: '5px' }}
                        onClick={() => {
                          goToMoveDetails(id);
                        }}
                      >
                        {id}
                      </Button>
                    </div>
                  ))
                ) : (
                  <Typography className={cls.valTxt}>-</Typography>
                )}
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Driver&nbsp;Name:&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>{move.driver_name ? move.driver_name : `HopDriver`}</Typography>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Dealer:&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>
                  {move.customer && move.customer.name ? move.customer.name : `Unknown Dealer`}
                </Typography>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Payer:&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>{move.payer && move.payer.name ? move.payer.name : `-`}</Typography>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Drive&nbsp;Distance:&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>
                  {move.lane && move.lane.distance_miles ? `${move.lane.distance_miles} miles` : `-`}
                </Typography>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Dealer&nbsp;Contact:&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>{move.dealer_contact ? move.dealer_contact : `-`}</Typography>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem} style={{ justifyContent: 'left' }}>
                <Typography className={cls.keyTxt}>Notes/Instructions:&nbsp;&nbsp;</Typography>
                {editMode ? (
                  <TextField
                    multiline
                    fullWidth
                    variant={'outlined'}
                    maxRows={5}
                    value={notes}
                    onChange={handleInputChange(setNotes)}
                    className={cls.nakedValTxt}
                    inputProps={{ className: cls.nakedValInput }}
                  />
                ) : (
                  <Typography className={cls.valTxt}>{move.move_details || `-`}</Typography>
                )}
              </div>
            </Grid>
          </Grid>
        </div>

        {width === `sm` || width === `xs` ? <div className={cls.bigBreak} /> : null}

        {showImages ? (
          <div className={cls.info}>
            <Typography className={cls.infoTxt}>DRIVER</Typography>
            <div className={cls.smallBreak} />
            <Tooltip
              title={
                driver && driver.display_name ? driver.display_name : move.driver_name ? move.driver_name : `HopDriver`
              }
            >
              {driver && driver.avatar_url ? (
                <img className={cls.infoImage} src={driver.avatar_url} alt='driver' />
              ) : (
                <div className={cls.infoImage}>
                  <Typography className={cls.infoNoDriverTxt}>{getDriverInitialsFromMove(move)}</Typography>
                </div>
              )}
            </Tooltip>
          </div>
        ) : null}
      </div>

      {move.consumer_pickup || move.consumer_type ? (
        <>
          <div className={cls.break} />

          <div className={cls.main}>
            <div className={cls.info}>
              <div className={cls.infoSpacer} />
            </div>

            <div className={cls.paper}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <div className={cls.listItem}>
                    <Typography className={cls.keyTxt}>Consumer&nbsp;Name:&nbsp;&nbsp;</Typography>
                    <Typography className={cls.valTxt}>{move.consumer_name || `-`}</Typography>
                  </div>
                </Grid>

                <Grid item xs={6}>
                  <div className={cls.listItem}>
                    <Typography className={cls.keyTxt}>Consumer&nbsp;Phone:&nbsp;&nbsp;</Typography>
                    <Typography className={cls.valTxt}>{getCleansedPhoneNumber(move.consumer_phone) || `-`}</Typography>
                  </div>
                </Grid>
              </Grid>
            </div>

            <div className={cls.info}>
              <div className={cls.infoSpacer} />
            </div>
          </div>
        </>
      ) : null}

      {move.cancel_status ? (
        <>
          <div className={cls.break} />

          <div className={cls.main}>
            <div className={cls.info}>
              <div className={cls.infoSpacer} />
            </div>

            <div className={cls.paper}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div className={cls.listItem}>
                    <Typography className={cls.keyTxt}>Cancel&nbsp;Reason:&nbsp;&nbsp;</Typography>
                    <Typography className={cls.valTxt}>{getCancelReasonFromMove(move)}</Typography>
                  </div>
                </Grid>
              </Grid>
            </div>

            <div className={cls.info}>
              <div className={cls.infoSpacer} />
            </div>
          </div>
        </>
      ) : null}

      {pickupWorkflowData && pickupWorkflowData.length ? (
        <>
          <div className={cls.break} />
          <div className={cls.break} />

          <div className={cls.main}>
            <div className={cls.info}>
              <div className={cls.infoSpacer} />
            </div>
            <Divide tip='View extra workflow data found on the pickup'>Pickup Workflow Data</Divide>
            <div className={cls.info}>
              <div className={cls.infoSpacer} />
            </div>
          </div>

          <div className={cls.main}>
            <div className={cls.info}>
              <div className={cls.infoSpacer} />
            </div>

            <div className={cls.paper}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  {pickupWorkflowOdd.map((wd, i) => (
                    <React.Fragment key={`move-details-pickup-workflow-odd-${i}`}>
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>{wd.key}:</Typography>
                        <Typography className={cls.valTxt}>{`${wd.val}`}</Typography>
                      </div>
                      {i < pickupWorkflowOdd.length - 1 ? <div className={cls.lineBreak} /> : null}
                    </React.Fragment>
                  ))}
                </Grid>

                <div className={cls.hiddenLineBreak} />

                <Grid item sm={6} xs={12}>
                  {pickupWorkflowEven.map((wd, i) => (
                    <React.Fragment key={`move-details-pickup-workflow-even-${i}`}>
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>{wd.key}:</Typography>
                        <Typography className={cls.valTxt}>{`${wd.val}`}</Typography>
                      </div>
                      {i < pickupWorkflowEven.length - 1 ? <div className={cls.lineBreak} /> : null}
                    </React.Fragment>
                  ))}
                </Grid>
              </Grid>
            </div>

            <div className={cls.info}>
              <div className={cls.infoSpacer} />
            </div>
          </div>
        </>
      ) : null}

      {deliveryWorkflowData && deliveryWorkflowData.length ? (
        <>
          <div className={cls.break} />
          <div className={cls.break} />

          <div className={cls.main}>
            <div className={cls.info}>
              <div className={cls.infoSpacer} />
            </div>
            <Divide tip='View extra workflow data found on the delivery'>Delivery Workflow Data</Divide>
            <div className={cls.info}>
              <div className={cls.infoSpacer} />
            </div>
          </div>

          <div className={cls.main}>
            <div className={cls.info}>
              <div className={cls.infoSpacer} />
            </div>

            <div className={cls.paper}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  {deliveryWorkflowOdd.map((wd, i) => (
                    <React.Fragment key={`move-details-delivery-workflow-odd-${i}`}>
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>{wd.key}:</Typography>
                        <Typography className={cls.valTxt}>{`${wd.val}`}</Typography>
                      </div>
                      {i < deliveryWorkflowOdd.length - 1 ? <div className={cls.lineBreak} /> : null}
                    </React.Fragment>
                  ))}
                </Grid>

                <div className={cls.hiddenLineBreak} />

                <Grid item sm={6} xs={12}>
                  {deliveryWorkflowEven.map((wd, i) => (
                    <React.Fragment key={`move-details-delivery-workflow-even-${i}`}>
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>{wd.key}:</Typography>
                        <Typography className={cls.valTxt}>{`${wd.val}`}</Typography>
                      </div>
                      {i < deliveryWorkflowEven.length - 1 ? <div className={cls.lineBreak} /> : null}
                    </React.Fragment>
                  ))}
                </Grid>
              </Grid>
            </div>

            <div className={cls.info}>
              <div className={cls.infoSpacer} />
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexWrap: 'nowrap',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  info: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  infoTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'center',
    lineHeight: 1.25,
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  infoImage: {
    position: 'relative',
    display: 'block',
    verticalAlign: 'top',
    objectFit: 'cover',
    width: '128px',
    height: '128px',
    borderRadius: '50%',
    background: theme.palette.fade[3],
    boxShadow: theme.shadow.soft,
  },
  infoSpacer: {
    display: 'block',
    position: 'relative',
    width: '128px',
  },
  infoNoVehicleTxt: {
    position: 'absolute',
    top: '42%',
    left: 0,
    right: 0,
    color: '#fff',
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 700,
  },
  infoNoDriverTxt: {
    position: 'absolute',
    top: '21%',
    left: 0,
    right: 0,
    color: '#fff',
    textAlign: 'center',
    fontSize: 48,
    fontWeight: 700,
  },
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  headTxt: {
    lineHeight: 1.25,
    fontSize: 21,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
    cursor: 'default',
  },
  list: {},
  listItem: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  keyTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  valTxt: {
    width: '100%',
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  relatedMoveBtn: {
    textAlign: 'right',
    width: '100%',
  },
  bigBreak: {
    width: '100%',
    height: theme.spacing(3),
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  smallBreak: {
    width: '100%',
    height: theme.spacing(1),
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: '6px',
    marginBottom: '8px',
    background: theme.palette.divider,
  },
}));
