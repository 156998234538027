import gql from 'graphql-tag';

const GET_INVOICES = gql`
  query dealer_getInvoices($customerId: bigint, $start: timestamptz!, $end: timestamptz!) {
    arinvoices(
      where: {
        customer_id: { _eq: $customerId }
        _or: [{ start_datetime: { _gte: $start, _lte: $end } }, { end_datetime: { _gte: $start, _lte: $end } }]
      }
      order_by: { start_datetime: desc }
    ) {
      id
      status
      start_datetime
      end_datetime
      customer {
        id
        name
      }
      armoves(where: { active: { _eq: 1 } }, order_by: { id: asc }) {
        id
        active
        due_amount
        paid_amount
        discount_amount
        discount_reason
        disputed
        dispute_reason
        type
      }
      arpayments {
        id
        status
        amount
      }
    }
  }
`;

const GET_INVOICE_DETAILS = gql`
  query dealer_getInvoiceDetails($invoiceId: bigint!) {
    arinvoices(where: { id: { _eq: $invoiceId } }) {
      id
      status
      accounting_num
      start_datetime
      end_datetime
      customer {
        id
        auto_pay
        billing_frequency
        config
        organization {
          config
        }
        name
        notify_billing
        payment_terms
        paymentmethods(where: { type: { _eq: "manual" } }) {
          id
        }
      }
      armoves(where: { active: { _eq: 1 } }, order_by: { id: asc }) {
        id
        accounting_item_id
        active
        arevent_id
        author
        billable_datetime
        discount_amount
        discount_reason
        dispute_reason
        disputed
        due_amount
        invoice_id
        move_id
        notes
        paid_amount
        status
        type
        move {
          id
          active
          class
          delivery_arrived
          delivery_started
          delivery_successful
          pickup_arrived
          pickup_started
          pickup_successful
          reference_num
          status
          tags
          vehicle_color
          vehicle_make
          vehicle_model
          vehicle_odometer
          vehicle_stock
          vehicle_vin
          vehicle_year
          cancel_status
          customer {
            id
            name
          }
          lane {
            id
            description
            distance_miles
            delivery {
              id
              name
              address
            }
            pickup {
              id
              name
              address
              region {
                id
                accounting_class_num
              }
            }
          }
          raterule {
            id
            rate
            type
          }
        }
        delayDetails: details_aggregate(where: { name: { _eq: "delay" } }) {
          aggregate {
            count
            sum {
              amount
            }
          }
        }
        fuelDetails: details_aggregate(where: { name: { _eq: "fuel" } }) {
          aggregate {
            count
            sum {
              amount
            }
          }
        }
        partialDetails: details_aggregate(where: { name: { _eq: "partial" } }) {
          aggregate {
            count
            sum {
              amount
            }
          }
        }
        rideDetails: details_aggregate(where: { name: { _eq: "ride" } }) {
          aggregate {
            count
            sum {
              amount
            }
          }
        }
        surgeDetails: details_aggregate(where: { name: { _eq: "surge" } }) {
          aggregate {
            count
            sum {
              amount
            }
          }
        }
        towDetails: details_aggregate(where: { name: { _eq: "tow" } }) {
          aggregate {
            count
            sum {
              amount
            }
          }
        }
        transportDetails: details_aggregate(where: { name: { _eq: "Transport" } }) {
          aggregate {
            count
            sum {
              amount
            }
          }
        }
        waivedDetails: details_aggregate(where: { name: { _eq: "waived" } }) {
          aggregate {
            count
            sum {
              amount
            }
          }
        }
        details {
          id
          armove_id
          name
          notes
          amount
          rate_source
          documents {
            id
            mime_type
            name
            url
          }
        }
        revisions(order_by: { revision: desc }) {
          id
          revision
          due_amount
          discount_amount
          discount_reason
          disputed
          dispute_reason
          details {
            id
            name
            notes
            amount
            documents {
              id
              mime_type
              name
              url
            }
          }
        }
      }
      arpayments(order_by: { createdat: asc }) {
        id
        status
        amount
        accounting_id
        gateway_transaction_id
        createdat
      }
    }
  }
`;

const GET_USERS_FROM_CONFIG = gql`
  query getDealerViewableAdmins($userIds: [bigint]!) {
    dealer_viewable_admin_info(where: { user_id: { _in: $userIds } }) {
      user_id
      display_name
      email
      phone
    }
  }
`;

export { GET_INVOICES, GET_INVOICE_DETAILS, GET_USERS_FROM_CONFIG };
