import React from 'react';
import { makeStyles, Grid, Typography, Checkbox, Tooltip, IconButton, Icon } from '@material-ui/core';

const log = false;

//////////////////////////////////////// COMPONENT ////////////////////////////////////////
export default function DashboardFilter(props) {
  const cls = useStyles();

  const { vehicles, handleClearFilters, tags, setTags } = props;

  let filter = [];

  const getFilters = () => {
    if (vehicles && vehicles.length > 0) {
      let statuses = [`Viewed`, `Not Viewed`];
      let makes = [];
      let models = [];
      let years = [];
      let colors = [];

      makes = vehicles
        .map(vehicle => vehicle.move.vehicle_make)
        .filter((make, i) => make !== null && make !== ``)
        .sort();
      makes = makes.filter((make, i) => makes.indexOf(make) === i);

      models = vehicles
        .map(vehicle => vehicle.move.vehicle_model)
        .filter((model, i) => model !== null && model !== ``)
        .sort();
      models = models.filter((model, i) => models.indexOf(model) === i);

      years = vehicles
        .map(vehicle => vehicle.move.vehicle_year)
        .filter((year, i) => year !== null && year !== ``)
        .sort();
      years = years.filter((year, i) => years.indexOf(year) === i);

      colors = vehicles
        .map(move => move.vehicle_color)
        .filter((color, i) => color !== null && color !== ``)
        .sort();
      colors = colors.filter((color, i) => colors.indexOf(color) === i);

      log && console.log(`Statuses`, statuses);
      log && console.log(`Makes`, makes);
      log && console.log(`Models`, models);
      log && console.log(`Years`, years);
      log && console.log(`Colors`, colors);

      return { statuses, makes, models, years, colors };
    } else return null;
  };

  filter = getFilters();

  const handleTags = (name, str) => event => {
    if (str && str !== null) {
      let newTags = [...tags[name]];
      if (newTags.length > 0 && newTags.includes(str)) {
        let strIndex = newTags.indexOf(str);
        if (strIndex !== -1) {
          newTags.splice(strIndex, 1);
          setTags[name]([...newTags]);
        }
      } else {
        newTags.push(str);
        setTags[name]([...newTags]);
      }
    }
  };

  return (
    <>
      <div className={cls.paper}>
        <div className={cls.clear}>
          <Tooltip title='Clear Filters'>
            <IconButton onClick={handleClearFilters}>
              <Icon>cached</Icon>
            </IconButton>
          </Tooltip>
        </div>

        <Typography className={cls.head}>Filtering Options</Typography>

        {filter && filter.statuses.length > 0 ? (
          <>
            <Typography className={cls.sub}>Move Status</Typography>
            {filter.statuses.map((item, i) => (
              <Grid key={item + i} container alignItems='center'>
                <Grid item>
                  <Checkbox
                    className={cls.optionCheck}
                    color='primary'
                    checked={tags.status.includes(item)}
                    onChange={handleTags(`status`, item)}
                  />
                </Grid>
                <Grid item xs>
                  <Typography className={cls.optionTxt}>{item}</Typography>
                </Grid>
              </Grid>
            ))}
          </>
        ) : null}

        {filter && filter.makes.length > 0 ? (
          <>
            <Typography className={cls.sub}>Vehicle Make</Typography>
            {filter.makes.map((item, i) => (
              <Grid key={item + i} container alignItems='center'>
                <Grid item>
                  <Checkbox
                    className={cls.optionCheck}
                    color='primary'
                    checked={tags.make.includes(item)}
                    onChange={handleTags(`make`, item)}
                  />
                </Grid>
                <Grid item xs>
                  <Typography className={cls.optionTxt}>{item}</Typography>
                </Grid>
              </Grid>
            ))}
          </>
        ) : null}

        {filter && filter.models.length > 0 ? (
          <>
            <Typography className={cls.sub}>Vehicle Model</Typography>
            {filter.models.map((item, i) => (
              <Grid key={item + i} container alignItems='center'>
                <Grid item>
                  <Checkbox
                    className={cls.optionCheck}
                    color='primary'
                    checked={tags.make.includes(item)}
                    onChange={handleTags(`model`, item)}
                  />
                </Grid>
                <Grid item xs>
                  <Typography className={cls.optionTxt}>{item}</Typography>
                </Grid>
              </Grid>
            ))}
          </>
        ) : null}

        {/* {filter && filter.years.length > 0 ? (
          <>
            <Typography className={cls.sub}>Vehicle Year</Typography>
            {filter.years.map((item, i) => (
              <Grid key={item + i} container alignItems='center'>
                <Grid item>
                  <Checkbox
                    className={cls.optionCheck}
                    color='primary'
                    checked={tags.year.includes(item)}
                    onChange={handleTags(`year`, item)}
                  />
                </Grid>
                <Grid item xs>
                  <Typography className={cls.optionTxt}>{item}</Typography>
                </Grid>
              </Grid>
            ))}
          </>
        ) : null}

        {filter && filter.colors.length > 0 ? (
          <>
            <Typography className={cls.sub}>Vehicle Color</Typography>
            {filter.colors.map((item, i) => (
              <Grid key={item + i} container alignItems='center'>
                <Grid item>
                  <Checkbox
                    className={cls.optionCheck}
                    color='primary'
                    checked={tags.color.includes(item)}
                    onChange={handleTags(`color`, item)}
                  />
                </Grid>
                <Grid item xs>
                  <Typography className={cls.optionTxt}>{item}</Typography>
                </Grid>
              </Grid>
            ))}
          </>
        ) : null} */}

        {!filter ? (
          <>
            <Typography className={cls.notFound}>No filters found.</Typography>
          </>
        ) : null}
      </div>
    </>
  );
}

//////////////////////////////////////// STYLES ////////////////////////////////////////
const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: 240,
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  clear: {
    position: 'absolute',
    top: '5px',
    right: '5px',
  },
  head: {
    marginBottom: theme.spacing(1),
    lineHeight: 1.25,
    fontSize: 21,
    fontWeight: 600,
  },
  sub: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    lineHeight: 1.25,
    fontSize: 18,
    fontWeight: 600,
  },
  optionCheck: {
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  optionTxt: {
    marginTop: 2,
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
  },
  notFound: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    fontSize: 18,
    fontWeight: 500,
  },
}));
