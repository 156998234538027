import React, { useState } from 'react';
import { useData } from '../../DataProvider';
import { toast } from 'react-toastify';
import { makeStyles, Container, Typography, Switch } from '@material-ui/core';

import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import * as Sentry from '@sentry/react';

import Loading from '../utils/Loading';
import MethodTable from './billing/MethodTable';
import PrepaidPackage from './billing/PrepaidPackage';

const log = false;

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  fakePaper: {
    width: 'fit-content',
    padding: theme.spacing(3),
    borderRadius: theme.shape.paperRadius,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  pref: {
    display: 'inline-block',
    verticalAlign: 'top',
  },
  head: {
    marginBottom: theme.spacing(2),
    lineHeight: 1,
    fontSize: '24px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '21px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
  },
  txtTitle: {
    fontSize: '12px',
    fontWeight: 500,
    textAlign: 'center',
  },
  txtItem: {
    fontSize: '18px',
    fontWeight: 500,
    textAlign: 'center',
  },
}));

const capFirst = str => {
  if (str) return str.charAt(0).toUpperCase() + str.slice(1);
};

////////// COMPONENT //////////
export default function Billing() {
  const ctx = useData();
  const cls = useStyles();

  const [billing, setBilling] = useState(`Unknown`);
  const [autoPay, setAutoPay] = useState(false);

  const customerId = parseInt(
    ctx.customerOverride ||
      (ctx.customerId
        ? ctx.customerId
        : 0)
  );

  const setPrefs = async ap => {
    ctx.apolloClient
      .mutate({
        mutation: UPDATE_PREFERENCES,
        variables: { customerId: customerId, autoPay: ap },
        onError: err => {
          Sentry.captureException(err);
          toast.error('Query failed to update customer billing prefs');
        },
      })
      .then(res => {
        if (res.data.update_customers) {
          let prefs = res.data.update_customers.returning[0];
          log && console.log(`>> Set Customer #${prefs.id} to Auto-Pay '${prefs.auto_pay}'.`);
        }
      })
      .catch(err => {
        console.log(`Error updating customer billing prefs:`, err);
      });
  };

  const handleCheck = async event => {
    setAutoPay(event.target.checked);
    await setPrefs(event.target.checked);
  };

  return (
    <>
      <div className={cls.root}>
        {ctx && ctx.firebaseUser && (
          <Container className={cls.container} maxWidth='lg'>
            <Typography className={cls.head}>Billing Preferences</Typography>
            <div style={{ width: '100%', height: '8px' }} />
            <Query
              query={GET_PREFERENCES_AND_CHECK_PREPAID(`query`)}
              variables={{ customerId: customerId }}
              // onError={(error) => {console.error(error); Sentry.captureException(error)}}
            >
              {({ loading, data }) => {
                const hasPrepaidPackage = data.productfees && data.productfees.length > 0 ? true : false
                const productFee = hasPrepaidPackage ? data.productfees[0] : {}
                const productFeeCost = productFee.cost
                if (loading) return <Loading fixed />;
                if (data && data.customers && data.customers.length > 0) {
                  let prefs = data.customers[0];
                  log &&
                    console.log(
                      `Got Customer #${prefs.id} with Billing Frequency '${prefs.billing_frequency}' and Auto-Pay '${prefs.auto_pay}'.`
                    );
                  setBilling(capFirst(prefs.billing_frequency));
                  setAutoPay(prefs.auto_pay);
                  return (
                    <div className={cls.fakePaper}>
                      <div className={cls.pref}>
                        <Typography className={cls.txtTitle} color='textSecondary' variant='h6'>
                          Customer Name
                        </Typography>
                        <Typography className={cls.txtItem} variant='h6'>
                          {prefs.name}
                        </Typography>
                      </div>
                      <div className={cls.pref} style={{ width: '64px' }} />
                      <div className={cls.pref}>
                        <Typography className={cls.txtTitle} color='textSecondary' variant='h6'>
                          Billing&nbsp;Frequency
                        </Typography>
                        <Typography className={cls.txtItem} variant='h6'>
                          {capFirst(billing)}
                        </Typography>
                      </div>
                      <div className={cls.pref} style={{ width: '64px' }} />
                      <div className={cls.pref}>
                        <Typography className={cls.txtTitle} color='textSecondary' variant='h6'>
                          Auto&nbsp;Pay
                        </Typography>
                        {/* Prepaid customers must be prepaid, and daily customers cannot be prepaid, so switch Fis disabled for both */}
                        <Switch
                          disabled={billing === `Daily` || hasPrepaidPackage ? true : false}
                          checked={autoPay}
                          onChange={event => handleCheck(event)}
                          value={autoPay}
                          color='primary'
                          inputProps={{ 'aria-label': 'checkbox' }}
                        />
                      </div>
                      {/* Conditionally render badge showing current prepaid package */}
                      {hasPrepaidPackage ? (
                        <PrepaidPackage productFeeCost={productFeeCost} />
                      ) : null}
                    </div>
                  );
                } else {
                  return (
                    <Typography variant='h6' className={cls.txtItem}>
                      No Billing Prefs Found
                    </Typography>
                  );
                }
              }}
            </Query>

            <div style={{ width: '100%', height: '32px' }} />

            <Typography className={cls.head}>Payment Methods</Typography>
            <Query
              query={GET_PAYMENT_METHODS(`query`)}
              variables={{ customerId: customerId }}
              onError={error => {
                console.error(error);
                Sentry.captureException(error);
              }}
            >
              {({ loading, data, refetch }) => {
                if (loading) return <Loading fixed />;
                if (data && data.arpaymentmethods && data.arpaymentmethods.length > 0) {
                  let methods = data.arpaymentmethods;
                  log && console.log(`Got ${methods.length} payment methods.`);
                  return <MethodTable methods={methods} refetch={refetch} />;
                } else {
                  return <MethodTable methods={[]} refetch={refetch} />;
                }
              }}
            </Query>
            <div style={{ width: '100%', height: '16px' }} />
          </Container>
        )}
      </div>
    </>
  );
}

////////// GRAPHQL //////////
const GET_PAYMENT_METHODS = (type = `query`) => gql`
${type} get_payment_methods($customerId: bigint!) {
  arpaymentmethods(where: {customer_id: {_eq: $customerId}, active: {_eq: true}, type: {_neq: "manual"}}, order_by: {primary: desc}) {
    id
    customer_id
    gateway_token
    active
    type
    routing_number
    account_type
    account_number
    expiration
    card_type
    primary
    name
    source
  }
}
`;

const GET_PREFERENCES_AND_CHECK_PREPAID = (type = `query`) => gql`
${type} get_preferences($customerId: bigint!) {
  customers(where: {id: {_eq: $customerId}, active: {_eq: 1}}) {
    id
    name
    billing_frequency
    auto_pay
  }
  productfees(
    where: {
      raterulegroup: { customer_id: { _eq: $customerId }, begin_date: { _lte: "now()" }, end_date: { _gte: "now()" } }
      product: { prepaid: { _eq: true } }
    }
  ) {
    id
    cost
  }
}
`;

const UPDATE_PREFERENCES = gql`
  mutation update_preferences($customerId: bigint!, $autoPay: Boolean!) {
    update_customers(where: { id: { _eq: $customerId }, active: { _eq: "1" } }, _set: { auto_pay: $autoPay }) {
      affected_rows
      returning {
        id
        name
        billing_frequency
        auto_pay
      }
    }
  }
`;
