//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const log = false;

//////////////////////// COMPONENT ////////////////////////

export default function PrepaidPackage({ productFeeCost }) {
  const cls = useStyles();
  log && console.log(`Prepaid amount:`, productFeeCost);

  if (productFeeCost) {
    return (
      <>
        <div style={{ width: '100%', height: '16px' }} />
        <div className={cls.package}>
          <Typography display='inline' className={cls.packageTitle}>
            Prepaid Package Amount:&nbsp;
          </Typography>
          <Typography display='inline' className={cls.packageAmount}>
            ${productFeeCost}
          </Typography>
        </div>
      </>
    );
  } else {
    return null;
  }
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  package: {
    position: 'relative',
    width: 'fit-content',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    boxShadow: theme.shadow.medium,
  },
  packageTitle: {
    fontSize: 18,
    fontWeight: 400,
  },
  packageAmount: {
    fontSize: 18,
    fontWeight: 600,
  },
}));