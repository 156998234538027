import React from 'react';
import { Modal } from '../../reusable/Modal';

import ReinstateModalContent from './ReinstateModalContent';

//////////////////////// COMPONENT ////////////////////////

export default function ReinstateModal({ open, input, onClose }) {
  const handleClose = (output = null) => {
    if (onClose) onClose(output);
  };

  return <Modal open={open}>{open ? <ReinstateModalContent input={input} onClose={handleClose} /> : null}</Modal>;
}