import React, { useEffect, useState } from 'react'
import { Tooltip, Icon, Switch, IconButton, FormControlLabel } from '@material-ui/core'

const log = false

export default function AutoRefresh({interval, refetch, persistAs=null}) {
    const localStorageName = `${persistAs}-auto-refresh`
    //Use JSON.parse() to load as boolean from localStorage that was stored as a string
    const [autoRefresh, setAutoRefresh] = useState(JSON.parse(localStorage.getItem(localStorageName) || true));

    useEffect(() => {
        log && console.log(`Auto refresh ${autoRefresh == true ? 'enabled' : 'disabled'}`)
        if (persistAs) {
            log && console.log(`Setting local storage to ${autoRefresh}`)
            localStorage.setItem(localStorageName, autoRefresh)
        }
    }, [autoRefresh])

    useEffect(() => {
        if (autoRefresh) {
        log && console.log(`AutoRefresh initialized. Setting up the timer to ${interval}ms...`)
        const timer = setInterval(() => {
            if (refetch) {
            log && console.log('Auto refreshing...')
            refetch()
            }
        }, interval)
        return function autoRefreshCleanup() {
            log && console.log('Clearing the refresh timer using timer id: ', timer)
            clearInterval(timer)
        }
        }
    })

    return (<>
        <Tooltip title="Manually refresh">
            <IconButton onClick={refetch}>
            <Icon>refresh</Icon>
            </IconButton>
        </Tooltip>
        <Tooltip title="Auto refresh every 60 seconds">
            <FormControlLabel
                control={
                    <Switch
                        checked={autoRefresh}
                        onChange={() => setAutoRefresh(!autoRefresh)}
                        name="AutoRefresh"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                }
                label="Auto refresh"
            />
            
        </Tooltip>
    </>)
}