//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';

import * as Sentry from '@sentry/react';

import DefaultErrorFallback from '../../reusable/Fallbacks/DefaultErrorFallback';
import { InvoiceContent, InvoicesList } from './index';
import { useInvoices } from './useInvoices';

//////////////////////// COMPONENT ////////////////////////

export default function Invoices(props) {
  const cls = useStyles();
  const { getDefaultInvoiceId } = useInvoices();

  const [invoiceId, setInvoiceId] = React.useState(getDefaultInvoiceId());

  return (
    <Sentry.ErrorBoundary fallback={<DefaultErrorFallback message='ERROR DISPLAYING INVOICES' />}>
      <div className={cls.root}>
        <Grid container justifyContent='space-between' alignItems='flex-start' wrap='nowrap'>
          <Grid className={cls.gridItem} item xs>
            <div className={cls.scrollWrap}>
              <InvoiceContent invoiceId={invoiceId} />
            </div>
          </Grid>
          <Grid className={cls.gridItem} item>
            <div className={cls.scrollWrap}>
              <InvoicesList invoiceId={invoiceId} setInvoiceId={setInvoiceId} />
            </div>
          </Grid>
        </Grid>
      </div>
    </Sentry.ErrorBoundary>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    display: 'block',
    width: '100vw',
    height: '100vh',
    top: 0,
    left: 0,
  },
  gridItem: {
    position: 'relative',
  },
  scrollWrap: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    paddingTop: 64,
  },
}));
