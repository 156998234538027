//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, TableContainer, Table, TablePagination } from '@material-ui/core';

import { UploadedTableHead, UploadedTableBody } from './index';

const defaultOrder = `asc`;
const defaultOrderBy = `order`;

//////////////////////// COMPONENT ////////////////////////

export default function UploadedTable({ headers, entries, errors }) {
  const cls = useStyles();

  const [order, setOrder] = React.useState(defaultOrder);
  const [orderBy, setOrderBy] = React.useState(defaultOrderBy);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const tableHeaders = headers.map((header, i) => {
    return { id: header, align: `left`, label: header };
  });

  return (
    <div className={cls.paper}>
      <TableContainer>
        <Table size='small' aria-label='uploaded-table'>
          <UploadedTableHead
            headers={tableHeaders}
            order={order}
            orderBy={orderBy}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
          />
          <UploadedTableBody
            rows={entries}
            errors={errors}
            order={order}
            orderBy={orderBy}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        </Table>
      </TableContainer>

      <div className={cls.pagination}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component='div'
          count={entries.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    border: theme.border[0],
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
  },
  pagination: {
    paddingRight: theme.spacing(2),
  },
}));
