//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, TableContainer, Table, TablePagination } from '@material-ui/core';

import AllowedCustomersTableHead from './AllowedCustomersTableHead'
import AllowedCustomersTableBody from './AllowedCustomersTableBody'

const defaultOrder = `desc`;
const defaultOrderBy = `end_date`;

//////////////////////// COMPONENT ////////////////////////

function AllowedCustomersTable(props) {
  const { allowedCustomers = [] } = props;

  const cls = useStyles();

  const [order, setOrder] = React.useState(defaultOrder);
  const [orderBy, setOrderBy] = React.useState(defaultOrderBy);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const headers = [
    { id: 'ID', align: 'left', label: 'ID' },
    { id: 'CUSTOMER', align: 'left', label: 'Customer' },
  ];

  const rows = allowedCustomers.map((allowedCustomer) => {
    let customerId = null
    let customerName = null
    if (allowedCustomer.customer_id) {
      customerId = allowedCustomer.customer_id
    } else if (allowedCustomer.id) {
      customerId = allowedCustomer.id
    }

    if (allowedCustomer.customer && allowedCustomer.customer.name) {
      customerName = allowedCustomer.customer.name
    } else if (allowedCustomer.name) {
      customerName = allowedCustomer.name
    }

    return {
      ID: customerId, 
      CUSTOMER: customerName,
    };
  });

  return (
    <div className={cls.paper}>
      <TableContainer>
        <Table size='small' aria-label='rate-rule-group-index-table'>
          <AllowedCustomersTableHead
            headers={headers}
            order={order}
            orderBy={orderBy}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
          />
          <AllowedCustomersTableBody rows={rows} order={order} orderBy={orderBy} page={page} rowsPerPage={rowsPerPage} />
        </Table>
      </TableContainer>

      <div className={cls.pagination}>
        <TablePagination
          classes={{
            toolbar: cls.toolbar,
          }}
          rowsPerPageOptions={[10, 25, 50, 100]}
          component='div'
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  paper: {
    background: theme.palette.background.paper,
  },
  pagination: {
    // paddingRight: theme.spacing(2),
    borderBottom: theme.border[0],
  },
  toolbar: {
    paddingRight: 0,
    paddingLeft: 0
  }
}));

//////////////////////// EXPORT ////////////////////////

export default AllowedCustomersTable;