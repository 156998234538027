import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DateTime } from 'luxon';
import sdk from '@hopdrive/sdk';

export default function MoveItemTooltip(props) {
  const cls = useStyles();

  const { move } = props;

  //Function for formating phone number in tooltip
  //Copied from https://stackoverflow.com/questions/8358084/regular-expression-to-reformat-a-us-phone-number-in-javascript
  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }

  //Copied basic setup from admin move planner's MoveDraggable tooltip
  return (
    <Grid className={cls.tooltipText} container spacing={2}>
      {move && (
        <>
          <Grid item xs={6}>
            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <Box>
                <strong>Move</strong>
              </Box>
              <Box>{move.id}</Box>
              <hr width='100%' style={{ opacity: 0.5 }} />
            </Grid>

            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <Box>Status</Box>
              <Box>{move.status ? move.status : 'unplanned'}</Box>
            </Grid>
            {/* Not sure if this should be shown to customers since if canceled the status will be 'canceled' */}
            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <Box>Cancel Status</Box>
              <Box>{move.cancel_status || 'N/A'}</Box>
            </Grid>

            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <hr width='100%' style={{ opacity: 0.5 }} />
            </Grid>
            <Grid container wrap='nowrap' direction='row' justifyContent='space-between' alignItems='center'>
              <Box>Origin</Box>
              <Box wrap='nowrap'>{sdk.utilities.getPropValue(move, 'lane.pickup.name')}</Box>
            </Grid>
            <Grid container wrap='nowrap' direction='row' justifyContent='space-between' alignItems='center'>
              <Box>Dest.</Box>
              <Box wrap='nowrap'>{sdk.utilities.getPropValue(move, 'lane.delivery.name')}</Box>
            </Grid>

            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <hr width='100%' style={{ opacity: 0.5 }} />
            </Grid>
            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <Box>VIN</Box>
              <Box>{move.vehicle_vin || 'N/A'}</Box>
            </Grid>
            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <Box>Stock</Box>
              <Box>{move.vehicle_stock || 'N/A'}</Box>
            </Grid>
            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <Box>Year</Box>
              <Box>{move.vehicle_year || 'N/A'}</Box>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <strong>Times</strong>
              <hr width='100%' style={{ opacity: 0.5 }} />
            </Grid>
            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <Box>Planned Pickup</Box>
              <Box>
                {move.pickup_time ? DateTime.fromISO(move.pickup_time).toLocaleString(DateTime.TIME_SIMPLE) : 'N/A'}
              </Box>
            </Grid>
            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <Box>Planned Delivery</Box>
              <Box>
                {move.delivery_time ? DateTime.fromISO(move.delivery_time).toLocaleString(DateTime.TIME_SIMPLE) : 'N/A'}
              </Box>
            </Grid>

            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <hr width='100%' style={{ opacity: 0.5 }} />
            </Grid>
            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <Box>Pickup Started</Box>
              <Box>
                {move.pickup_started
                  ? DateTime.fromISO(move.pickup_started).toLocaleString(DateTime.TIME_SIMPLE)
                  : 'N/A'}
              </Box>
            </Grid>
            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <Box>Pickup Arrived</Box>
              <Box>
                {move.pickup_arrived
                  ? DateTime.fromISO(move.pickup_arrived).toLocaleString(DateTime.TIME_SIMPLE)
                  : 'N/A'}
              </Box>
            </Grid>
            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <Box>Pickup Successful</Box>
              <Box>
                {move.pickup_successful
                  ? DateTime.fromISO(move.pickup_successful).toLocaleString(DateTime.TIME_SIMPLE)
                  : 'N/A'}
              </Box>
            </Grid>

            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <hr width='100%' style={{ opacity: 0.5 }} />
            </Grid>
            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <Box>Delivery Started</Box>
              <Box>
                {move.delivery_started
                  ? DateTime.fromISO(move.delivery_started).toLocaleString(DateTime.TIME_SIMPLE)
                  : 'N/A'}
              </Box>
            </Grid>
            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <Box>Delivery Arrived</Box>
              <Box>
                {move.delivery_arrived
                  ? DateTime.fromISO(move.delivery_arrived).toLocaleString(DateTime.TIME_SIMPLE)
                  : 'N/A'}
              </Box>
            </Grid>
            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <Box>Delivery Successful</Box>
              <Box>
                {move.delivery_successful
                  ? DateTime.fromISO(move.delivery_successful).toLocaleString(DateTime.TIME_SIMPLE)
                  : 'N/A'}
              </Box>
            </Grid>
          </Grid>
          {move.consumer_type ? (
            <Grid item xs={12}>
              <Grid container direction='row' justifyContent='space-between' alignItems='center'>
                <strong>Customer Info</strong>
                <hr width='100%' style={{ opacity: 0.5 }} />
              </Grid>
              <Grid container direction='row' justifyContent='space-between' alignItems='center'>
                <Box>Customer Name</Box>
                <Box>{move.consumer_name}</Box>
              </Grid>
              <Grid container direction='row' justifyContent='space-between' alignItems='center'>
                <Box>Customer Phone</Box>
                <Box>{formatPhoneNumber(move.consumer_phone)}</Box>
              </Grid>
            </Grid>
          ) : null}
        </>
      )}
    </Grid>
  );
}

const useStyles = makeStyles(theme => ({
    tooltipText: {
        // maxWidth: "none",
        // width: "400px"
    }
}));
