import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import DashboardTile from './DashboardTile';
import Divide from '../../reusable/Divide';

////////// COMPONENT //////////
export default function DashboardGrid(props) {
  const cls = useStyles();

  const { moves } = props;

  const groupBy = (objectArray, ...properties) => {
    return [
      ...Object.values(
        objectArray.reduce((accumulator, object) => {
          const key = JSON.stringify(properties.map(x => object[x] || null));

          if (!accumulator[key]) {
            accumulator[key] = [];
          }
          accumulator[key].push(object);
          return accumulator;
        }, {})
      ),
    ];
  };

  return (
    <>
      {groupBy(moves, 'consumer_name').map(consumerMoveGroup => (
        <React.Fragment key={consumerMoveGroup[0].consumer_name}>
          {consumerMoveGroup[0].consumer_name !== null && (
            <>
              <Divide spacer tip={`Track concierge moves for ${consumerMoveGroup[0].consumer_name}.`}>
                {consumerMoveGroup[0].consumer_name}
              </Divide>
              <Grid container justifyContent='center' alignItems='flex-start' spacing={2}>
                {consumerMoveGroup.map(move => (
                  <Grid key={move.id} item xs className={cls.tile}>
                    <DashboardTile move={move} />
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </React.Fragment>
      ))}
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  tile: {
    position: 'relative',
    minWidth: '480px',
    maxWidth: '540px',
    [theme.breakpoints.down('md')]: {
      minWidth: '360px',
      maxWidth: '420px',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
      maxWidth: '100%',
    },
  },
}));
