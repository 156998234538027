//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Container } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';
import useMoveImport from './useMoveImport';

import CsvReader from './CsvReader';
import { UploadedContent } from './index';

//////////////////////// COMPONENT ////////////////////////

export default function MoveImport(props) {
  const cls = useStyles();

  const [storedCsvData, setStoredCsvData] = React.useState(null);
  const [headers, setHeaders] = React.useState(null);
  const [entries, setEntries] = React.useState(null);
  const [movesToUpsert, setMovesToUpsert] = React.useState(null);
  const [dupeStr, setDupeStr] = React.useState(``);

  const { buildMovesFromCsvData } = useMoveImport();

  // Force a reupload of the csv to rebuild the entries with the new dupe string
  React.useEffect(() => {
    if (storedCsvData) handleCsvData(storedCsvData);
    else handleRemoveData();
  }, [dupeStr]);

  // Convert and set movesToUpsert
  const handleCsvData = async newCsvData => {
    setStoredCsvData(JSON.parse(JSON.stringify(newCsvData)));
    const buildObj = await buildMovesFromCsvData(newCsvData, dupeStr);
    const { headers, entries, moves } = buildObj;
    setHeaders(headers);
    setEntries(entries);
    setMovesToUpsert(moves);
  };

  // Clear out movesToUpsert
  const handleRemoveData = () => {
    setStoredCsvData(null);
    setHeaders(null);
    setEntries(null);
    setMovesToUpsert(null);
  };

  return (
    <div className={cls.root}>
      <Container maxWidth='lg'>
        <CsvReader
          onUpload={handleCsvData}
          onRemove={handleRemoveData}
          dupeStr={dupeStr}
          onDupeStrChange={setDupeStr}
        />

        <Spacer />

        {entries && movesToUpsert ? (
          <UploadedContent headers={headers} entries={entries} moves={movesToUpsert} />
        ) : null}
      </Container>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
}));
