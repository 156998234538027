import React from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';
import axios from 'axios';
import { useData } from '../../../DataProvider';
import { toast } from 'react-toastify';
import sdk from '@hopdrive/sdk';

import gql from 'graphql-tag';
import * as Sentry from '@sentry/react';

import {
  makeStyles,
  Grid,
  IconButton,
  Icon,
  Typography,
  Tooltip,
  Button,
  Checkbox,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import AppraisalPrint from './AppraisalPrint';

import bb_logo_primary from '../../../static/bb_primary.svg';
// import bb_logo_secondary from '../../../static/bb_secondary.svg';

const log = false;

// Return a money value string with commas in the proper places
const moneyWithCommas = val => {
  if (val) {
    const valStr = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    if (valStr.includes(`-`)) return `-$` + valStr.replace(`-`, ``);
    else return `$` + valStr;
  } else return `$0`;
};

// Get BlackBook's condition based on our condition
const getBBCondition = (cond, noMint = false) => {
  if (cond === `poor`) return `rough`;
  else if (cond === `fair`) return `avg`;
  else if (cond === `good` || (cond === `mint` && noMint)) return `clean`;
  else return `xclean`;
};

// Get BlackBook's valueType based on our valueType
const getBBValueType = valType => {
  if (valType === `tradein`) return `tradein`;
  else if (valType === `wholesale`) return `whole`;
  else return `retail`;
};

// Make the value type human readable
const getReadableValueType = type => {
  if (type === `retail`) return `Retail`;
  if (type === `wholesale`) return `Wholesale`;
  if (type === `tradein`) return `Trade-\u2060In`;
};

//////////////////////////////////////// COMPONENT ////////////////////////////////////////
function AppraisalDetailsSidebar(props) {
  const ctx = useData();
  const cls = useStyles();

  const { appraisal, vehicle, unsavedChanges, setUnsavedChanges, vehicleImages } = props;

  const editable = appraisal.status === `new` || appraisal.status === `renewed`;

  const [branding, setBranding] = React.useState(null);

  const [condition, setCondition] = React.useState(appraisal.condition);
  const [overrides, setOverrides] = React.useState(appraisal.trim_overrides || []);

  const getBaseValue = () => {
    let val = 0;

    if (valueType === `retail`) val = vehicle.base_retail_xclean;
    if (valueType === `wholesale`) val = vehicle.base_whole_xclean;
    if (valueType === `tradein`) val = vehicle.base_tradein_clean;

    return val;
  };

  const getBaseTrim = () => {
    let val = 0;
    let defaultTrimArr = [];

    if (vehicle.add_deduct_list && vehicle.add_deduct_list.length > 0) {
      defaultTrimArr = vehicle.add_deduct_list.filter(trim => trim.auto !== `N`);
      defaultTrimArr = defaultTrimArr.map(trim => trim[getBBCondition(condition)]);
      if (defaultTrimArr.length > 0) val = defaultTrimArr.reduce((a, b) => a + b);
    }

    return val;
  };

  const getConditionAdjustment = () => {
    let valMax = 0;
    let val = 0;

    if (valueType === `retail`) {
      valMax = vehicle.base_retail_xclean;
      val = vehicle[`base_retail_${getBBCondition(condition)}`] - valMax;
    }
    if (valueType === `wholesale`) {
      valMax = vehicle.base_whole_xclean;
      val = vehicle[`base_whole_${getBBCondition(condition)}`] - valMax;
    }
    if (valueType === `tradein`) {
      valMax = vehicle.base_tradein_clean;
      val = vehicle[`base_tradein_${getBBCondition(condition)}`] - valMax;
    }

    return val;
  };

  const getMileageAdjustment = cond => {
    let val = 0;
    let mileageObj = null;

    if (vehicle.mileage_list && vehicle.mileage_list.length > 0) {
      vehicle.mileage_list.forEach(listItem => {
        let mileage = 1;

        if (parseInt(appraisal.vehicle_odometer)) mileage = parseInt(appraisal.vehicle_odometer);
        if (parseInt(mileageOverride)) mileage = parseInt(mileageOverride);

        if (mileage >= listItem.range_begin && mileage <= listItem.range_end) mileageObj = listItem;
      });
    }

    if (mileageObj) {
      if (valueType === `retail` || valueType === `wholesale`) val = mileageObj[getBBCondition(cond)];
      if (valueType === `tradein`) val = mileageObj[getBBCondition(cond)];
    }

    return val;
  };

  const [mileageOverride, setMileageOverride] = React.useState(appraisal.mileage_override || ``);

  const getRetailValue = cond => {
    let val = vehicle[`history_adjusted_${getBBValueType(`retail`)}_${getBBCondition(cond)}`];
    return val + getMileageAdjustment(cond);
  };
  const getWholesaleValue = cond => {
    let val = vehicle[`history_adjusted_${getBBValueType(`wholesale`)}_${getBBCondition(cond)}`];
    return val + getMileageAdjustment(cond);
  };
  const getTradeinValue = cond => {
    let val = vehicle[`history_adjusted_${getBBValueType(`tradein`)}_${getBBCondition(cond)}`];
    return val + getMileageAdjustment(cond);
  };

  const getHistoryAdjustment = () => {
    let val = 0;

    if (valueType === `retail`) val = vehicle[`history_retail_${getBBCondition(condition)}`];
    if (valueType === `wholesale`) val = vehicle[`history_whole_${getBBCondition(condition)}`];
    if (valueType === `tradein`) val = vehicle[`history_tradein_${getBBCondition(condition)}`];

    return val;
  };
  const getHistoryAdjustedValue = () => {
    let val = 0;

    if (valueType === `retail`) val = getRetailValue(condition);
    if (valueType === `wholesale`) val = getWholesaleValue(condition);
    if (valueType === `tradein`) val = getTradeinValue(condition);

    return val;
  };

  const [retailValue, setRetailValue] = React.useState(getRetailValue(condition));
  const [wholesaleValue, setWholesaleValue] = React.useState(getWholesaleValue(condition));
  const [tradeinValue, setTradeinValue] = React.useState(getTradeinValue(condition));

  const getTrimValue = () => {
    let val = 0;
    let uocList = overrides.map(trim => trim.uoc);

    if (vehicle.add_deduct_list && vehicle.add_deduct_list.length > 0)
      vehicle.add_deduct_list.forEach(trim => {
        if (uocList.includes(trim.uoc)) {
          if (trim.auto !== `N`) val -= trim[`${getBBCondition(condition)}`];
          else val += trim[`${getBBCondition(condition)}`];
        }
      });

    return val;
  };

  const [valueType, setValueType] = React.useState(appraisal.value_type);
  const [valueTrim, setValueTrim] = React.useState(getTrimValue());
  const [valueAdjust, setValueAdjust] = React.useState(appraisal.value_adjustment);
  const [vehicleNotes, setVehicleNotes] = React.useState(appraisal.vehicle_notes || ``);

  const [appraiserName, setAppraiserName] = React.useState(appraisal.appraiser_name || ``);
  const [appraiserCredit, setAppraiserCredit] = React.useState(appraisal.appraiser_credentials || ``);
  const [appraiserContact, setAppraiserContact] = React.useState(appraisal.appraiser_contact || ``);
  const [appraiserNotes, setAppraiserNotes] = React.useState(appraisal.appraiser_notes || ``);
  const [appraiserTime, setAppraiserTime] = React.useState(
    appraisal.appraiser_expire_time_utc || moment().add(7, `days`).startOf(`day`).format()
  );

  // Get total value based on value type, trim and adjustment
  const getTotalValue = () => {
    let val = 0;

    if (valueType === `retail`) val = parseInt(retailValue) || 0;
    if (valueType === `wholesale`) val = parseInt(wholesaleValue) || 0;
    if (valueType === `tradein`) val = parseInt(tradeinValue) || 0;

    return val + (parseInt(valueTrim) || 0) + (parseInt(valueAdjust) || 0);
  };

  const [valueTotal, setValueTotal] = React.useState(appraisal.value_total);

  // useEffect called on load
  React.useEffect(() => {
    handleGetBranding(appraisal.customer.id);
  }, [appraisal]);

  // useEffect to update retail, wholesale, and tradein values
  React.useEffect(() => {
    if (editable) {
      setRetailValue(getRetailValue(condition));
      setWholesaleValue(getWholesaleValue(condition));
      setTradeinValue(getTradeinValue(condition));
    }
  }, [condition, mileageOverride]);

  // useEffect to update valueTrim
  React.useEffect(() => {
    if (editable) setValueTrim(getTrimValue());
  }, [condition, overrides]);

  // useEffect to update valueTotal
  React.useEffect(() => {
    if (editable) setValueTotal(getTotalValue());
  }, [condition, retailValue, wholesaleValue, tradeinValue, mileageOverride, valueType, valueTrim, valueAdjust]);

  // Called when the condition is changed by the dealer
  const handleConditionChange = cond => {
    setCondition(cond);
    setUnsavedChanges(true);
  };

  // Called when checkboxes are checked/unchecked
  const handleOverridesChange = override => {
    if (vehicle.add_deduct_list && vehicle.add_deduct_list.length > 0) {
      let newUocArr = overrides.map(trim => trim.uoc);

      if (newUocArr.includes(override.uoc)) {
        const index = newUocArr.indexOf(override.uoc);
        newUocArr.splice(index, 1);
      } else newUocArr.push(override.uoc);

      let newOverridesArr = vehicle.add_deduct_list.filter(trim => newUocArr.includes(trim.uoc));
      newOverridesArr = newOverridesArr.map(trim => {
        return { uoc: trim.uoc, name: trim.name, included_in_price: trim.auto !== `N` ? true : false };
      });
      log && console.log(`Overrides:`, newOverridesArr);

      setOverrides(newOverridesArr);
      setUnsavedChanges(true);
    }
  };

  // Called when the value type has changed
  const handleValueType = type => {
    if (type) {
      setValueType(type);
      setUnsavedChanges(true);
    }
  };

  // Called when the mileage override is changed
  const handleMileageChange = event => {
    const val = event.target.value;
    if (val === `` || (val && parseInt(val) >= 0 && parseInt(val) < 1000000)) {
      setMileageOverride(val);
      setUnsavedChanges(true);
    }
  };

  // Called when a textfield is typed in
  const handleInputChange = setHandler => event => {
    if (setHandler && event.target.value.length <= 255) {
      setHandler(event.target.value);
      setUnsavedChanges(true);
    }
  };

  // Called when the expire date is changed
  const handleExpireDateChange = event => {
    setAppraiserTime(moment.utc(moment(event).startOf(`day`)).format());
    setUnsavedChanges(true);
  };

  // Called when the "SAVE APPRAISAL" button is clicked
  const saveAppraisal = async () => {
    let res = await ctx.apolloClient.mutate({
      mutation: SAVE_APPRAISAL,
      variables: {
        id: appraisal.id,
        condition: condition || `good`,
        trimOverrides: overrides || [],
        mileageOverride: parseInt(mileageOverride) || null,
        valueType: valueType || `tradein`,
        valueAdjustment: valueAdjust || 0,
        valueTotal: valueTotal || 0,
        vehicleNotes: vehicleNotes || null,
        appraiserName: appraiserName || null,
        appraiserCredit: appraiserCredit || null,
        appraiserContact: appraiserContact || null,
        appraiserNotes: appraiserNotes || null,
        appraiserTime: appraiserTime || null,
      },
      onError: err => {
        Sentry.captureException(err);
        console.error(`Error saving appraisal record:`, err);
      },
    });

    if (
      res.data.update_appraisals &&
      res.data.update_appraisals.returning &&
      res.data.update_appraisals.returning.length > 0
    ) {
      let saveRes = res.data.update_appraisals.returning[0];
      log && console.log(`SAVED Appraisal #${saveRes.id}`);
      setUnsavedChanges(false);
    } else {
      console.error(`Failed to save appraisal record!`);
      toast.error(`Failed to save appraisal record!`);
    }
  };

  // Called when the "RESET APPRAISAL" button is clicked
  const resetAppraisal = () => {
    setCondition(`good`);
    setOverrides([]);

    setMileageOverride(``);

    setRetailValue(getRetailValue(`good`));
    setWholesaleValue(getWholesaleValue(`good`));
    setTradeinValue(getTradeinValue(`good`));
    setValueType(`tradein`);
    setValueAdjust(0);
    setVehicleNotes(``);

    setAppraiserName(``);
    setAppraiserCredit(``);
    setAppraiserContact(``);
    setAppraiserNotes(``);
    setAppraiserTime(moment().add(7, `days`).startOf(`day`).format());

    setValueTotal(getTotalValue());

    setUnsavedChanges(true);
  };

  // Called when the "REVERT APPRAISAL" button is clicked
  const revertAppraisal = () => {
    setCondition(appraisal.condition || `good`);
    setOverrides(appraisal.trim_overrides || []);

    setMileageOverride(appraisal.mileage_override || ``);

    setRetailValue(getRetailValue(appraisal.condition || `good`));
    setWholesaleValue(getWholesaleValue(appraisal.condition || `good`));
    setTradeinValue(getTradeinValue(appraisal.condition || `good`));
    setValueType(appraisal.value_type || `tradein`);
    setValueAdjust(appraisal.value_adjustment || 0);
    setVehicleNotes(appraisal.vehicle_notes || ``);

    setAppraiserName(appraisal.appraiser_name || ``);
    setAppraiserCredit(appraisal.appraiser_credentials || ``);
    setAppraiserContact(appraisal.appraiser_contact || ``);
    setAppraiserNotes(appraisal.appraiser_notes || ``);
    setAppraiserTime(appraisal.appraiser_expire_time_utc || moment().add(7, `days`).startOf(`day`).format());

    setValueTotal(appraisal.value_total || 0);

    setUnsavedChanges(false);
  };

  // Get customer branding from config
  const handleGetBranding = async customerId => {
    if (customerId) {
      try {
        const customerConfig = await sdk.configs.getCustomerConfig(customerId);
        const brandingRes = customerConfig && customerConfig.branding ? customerConfig.branding : {};
        setBranding(brandingRes);
      } catch (err) {
        console.error(`Error getting customer config:`, err);
      }
    }
  };

  // Get our 3rd party API vehicle data
  const getBlackBookVehicleData = async () => {
    if (appraisal) {
      // Set VIN based on the appraisal VIN passed in
      const vin = appraisal.vin;

      // Set customer ID based on what system we are currently using
      let cid = `test`;
      if (process.env.REACT_APP_ENV === `production`) cid = appraisal.customer.id;

      // Call BlackBook with params
      let res = await axios.get(
        `${process.env.REACT_APP_BB_URL}/UsedCarWS/UsedCarWS/UsedVehicle/VIN/${vin}?mileage=0&evm_flag=BOTH&template=19&customerid=${cid}&username=${process.env.REACT_APP_BB_USER}&password=${process.env.REACT_APP_BB_PASS}`
      );

      // If BlackBook gives back valid data
      if (
        res &&
        res.data &&
        res.data.used_vehicles &&
        res.data.used_vehicles.used_vehicle_list &&
        res.data.used_vehicles.used_vehicle_list.length > 0
      ) {
        return res.data.used_vehicles.used_vehicle_list[0];
      }
      // Else return null and give error
      else {
        console.error(`Vehicle not found by BlackBook!`);
        return null;
      }
    }
  };

  // Called when the "UNLOCK APPRAISAL" button is clicked
  const unlockAppraisal = async () => {
    // Get 3rd party API vehicle data
    let apiVehicle = await getBlackBookVehicleData();
    log && console.log(`API vehicle data:`, apiVehicle);

    // Set appraisal response to what was inserted
    let res = await ctx.apolloClient.mutate({
      mutation: RENEW_APPRAISAL,
      variables: {
        id: appraisal.id,
        status: apiVehicle ? `renewed` : `no data`,
        apiVehicle: apiVehicle || null,
      },
      onError: err => {
        Sentry.captureException(err);
        console.error(`Error updating appraisal record:`, err);
      },
    });

    if (
      res.data.update_appraisals &&
      res.data.update_appraisals.returning &&
      res.data.update_appraisals.returning.length > 0
    ) {
      log && console.log(`RENEW Appraisal res:`, res.data.update_appraisals.returning[0]);
      props.history.go(0);
    } else console.error(`Failed to update appraisal record!`);
  };

  // PREVENT ZERO DOLLAR VALUES (START) //

  // Check for the best possible condition switch based on the type
  const checkBestCondition = type => {
    const conditions = [`poor`, `fair`, `good`, `mint`];

    let bestCondition = `poor`;

    if (vehicle[`history_adjusted_${getBBValueType(valueType)}_${getBBCondition(`fair`)}`]) bestCondition = `fair`;
    if (vehicle[`history_adjusted_${getBBValueType(valueType)}_${getBBCondition(`good`)}`]) bestCondition = `good`;
    if (vehicle[`history_adjusted_${getBBValueType(valueType)}_${getBBCondition(`mint`)}`]) bestCondition = `mint`;

    // If its not a viable condition, switch it
    if (conditions.indexOf(bestCondition) < conditions.indexOf(condition)) setCondition(bestCondition);
  };

  // useEffect to set the best possible condition
  React.useEffect(() => {
    checkBestCondition();
  }, [valueType]);

  // Check if the condition (of the selected valueType) exists in vehicle_data object
  // If the condition does not exist, then the button is disbaled (returns true)
  const checkCondition = cond => {
    const conditionExists = vehicle[`history_adjusted_${getBBValueType(valueType)}_${getBBCondition(cond)}`];

    if (conditionExists) return false;
    return true;
  };

  // PREVENT ZERO DOLLAR VALUES (END) //

  return (
    <>
      <div className={cls.base}>
        <div className={cls.header}>
          <Grid container justifyContent='space-between' alignItems='center'>
            <Grid item>
              <Typography className={cls.keyTxt}>Appraised Value</Typography>
              <Typography className={cls.valTxt}>{valueTotal ? moneyWithCommas(valueTotal) : `-`}</Typography>
            </Grid>
            <Grid item xs />
            <Grid item>
              <Tooltip title='Revert appraisal back to saved values'>
                <IconButton
                  className={cls.revertIconParent}
                  disabled={!unsavedChanges}
                  onClick={() => revertAppraisal()}
                >
                  <Icon className={cls.icon}>history</Icon>
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title='Save an appraisal to store the current state of your form, even after the values expire'>
                <IconButton className={cls.saveIconParent} disabled={!unsavedChanges} onClick={() => saveAppraisal()}>
                  <Icon className={cls.icon}>save</Icon>
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <AppraisalPrint
                appraisal={appraisal}
                mileage={parseInt(mileageOverride) || parseInt(appraisal.vehicle_odometer) || null}
                image={vehicleImages && vehicleImages.length > 0 ? vehicleImages[0] : null}
                action='icon-button'
                brand={branding}
              />
            </Grid>
          </Grid>
        </div>

        <div className={cls.body}>
          {appraisal.status !== `no data` ? (
            <>
              {!editable || (!appraisal.vehicle_data && appraisal.status !== 'no data') ? (
                <>
                  <div className={cls.formSection}>
                    <Typography className={cls.sectionTxt}>Appraisal Locked</Typography>

                    <div className={cls.xsBreak} />

                    <Tooltip title='Renew values and make the appraisal editable.'>
                      <Button
                        className={cls.button}
                        fullWidth
                        color='primary'
                        variant='contained'
                        onClick={() => unlockAppraisal()}
                      >
                        Unlock Appraisal
                      </Button>
                    </Tooltip>

                    <div className={cls.xsBreak} />

                    <Typography className={cls.fineTxt}>
                      All appraisals lock at 12AM EST because their values have the potential to become outdated. You
                      can still print or save a PDF of the current appraisal (Last saved{' '}
                      {moment(appraisal.last_save_time_utc).format(`MM/DD/YYYY @ LT`)}). To refresh the latest appraisal
                      values and continue editing, click the button above. Note that this may change your current
                      appraised&nbsp;value.
                    </Typography>
                  </div>

                  <div className={cls.lineBreak} />
                </>
              ) : null}

              <div className={cls.formSection}>
                <Typography className={cls.sectionTxt}>Vehicle Condition</Typography>

                <div className={cls.xsBreak} />

                <Grid container spacing={1} justifyContent='space-between'>
                  <Grid item xs>
                    <Tooltip
                      title={
                        checkCondition('poor') ? 'Black Book® does not have appraisal data for this condition.' : ''
                      }
                    >
                      <span>
                        <Button
                          disabled={!editable || checkCondition('poor')}
                          className={cls.button + ' ' + (condition === `poor` ? cls.button1Active : cls.button1)}
                          fullWidth
                          variant='outlined'
                          onClick={() => handleConditionChange(`poor`)}
                        >
                          Poor
                        </Button>
                      </span>
                    </Tooltip>
                  </Grid>
                  <Grid item xs>
                    <Tooltip
                      title={
                        checkCondition('fair') ? 'Black Book® does not have appraisal data for this condition.' : ''
                      }
                    >
                      <span>
                        <Button
                          disabled={!editable || checkCondition('fair')}
                          className={cls.button + ' ' + (condition === `fair` ? cls.button2Active : cls.button2)}
                          fullWidth
                          variant='outlined'
                          onClick={() => handleConditionChange(`fair`)}
                        >
                          Fair
                        </Button>
                      </span>
                    </Tooltip>
                  </Grid>
                  <Grid item xs>
                    <Tooltip
                      title={
                        checkCondition('good') ? 'Black Book® does not have appraisal data for this condition.' : ''
                      }
                    >
                      <span>
                        <Button
                          disabled={!editable || checkCondition('good')}
                          className={cls.button + ' ' + (condition === `good` ? cls.button3Active : cls.button3)}
                          fullWidth
                          variant='outlined'
                          onClick={() => handleConditionChange(`good`)}
                        >
                          Good
                        </Button>
                      </span>
                    </Tooltip>
                  </Grid>
                  <Grid item xs>
                    <Tooltip
                      title={
                        checkCondition('mint') ? 'Black Book® does not have appraisal data for this condition.' : ''
                      }
                    >
                      <span>
                        <Button
                          disabled={!editable || checkCondition('mint')}
                          className={cls.button + ' ' + (condition === `mint` ? cls.button4Active : cls.button4)}
                          fullWidth
                          variant='outlined'
                          onClick={() => handleConditionChange(`mint`)}
                        >
                          Mint
                        </Button>
                      </span>
                    </Tooltip>
                  </Grid>
                </Grid>

                {editable && vehicle.add_deduct_list && vehicle.add_deduct_list.length > 0 ? (
                  <>
                    <div className={cls.lgBreak} />

                    <Typography className={cls.sectionTxt}>Add/Deduct Trim</Typography>

                    <div className={cls.xsBreak} />

                    {vehicle.add_deduct_list.map((trim, i) => {
                      const getChecked = () => {
                        const overridesUocArr = overrides.map(override => override.uoc);
                        if (trim.auto !== `N`) {
                          if (overridesUocArr.includes(trim.uoc)) return false;
                          else return true;
                        } else {
                          if (overridesUocArr.includes(trim.uoc)) return true;
                          else return false;
                        }
                      };
                      return (
                        <div key={`trim-override-${i}`} className={cls.checkBlock}>
                          <Checkbox
                            className={cls.check}
                            checked={getChecked()}
                            onChange={() => handleOverridesChange(trim)}
                          />
                          <Typography className={cls.checkTxt}>{trim.name}</Typography>
                        </div>
                      );
                    })}
                  </>
                ) : null}
              </div>

              <div className={cls.lineBreak} />

              <div className={cls.formSection}>
                <Typography className={cls.sectionTxt}>Build Appraisal Value</Typography>

                <div className={cls.xsBreak} />

                <Grid container spacing={1} justifyContent='space-between'>
                  <Grid item xs>
                    <Button
                      disabled={!editable}
                      className={
                        cls.button + ' ' + (valueType === `retail` ? cls.buttonSelectActive : cls.buttonSelect)
                      }
                      fullWidth
                      color='primary'
                      variant='outlined'
                      onClick={() => handleValueType(`retail`)}
                    >
                      Retail
                      {editable ? (
                        <>
                          <br />
                          {retailValue ? moneyWithCommas(retailValue) : `-`}
                        </>
                      ) : null}
                    </Button>
                  </Grid>
                  <Grid item xs>
                    <Button
                      disabled={!editable}
                      className={
                        cls.button + ' ' + (valueType === `wholesale` ? cls.buttonSelectActive : cls.buttonSelect)
                      }
                      fullWidth
                      color='primary'
                      variant='outlined'
                      onClick={() => handleValueType(`wholesale`)}
                    >
                      Wholesale
                      {editable ? (
                        <>
                          <br />
                          {wholesaleValue ? moneyWithCommas(wholesaleValue) : `-`}
                        </>
                      ) : null}
                    </Button>
                  </Grid>
                  <Grid item xs>
                    <Button
                      disabled={!editable}
                      className={
                        cls.button + ' ' + (valueType === `tradein` ? cls.buttonSelectActive : cls.buttonSelect)
                      }
                      fullWidth
                      color='primary'
                      variant='outlined'
                      onClick={() => handleValueType(`tradein`)}
                    >
                      Trade-In
                      {editable ? (
                        <>
                          <br />
                          {tradeinValue ? moneyWithCommas(tradeinValue) : `-`}
                        </>
                      ) : null}
                    </Button>
                  </Grid>
                </Grid>

                <div className={cls.lgBreak} />

                <Grid container spacing={1} justifyContent='space-between'>
                  <Grid item xs>
                    <Tooltip title='Override the current mileage with this value (Only adjust this if the header mileage is inaccurate)'>
                      <TextField
                        disabled={!editable}
                        fullWidth
                        type='number'
                        label='Mileage Override'
                        placeholder='0'
                        variant='outlined'
                        size='small'
                        value={mileageOverride}
                        onChange={handleMileageChange}
                        InputProps={{
                          startAdornment: (
                            <>
                              <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                                <Icon color='disabled' fontSize='small'>
                                  explore
                                </Icon>
                              </InputAdornment>
                            </>
                          ),
                        }}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item xs>
                    <Tooltip title='Add a custom value to adjust the total appraised value'>
                      <TextField
                        disabled={!editable}
                        fullWidth
                        type='number'
                        label='Custom Adjustment'
                        placeholder='0'
                        variant='outlined'
                        size='small'
                        value={valueAdjust}
                        onChange={handleInputChange(setValueAdjust)}
                        InputProps={{
                          startAdornment: (
                            <>
                              <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                                <Icon color='disabled' fontSize='small'>
                                  attach_money
                                </Icon>
                              </InputAdornment>
                            </>
                          ),
                        }}
                      />
                    </Tooltip>
                  </Grid>
                </Grid>

                <div className={cls.mdBreak} />

                <Tooltip title='Store any additional vehicle notes you would like to add to the appraisal record'>
                  <TextField
                    disabled={!editable}
                    fullWidth
                    multiline
                    label={`Vehicle Notes (${vehicleNotes ? vehicleNotes.length : 0}/255)`}
                    placeholder='Anything else to add?'
                    variant='outlined'
                    size='small'
                    value={vehicleNotes}
                    onChange={handleInputChange(setVehicleNotes)}
                    InputProps={{
                      startAdornment: (
                        <>
                          <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                            <Icon color='disabled' fontSize='small'>
                              comment
                            </Icon>
                          </InputAdornment>
                        </>
                      ),
                    }}
                  />
                </Tooltip>

                {editable ? (
                  <>
                    <div className={cls.mdBreak} />

                    <div className={cls.lineItem}>
                      <div className={cls.flex}>
                        <Typography className={cls.flexTxtL}>
                          {getReadableValueType(valueType)}&nbsp;Base&nbsp;Value
                        </Typography>
                        <div className={cls.flexLine} />
                        <Typography className={cls.flexTxtR}>{moneyWithCommas(getBaseValue())}</Typography>
                      </div>
                    </div>
                    <div className={cls.lineItem}>
                      <div className={cls.flex}>
                        <Typography className={cls.flexTxtL}>
                          {getReadableValueType(valueType)}&nbsp;Base&nbsp;Trim&nbsp;Adjustment
                        </Typography>
                        <div className={cls.flexLine} />
                        <Typography className={cls.flexTxtR}>{moneyWithCommas(getBaseTrim())}</Typography>
                      </div>
                    </div>
                    <div className={cls.lineItem}>
                      <div className={cls.flex}>
                        <Typography className={cls.flexTxtL}>
                          {getReadableValueType(valueType)}&nbsp;Condition&nbsp;Adjustment
                        </Typography>
                        <div className={cls.flexLine} />
                        <Typography className={cls.flexTxtR}>{moneyWithCommas(getConditionAdjustment())}</Typography>
                      </div>
                    </div>
                    <div className={cls.lineItem}>
                      <div className={cls.flex}>
                        <Typography className={cls.flexTxtL}>
                          {getReadableValueType(valueType)}&nbsp;Mileage&nbsp;Adjustment
                        </Typography>
                        <div className={cls.flexLine} />
                        <Typography className={cls.flexTxtR}>
                          {moneyWithCommas(getMileageAdjustment(condition))}
                        </Typography>
                      </div>
                    </div>
                    <div className={cls.lineItem}>
                      <div className={cls.flex}>
                        <Typography className={cls.flexTxtL}>
                          {getReadableValueType(valueType)}&nbsp;History&nbsp;Adjustment
                        </Typography>
                        <div className={cls.flexLine} />
                        <Typography className={cls.flexTxtR}>{moneyWithCommas(getHistoryAdjustment())}</Typography>
                      </div>
                    </div>
                    <div className={cls.lineItem}>
                      <div className={cls.flex}>
                        <Typography className={cls.flexTxtL}>
                          {getReadableValueType(valueType)}&nbsp;History&nbsp;Adjusted&nbsp;Value
                        </Typography>
                        <div className={cls.flexLine} />
                        <Typography className={cls.flexTxtR}>{moneyWithCommas(getHistoryAdjustedValue())}</Typography>
                      </div>
                    </div>
                    <div className={cls.xsBreak} />
                    <div className={cls.lineItem}>
                      <div className={cls.flex}>
                        <Typography className={cls.flexTxtL}>
                          {getReadableValueType(valueType)}&nbsp;Custom&nbsp;Trim&nbsp;Adjustment
                        </Typography>
                        <div className={cls.flexLine} />
                        <Typography className={cls.flexTxtR}>{moneyWithCommas(valueTrim)}</Typography>
                      </div>
                    </div>
                    <div className={cls.lineItem}>
                      <div className={cls.flex}>
                        <Typography className={cls.flexTxtL}>
                          {getReadableValueType(valueType)}&nbsp;Custom&nbsp;Value&nbsp;Adjustment
                        </Typography>
                        <div className={cls.flexLine} />
                        <Typography className={cls.flexTxtR}>{moneyWithCommas(valueAdjust)}</Typography>
                      </div>
                    </div>
                    <div className={cls.lineItem}>
                      <div className={cls.flex}>
                        <Typography className={cls.flexTxtL}>
                          {getReadableValueType(valueType)}&nbsp;Total&nbsp;Value
                        </Typography>
                        <div className={cls.flexLine} />
                        <Typography className={cls.flexTxtR}>{moneyWithCommas(valueTotal)}</Typography>
                      </div>
                    </div>
                  </>
                ) : null}

                <div className={cls.lgBreak} />

                <Tooltip title='Enter your name'>
                  <TextField
                    disabled={!editable}
                    fullWidth
                    multiline
                    label={`Appraiser Name`}
                    placeholder='Enter your name...'
                    variant='outlined'
                    size='small'
                    value={appraiserName}
                    onChange={handleInputChange(setAppraiserName)}
                    InputProps={{
                      startAdornment: (
                        <>
                          <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                            <Icon color='disabled' fontSize='small'>
                              account_circle
                            </Icon>
                          </InputAdornment>
                        </>
                      ),
                    }}
                  />
                </Tooltip>

                <div className={cls.mdBreak} />

                <Tooltip title='Enter your job title/credentials'>
                  <TextField
                    disabled={!editable}
                    fullWidth
                    multiline
                    label={`Appraiser Credentials`}
                    placeholder='Enter your job title...'
                    variant='outlined'
                    size='small'
                    value={appraiserCredit}
                    onChange={handleInputChange(setAppraiserCredit)}
                    InputProps={{
                      startAdornment: (
                        <>
                          <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                            <Icon color='disabled' fontSize='small'>
                              local_offer
                            </Icon>
                          </InputAdornment>
                        </>
                      ),
                    }}
                  />
                </Tooltip>

                <div className={cls.mdBreak} />

                <Tooltip title='Enter your most up-to-date contact info for the customer to reach you'>
                  <TextField
                    disabled={!editable}
                    fullWidth
                    multiline
                    label={`Appraiser Contact`}
                    placeholder='Enter your phone/email...'
                    variant='outlined'
                    size='small'
                    value={appraiserContact}
                    onChange={handleInputChange(setAppraiserContact)}
                    InputProps={{
                      startAdornment: (
                        <>
                          <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                            <Icon color='disabled' fontSize='small'>
                              contact_phone
                            </Icon>
                          </InputAdornment>
                        </>
                      ),
                    }}
                  />
                </Tooltip>

                <div className={cls.mdBreak} />

                <Tooltip title='Write what you want to say to the customer'>
                  <TextField
                    disabled={!editable}
                    fullWidth
                    multiline
                    label={`Appraiser Message`}
                    placeholder='What do you want to say?'
                    variant='outlined'
                    size='small'
                    value={appraiserNotes}
                    onChange={handleInputChange(setAppraiserNotes)}
                    InputProps={{
                      startAdornment: (
                        <>
                          <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                            <Icon color='disabled' fontSize='small'>
                              chat
                            </Icon>
                          </InputAdornment>
                        </>
                      ),
                    }}
                  />
                </Tooltip>

                <div className={cls.mdBreak} />

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Tooltip title='Set the date you would like the appraisal to expire'>
                    <DatePicker
                      disabled={!editable}
                      autoOk
                      fullWidth
                      minDate={moment().format()}
                      variant='inline'
                      orientation='portrait'
                      format='MM/dd/yyyy'
                      label='Expiration Date'
                      size='small'
                      inputVariant='outlined'
                      value={appraiserTime}
                      onChange={handleExpireDateChange}
                    />
                  </Tooltip>
                </MuiPickersUtilsProvider>

                <div className={cls.mdBreak} />

                <Grid container spacing={1} justifyContent='space-between'>
                  <Grid item xs>
                    <Tooltip title='Save an appraisal to store the current state of your form, even after the values expire'>
                      {unsavedChanges ? (
                        <Button
                          disabled={!editable}
                          className={cls.button}
                          fullWidth
                          color='primary'
                          variant='contained'
                          onClick={() => saveAppraisal()}
                        >
                          Save Appraisal
                        </Button>
                      ) : (
                        <AppraisalPrint
                          appraisal={appraisal}
                          mileage={parseInt(mileageOverride) || parseInt(appraisal.vehicle_odometer) || null}
                          image={vehicleImages && vehicleImages.length > 0 ? vehicleImages[0] : null}
                          action='button'
                        />
                      )}
                    </Tooltip>
                  </Grid>
                  <Grid item xs>
                    <Tooltip
                      title={
                        unsavedChanges
                          ? `Revert appraisal back to saved values`
                          : `Reset an appraisal to clear any adjustments made and set all values to default`
                      }
                    >
                      <Button
                        disabled={!editable}
                        className={cls.button}
                        fullWidth
                        color='secondary'
                        variant='contained'
                        onClick={() => (unsavedChanges ? revertAppraisal() : resetAppraisal())}
                      >
                        {unsavedChanges ? `Revert Appraisal` : `Reset Appraisal`}
                      </Button>
                    </Tooltip>
                  </Grid>
                </Grid>

                <div className={cls.xsBreak} />

                <Typography className={cls.fineTxt}>
                  * This form displays values pulled from Black Book<sup>®</sup> as of{' '}
                  {moment(appraisal.last_fetch_time_utc).format(`MM/DD/YYYY`)}.
                </Typography>

                <div className={cls.xlBreak} />

                <Grid container spacing={1} alignItems='center' wrap='nowrap'>
                  <Grid item xs />
                  <Grid item>
                    <Typography className={cls.bbText}>POWERED BY</Typography>
                  </Grid>
                  <Grid item>
                    <a href='https://www.blackbook.com/' target='_blank' rel='noopener noreferrer'>
                      <img src={bb_logo_primary} width='80' alt='blackbook logo' />
                      {/* <img src={bb_logo_secondary} width='128' alt='blackbook logo' /> */}
                    </a>
                  </Grid>
                  <Grid item xs />
                </Grid>

                <div className={cls.mdBreak} />
              </div>
            </>
          ) : (
            <>
              <div className={cls.formSection}>
                <Typography className={cls.sectionTxt}>
                  No Black Book<sup>®</sup> Data Available
                </Typography>

                <div className={cls.xsBreak} />

                <Typography className={cls.fineTxt}>
                  Black Book<sup>®</sup> data could not be found for this vehicle. Therefore, no values can be
                  displayed. This is most commonly due to having an unknown VIN. If you know the VIN is valid, but you
                  are seeing this page, please contact&nbsp;us.
                </Typography>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

//////////////////////////////////////// STYLES ////////////////////////////////////////
const useStyles = makeStyles(theme => ({
  base: {
    zIndex: 20,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100vh',
    paddingTop: '64px',
    background: '#fff',
    boxShadow: '0 0 16px #00000032',
  },
  header: {
    position: 'relative',
    width: '100%',
    height: '80px',
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.border}`,
  },
  keyTxt: {
    marginBottom: theme.spacing(0.5),
    lineHeight: 1,
    color: theme.palette.text.secondary,
    fontSize: 12,
    fontWeight: 500,
  },
  valTxt: {
    lineHeight: 1,
    fontSize: 24,
    fontWeight: 500,
  },
  revertIconParent: {
    '&:hover $icon': {
      color: theme.palette.error.main,
    },
  },
  saveIconParent: {
    '&:hover $icon': {
      color: theme.palette.secondary.main,
    },
  },
  icon: {
    transition: '0.2s',
  },
  body: {
    position: 'relative',
    width: '100%',
    height: 'auto',
    overflow: typeof InstallTrigger !== 'undefined' ? 'auto' : 'overlay',
  },
  formSection: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
  },
  sectionTxt: {
    lineHeight: 1,
    fontSize: 18,
    fontWeight: 500,
  },
  check: {
    padding: theme.spacing(0.25),
  },
  checkTxt: {
    verticalAlign: 'middle',
    display: 'inline-block',
    marginTop: theme.spacing(0.25),
    marginLeft: theme.spacing(0.5),
    lineHeight: 1,
    fontSize: 16,
  },
  button: {
    color: '#fff',
    boxShadow: 'none',
    '&:hover, &:active': {
      boxShadow: 'none',
    },
    transition: '0.2s',
  },
  button1: {
    color: theme.palette.text.primary,
    borderColor: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
      backgroundColor: 'transparent',
    },
  },
  button2: {
    color: theme.palette.text.primary,
    borderColor: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.warning.main,
      borderColor: theme.palette.warning.main,
      backgroundColor: 'transparent',
    },
  },
  button3: {
    color: theme.palette.text.primary,
    borderColor: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.success.main,
      borderColor: theme.palette.success.main,
      backgroundColor: 'transparent',
    },
  },
  button4: {
    color: theme.palette.text.primary,
    borderColor: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.info.main,
      borderColor: theme.palette.info.main,
      backgroundColor: 'transparent',
    },
  },
  button1Active: {
    borderColor: theme.palette.error.main,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.main,
    },
  },
  button2Active: {
    borderColor: theme.palette.warning.main,
    backgroundColor: theme.palette.warning.main,
    '&:hover': {
      backgroundColor: theme.palette.warning.main,
    },
  },
  button3Active: {
    borderColor: theme.palette.success.main,
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.main,
    },
  },
  button4Active: {
    borderColor: theme.palette.info.main,
    backgroundColor: theme.palette.info.main,
    '&:hover': {
      backgroundColor: theme.palette.info.main,
    },
  },
  buttonSelect: {
    lineHeight: 1.5,
    color: theme.palette.text.primary,
    borderColor: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      backgroundColor: 'transparent',
    },
  },
  buttonSelectActive: {
    lineHeight: 1.5,
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  lineItem: {
    display: `block`,
    marginTop: theme.spacing(0.75),
    marginBottom: theme.spacing(0.75),
  },
  flex: {
    display: 'flex',
    flexWrap: 'nowrap',
    verticalAlign: 'top',
    width: '100%',
  },
  flexLine: {
    width: '100%',
    height: '1px',
    marginTop: '5.5px',
    marginBottom: '5.5px',
    background: '#eee',
  },
  flexTxtL: {
    verticalAlign: 'top',
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
    lineHeight: 1,
    fontSize: '12px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  flexTxtR: {
    verticalAlign: 'top',
    marginLeft: theme.spacing(1),
    lineHeight: 1,
    fontSize: '12px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  fineTxt: {
    lineHeight: 1.5,
    color: theme.palette.text.secondary,
    fontSize: 12,
    fontWeight: 400,
  },
  bbText: {
    paddingBottom: theme.spacing(0.375),
    lineHeight: 1,
    color: theme.palette.text.secondary,
    fontSize: 12,
    fontWeight: 400,
  },
  xsBreak: {
    width: '100%',
    height: theme.spacing(1),
  },
  smBreak: {
    width: '100%',
    height: theme.spacing(1.5),
  },
  mdBreak: {
    width: '100%',
    height: theme.spacing(2),
  },
  lgBreak: {
    width: '100%',
    height: theme.spacing(3),
  },
  xlBreak: {
    width: '100%',
    height: theme.spacing(4),
  },
  lineBreak: {
    width: '100%',
    height: 1,
    background: theme.palette.border,
  },
}));

//////////////////////////////////////// GRAPHQL ////////////////////////////////////////
const SAVE_APPRAISAL = gql`
  mutation save_appraisal(
    $id: bigint!
    $condition: String!
    $trimOverrides: jsonb
    $mileageOverride: bigint
    $valueType: String!
    $valueAdjustment: bigint!
    $valueTotal: bigint!
    $vehicleNotes: String
    $appraiserName: String
    $appraiserCredit: String
    $appraiserContact: String
    $appraiserNotes: String
    $appraiserTime: timestamptz
  ) {
    update_appraisals(
      where: { id: { _eq: $id } }
      _set: {
        condition: $condition
        trim_overrides: $trimOverrides
        mileage_override: $mileageOverride
        value_type: $valueType
        value_adjustment: $valueAdjustment
        value_total: $valueTotal
        vehicle_notes: $vehicleNotes
        appraiser_name: $appraiserName
        appraiser_credentials: $appraiserCredit
        appraiser_contact: $appraiserContact
        appraiser_notes: $appraiserNotes
        appraiser_expire_time_utc: $appraiserTime
        last_save_time_utc: "now()"
      }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

const RENEW_APPRAISAL = gql`
  mutation renew_appraisal($id: bigint!, $status: String!, $apiVehicle: jsonb) {
    update_appraisals(
      where: { id: { _eq: $id } }
      _set: { status: $status, vehicle_data: $apiVehicle, last_fetch_time_utc: "now()" }
    ) {
      affected_rows
      returning {
        id
        status
        vehicle_data
      }
    }
  }
`;

const GET_CUSTOMER_BRAND = gql`
  query get_customer_brand($id: bigint!) {
    customers(where: { id: { _eq: $id } }) {
      branding: config(path: "$branding")
    }
  }
`;

//////////////////////////////////////// EXPORT ////////////////////////////////////////
export default withRouter(AppraisalDetailsSidebar);
