import React from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';
import axios from 'axios';
import { useData } from '../../DataProvider';
import { toast } from 'react-toastify';
import sdk from '@hopdrive/sdk';

import { makeStyles, Container, Typography, Tooltip, Icon, Grid, Button } from '@material-ui/core';

import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import * as Sentry from '@sentry/react';

import Loading from '../utils/Loading';
import AppraisalsFilter1 from './appraisals/AppraisalsFilter1';
import AppraisalsFilter2 from './appraisals/AppraisalsFilter2';

import { AccordianTable, AccordianRow, TableSort } from '../reusable/AccordianTable';

import { ExportToCsv } from 'export-to-csv';
import { useTools } from '../hooks/useTools';

const log = false;

const last8Digits = vin => {
  if (vin && vin.length >= 8) return vin.slice(-8);
};

function cleansePhoneNumber(num) {
  var cleaned = ('' + num).replace(/\D/g, '');
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
}

const getDefaultDisable = () => {
  const localRange = localStorage.getItem(`appraisal-index-range`);
  if (localRange && localRange === `custom`) return false;
  else return true;
};
const getDefaultRange = () => {
  const localRange = localStorage.getItem(`appraisal-index-range`);
  if (localRange) return localRange;
  else return `this`;
};
const getDefaultStart = () => {
  const localRange = localStorage.getItem(`appraisal-index-range`);
  const localStart = localStorage.getItem(`appraisal-index-start`);
  if (localRange === `custom` && localStart) return localStart;
  else if (localRange === `last`) return moment.utc(moment().startOf(`day`).subtract(6, `months`)).format();
  else return moment.utc(moment().startOf(`day`).subtract(3, `months`)).format();
};
const getDefaultEnd = () => {
  const localRange = localStorage.getItem(`appraisal-index-range`);
  const localEnd = localStorage.getItem(`appraisal-index-end`);
  if (localRange === `custom` && localEnd) return localEnd;
  else if (localRange === `last`) return moment.utc(moment().endOf(`day`).subtract(3, `months`)).format();
  else return moment.utc(moment().endOf(`day`)).format();
};

const defaultOrder = `desc`;
const defaultOrderBy = `APPRAISAL_TIME`;

//////////////////////////////////////// COMPONENT ////////////////////////////////////////
function Appraisals(props) {
  const ctx = useData();
  const cls = useStyles();
  const { getVin } = useTools();

  const [disablePickers, setDisablePickers] = React.useState(getDefaultDisable());
  const [range, setRange] = React.useState(getDefaultRange());
  const [start, setStart] = React.useState(getDefaultStart());
  const [end, setEnd] = React.useState(getDefaultEnd());

  const [search, setSearch] = React.useState(``);
  const [order, setOrder] = React.useState(defaultOrder);
  const [orderBy, setOrderBy] = React.useState(defaultOrderBy);
  const [tablePage, setTablePage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [expandedRowId, setExpandedRowId] = React.useState(0);

  // const [apiLoading, setApiLoading] = React.useState(false);

  let customerId = parseInt(
    ctx.customerOverride ||
      (ctx.customerId 
        ? ctx.customerId
        : 0)
  );

  // const [isConcierge, setIsConcierge] = React.useState(getDefaultIsConcierge());
  const [statusTags, setStatusTags] = React.useState([]);
  const [makeTags, setMakeTags] = React.useState([]);
  const [modelTags, setModelTags] = React.useState([]);
  const [yearTags, setYearTags] = React.useState([]);
  const [colorTags, setColorTags] = React.useState([]);

  const [accessLoading, setAccessLoading] = React.useState(true);
  const [access, setAccess] = React.useState({});

  const [apiLoading, setApiLoading] = React.useState(false);

  React.useEffect(() => {
    setCustomerAccess(customerId);
  }, [customerId]);

  // Get our 3rd party API vehicle data
  const getBlackBookVehicleData = async vehicle => {
    if (vehicle) {
      // Set VIN based on the appraisal VIN passed in
      const vin = getVin(vehicle);

      // Set customer ID based on what system we are currently using
      let cid = `test`;
      if (process.env.REACT_APP_ENV === `production`) cid = customerId;

      // Call BlackBook with params
      let res = await axios.get(
        `${process.env.REACT_APP_BB_URL}/UsedCarWS/UsedCarWS/UsedVehicle/VIN/${vin}?mileage=0&evm_flag=BOTH&template=19&customerid=${cid}&username=${process.env.REACT_APP_BB_USER}&password=${process.env.REACT_APP_BB_PASS}`
      );

      // If BlackBook gives back valid data
      if (
        res &&
        res.data &&
        res.data.used_vehicles &&
        res.data.used_vehicles.used_vehicle_list &&
        res.data.used_vehicles.used_vehicle_list.length > 0
      ) {
        return res.data.used_vehicles.used_vehicle_list[0];
      }
      // Else return null and give error
      else {
        console.error(`Vehicle not found by BlackBook!`);
        return null;
      }
    }
  };

  // Get the customer config and set the customer's access
  const setCustomerAccess = async customerId => {
    if (customerId) {
      try {
        const customerConfig = await sdk.configs.getCustomerConfig(customerId);
        const appraisalsAccess = customerConfig && customerConfig.appraisals ? customerConfig.appraisals : {};
        setAccess(appraisalsAccess);
        setAccessLoading(false);
      } catch (err) {
        console.error(`Error getting customer config:`, err);
      }
    }
  };

  // Insert an appraisal into the db
  const insertAppraisal = async appraisalToInsert => {
    try {
      let res = await ctx.apolloClient.mutate({
        mutation: INSERT_APPRAISALS,
        variables: { appraisals: [appraisalToInsert] },
        onError: err => {
          Sentry.captureException(err);
          console.error(`Error inserting appraisal record:`, err);
        },
      });

      if (
        res.data.insert_appraisals &&
        res.data.insert_appraisals.returning &&
        res.data.insert_appraisals.returning.length > 0
      ) {
        return res.data.insert_appraisals.returning[0];
      } else return null;
    } catch (err) {
      console.error(`Error inserting appraisal record:`, err);
      return null;
    }
  };

  // Called when the heart is clicked
  const favoriteAppraisal = async appraisal => {
    if (appraisal.id) {
      let res = await ctx.apolloClient.mutate({
        mutation: FAVORITE_APPRAISAL,
        variables: {
          id: appraisal.id,
          favorite: appraisal.favorite ? false : true,
        },
        onError: err => {
          Sentry.captureException(err);
          console.error(`Error favoriting appraisal record:`, err);
        },
      });

      if (
        res.data.update_appraisals &&
        res.data.update_appraisals.returning &&
        res.data.update_appraisals.returning.length > 0
      ) {
        let favRes = res.data.update_appraisals.returning[0];
        log && console.log(`FAVORITED Appraisal #${favRes.id}`);
      } else {
        console.error(`Failed to favorite appraisal record!`);
        toast.error(`Failed to favorite appraisal record!`);
      }
    } else {
      console.error(`Appraisal record does not exist, cannot favorite!`);
      toast.error(`Please view the appraisal record before favoriting it!`);
    }
  };

  // If appraisal record doesnt exist (based on VIN for now), insert a new one
  //   Then call 3rd party API and update appraisal record's status and vehicle_data
  // Load appraisal details page (getAppraisalById is queried there)
  // Should only take in ID once we set up appraisals view in db
  const goToAppraisalDetails = async vehicle => {
    setApiLoading(true);

    // If vin exists
    if (vehicle && vehicle.vin) {
      // If an appraisal already exists, go to it
      if (vehicle.appraisal && vehicle.appraisal.id) {
        log && console.log(`Appraisal exists! Huzzah!`, vehicle.appraisal);
        props.history.push(`/appraisals/${vehicle.appraisal.id}`);
      }
      // Else make a new appraisal and call 3rd party API
      else {
        // Get 3rd party API vehicle data
        let apiVehicle = await getBlackBookVehicleData(vehicle);
        const vin = getVin(vehicle);
        log && console.log(`API vehicle data:`, apiVehicle);

        // Set appraisal response to what was inserted
        let insertAppraisalRes = await insertAppraisal({
          customer_id: customerId || null,
          status: apiVehicle ? `new` : `no data`,
          vin: vin,
          vehicle_data: apiVehicle || null,
        });
        log && console.log(`INSERT Appraisal res:`, insertAppraisalRes);

        // If response is successful at inserting an appraisal, go to the appraisal details
        if (insertAppraisalRes && insertAppraisalRes.id) props.history.push(`/appraisals/${insertAppraisalRes.id}`);
        // Else show an error
        else toast.error(`Something went wrong! Try refreshing the page.`);
      }
    }
    // Else show an error
    else toast.error(`VIN is missing or invalid!`);

    setApiLoading(false);
  };

  // Go to the latest move details
  const goToMoveDetails = moveId => {
    if (moveId) props.history.push(`/moves/${moveId}`);
  };

  // Control range picker
  const handleRangeChange = value => {
    log && console.log(`handleRangeChange() Value:`, value);
    localStorage.setItem(`appraisal-index-range`, value);
    if (value === `this`) {
      setDisablePickers(true);
      setRange(value);
      setStart(moment.utc(moment().startOf(`day`).subtract(3, `months`)).format());
      setEnd(moment.utc(moment().endOf(`day`)).format());
    } else if (value === `last`) {
      setDisablePickers(true);
      setRange(value);
      setStart(moment.utc(moment().startOf(`day`).subtract(6, `months`)).format());
      setEnd(moment.utc(moment().endOf(`day`).subtract(3, `months`)).format());
    } else {
      setDisablePickers(false);
      setRange(value);
      setStart(getDefaultStart());
      setEnd(getDefaultEnd());
    }
  };

  // Control date pickers
  const handleDateChange = (value, name) => {
    log && console.log(`Date Change:`, { value, name });

    // Set start date
    // If the start date is more than 3 months before the end date, move the end date
    // If the start date is after the end date, move the end date
    if (name === `start`) {
      const newStart = moment.utc(moment(value).startOf(`day`)).format();
      if (moment(newStart).isBefore(moment(end).subtract(3, `months`))) {
        const newEnd = moment.utc(moment(newStart).endOf(`day`).add(3, `months`)).format();
        localStorage.setItem(`appraisal-index-end`, newEnd);
        setEnd(newEnd);
      }
      if (moment(newStart).isAfter(moment(end))) {
        const newEnd = moment.utc(moment(newStart).endOf(`day`)).format();
        localStorage.setItem(`appraisal-index-end`, newEnd);
        setEnd(newEnd);
      }
      localStorage.setItem(`appraisal-index-start`, newStart);
      setStart(newStart);
    }

    // Set end date
    // If the end date is before the start date, move the start date
    // If the end date is more than 3 months after the start date, move the start date
    if (name === `end`) {
      const newEnd = moment.utc(moment(value).startOf(`day`)).format();
      if (moment(newEnd).isBefore(moment(start))) {
        const newStart = moment.utc(moment(newEnd).startOf(`day`)).format();
        localStorage.setItem(`appraisal-index-start`, newStart);
        setStart(newStart);
      }
      if (moment(newEnd).isAfter(moment(start).add(3, `months`))) {
        const newStart = moment.utc(moment(newEnd).startOf(`day`).subtract(3, `months`)).format();
        localStorage.setItem(`appraisal-index-start`, newStart);
        setStart(newStart);
      }
      localStorage.setItem(`appraisal-index-end`, newEnd);
      setEnd(newEnd);
    }
  };

  const handleClearFilters = () => {
    setSearch(``);
    setStatusTags([]);
    setMakeTags([]);
    setModelTags([]);
    setYearTags([]);
    setColorTags([]);
  };

  // Filter down only allowed moves
  const applyAllowed = dataset => {
    if (dataset && dataset.length > 0 && access.allowed_move_types && access.allowed_move_types.length > 0) {
      let allowedArray = [];

      if (access.allowed_move_types.includes(`concierge`)) {
        log && console.log(`Concierge moves allowed!`);
        const tmpArray = dataset.filter(result => result.move.consumer_pickup);
        allowedArray = [...allowedArray, ...tmpArray];
      }
      if (access.allowed_move_types.includes(`operations`)) {
        log && console.log(`Operations moves allowed!`);
        const tmpArray = dataset.filter(result => !result.move.consumer_pickup);
        allowedArray = [...allowedArray, ...tmpArray];
      }

      return allowedArray;
    } else return [];
  };

  // Apply sorting for table view
  const applySorting = dataset => {
    if (dataset && dataset.length > 0)
      return dataset.sort((a, b) => {
        const aTime = a.move.pickup_time || a.move.ready_by;
        const bTime = b.move.pickup_time || b.move.ready_by;
        if (!aTime) return 1;
        if (aTime > bTime) return -1;
        if (aTime < bTime) return 1;
        return 0;
      });
    else return [];
  };

  // Apply multiple filter options on the dataset
  const applyFilters = dataset => {
    let filterResults = dataset;

    if (statusTags && statusTags.length > 0) {
      filterResults = filterResults.filter(vehicle => {
        const appraisal = vehicle.appraisal || {};
        const appraisalTime =
          appraisal.last_save_time_utc ||
          appraisal.last_fetch_time_utc ||
          vehicle.move.pickup_time ||
          vehicle.move.ready_by ||
          null;
        let appraisalViewed = false;

        if (appraisal.id && appraisalTime && moment(appraisalTime).isAfter(moment().subtract(1, `months`).format()))
          appraisalViewed = true;

        if (appraisalViewed && statusTags.includes(`Viewed`)) return true;
        if (!appraisalViewed && statusTags.includes(`Not Viewed`)) return true;
        return false;
      });
    }
    if (makeTags && makeTags.length > 0) {
      filterResults = filterResults.filter(vehicle => {
        if (vehicle.move.vehicle_make && makeTags.includes(vehicle.move.vehicle_make)) return true;
        return false;
      });
    }
    if (modelTags && modelTags.length > 0) {
      filterResults = filterResults.filter(vehicle => {
        if (vehicle.move.vehicle_model && modelTags.includes(vehicle.move.vehicle_model)) return true;
        return false;
      });
    }
    if (yearTags && yearTags.length > 0) {
      filterResults = filterResults.filter(vehicle => {
        if (vehicle.move.vehicle_year && yearTags.includes(vehicle.move.vehicle_year)) return true;
        return false;
      });
    }
    if (colorTags && colorTags.length > 0) {
      filterResults = filterResults.filter(vehicle => {
        if (vehicle.move.vehicle_color && colorTags.includes(vehicle.move.vehicle_color)) return true;
        return false;
      });
    }

    let searchResults = filterResults;
    if (search && search.length > 0) {
      searchResults = searchResults.filter(vehicle => {
        if (
          (vehicle.move.vehicle_year && (vehicle.move.vehicle_year + ``).toLocaleLowerCase().includes(search)) ||
          (vehicle.move.vehicle_make && (vehicle.move.vehicle_make + ``).toLocaleLowerCase().includes(search)) ||
          (vehicle.move.vehicle_model && (vehicle.move.vehicle_model + ``).toLocaleLowerCase().includes(search)) ||
          (vehicle.vin && (vehicle.vin + ``).toLocaleLowerCase().includes(search))
        )
          return true;
        else return false;
      });
    }

    return searchResults;
  };

  const generateCSV = vehicles => {
    // Create rows and options for CSV
    const createCsvRow = vehicle => {
      return {
        VEHICLE:
          `${vehicle.move.vehicle_year || ``} ${vehicle.move.vehicle_make || ``} ${vehicle.move.vehicle_model || ``}` ||
          `-`,
        VIN: vehicle.vin || `-`,
        STATUS: (vehicle.appraisal && vehicle.appraisal.status) || `-`,
        APPRAISAL_TIME: vehicle.move.pickup_time || vehicle.move.ready_by || `-`,
      };
    };
    const csvRows = vehicles.map(move => createCsvRow(move));
    const csvOptions = {
      filename: `Potential_Appraisal_Vehicles`,
      showTitle: true,
      title: `Potential_Appraisal_Vehicles`,
      useKeysAsHeaders: true,
    };

    // Create and generate the CSV
    const csvExporter = new ExportToCsv(csvOptions);
    csvExporter.generateCsv(csvRows);
  };

  const getTableActions = vehicles => {
    return [
      { name: `generate-csv`, label: `Generate\xa0CSV`, data: { vehicles: vehicles }, handler: handleContextMenuClick },
    ];
  };
  const getRowActions = vehicle => {
    return [
      {
        name: `appraisal-details`,
        label: `Appraisal\xa0Details`,
        data: { vehicle: vehicle },
        handler: handleContextMenuClick,
      },
      {
        name: `move-details`,
        label: `Move\xa0Details`,
        data: { moveId: vehicle && vehicle.move.id },
        handler: handleContextMenuClick,
      },
    ];
  };

  const handleContextMenuClick = async (e, data, target) => {
    if (data.action.name === `generate-csv`) {
      generateCSV(data.vehicles);
      return;
    }
    if (data.action.name === `appraisal-details`) {
      goToAppraisalDetails(data.vehicle);
      return;
    }
    if (data.action.name === `move-details`) {
      goToMoveDetails(data.moveId);
      return;
    }
  };

  if (accessLoading || apiLoading) return <Loading fixed />;
  if (!access.active)
    return (
      <div className={cls.restricted}>
        <Container maxWidth='md'>
          <Typography className={cls.restrictedText}>
            Welcome to the new HopDrive appraisal feature. This revolutionary tool will turn your Service Lane into an
            Appraisal Drive and help you buy more cars from your customers! If you are interested in seeing a demo,
            please email sales@hopdrive.com to set up some time.
          </Typography>
          <a href='mailto:sales@hopdrive.com?cc=sales@hopdrive.com&subject=Appraisals%20-%20Demo%20Request'>
            <Button className={cls.restrictedButton} color='primary' variant='contained' size='large'>
              Request Demo
            </Button>
          </a>
        </Container>
      </div>
    );
  return (
    <div className={cls.root}>
      <Container maxWidth='xl'>
        <Typography className={cls.head}>Appraisal Index</Typography>

        <AppraisalsFilter1
          range={range}
          start={start}
          end={end}
          onRangeChange={handleRangeChange}
          onDateChange={handleDateChange}
          disablePickers={disablePickers}
        />

        {ctx && ctx.firebaseUser && (
          <Query
            query={GET_APPRAISAL_ROWS}
            variables={{
              customerId: customerId,
              start: start,
              end: end,
            }}
            onError={error => {
              console.error(error);
              Sentry.captureException(error);
            }}
          >
            {({ loading, data, refetch }) => {
              if (loading) return <Loading fixed />;
              if (data && data.appraisal_vehicles && data.appraisal_vehicles.length > 0) {
                let allowedVehicles = applyAllowed(data.appraisal_vehicles);
                let vehicles = applySorting(allowedVehicles);
                log && console.log(`Vehicles for Appraisal Index:`, vehicles);
                const filteredData = applyFilters(vehicles);
                const headers = [
                  { id: `VEHICLE`, alignLeft: true, numeric: false, label: `Vehicle` },
                  { id: `VIN`, alignLeft: true, numeric: false, label: `VIN\xa0(Last\xa08)` },
                  { id: `CONSUMER_NAME`, alignLeft: true, numeric: false, label: `Customer\xa0Name` },
                  { id: `CONSUMER_PHONE`, alignLeft: true, numeric: false, label: `Customer\xa0Phone` },
                  { id: `APPRAISAL_TIME`, alignLeft: true, numeric: false, label: `Appraisal\xa0Time` },
                  { id: `STATUS`, alignLeft: false, numeric: false, label: `Status` },
                  { id: `FAVORITE`, alignLeft: false, numeric: false, label: `Favorite` },
                ];
                const rows = filteredData.map(vehicle => {
                  const appraisal = vehicle.appraisal || {};
                  const appraisalTime =
                    appraisal.last_save_time_utc ||
                    appraisal.last_fetch_time_utc ||
                    vehicle.move.pickup_time ||
                    vehicle.move.ready_by ||
                    null;
                  let appraisalViewed = false;
                  const vin = getVin(vehicle);

                  if (
                    appraisal.id &&
                    appraisalTime &&
                    moment(appraisalTime).isAfter(moment().subtract(1, `months`).format())
                  )
                    appraisalViewed = true;

                  return {
                    VEHICLE:
                      `${vehicle.move.vehicle_year || ``} ${vehicle.move.vehicle_make || ``} ${
                        vehicle.move.vehicle_model || ``
                      }` || null,
                    VIN: vin,
                    CONSUMER_NAME: vehicle.move.consumer_name || null,
                    CONSUMER_PHONE: vehicle.move.consumer_phone || null,
                    APPRAISAL_TIME: vehicle.move.pickup_time || vehicle.move.ready_by || null,
                    STATUS: (
                      <Tooltip
                        placement='top'
                        title={
                          appraisal.id && appraisalViewed
                            ? `Vehicle has been viewed/edited in the past month`
                            : `Vehicle has NOT been viewed/edited in the past month`
                        }
                      >
                        <Icon className={cls.icon}>
                          {appraisal.id && appraisalViewed ? `visibility` : `visibility_off`}
                        </Icon>
                      </Tooltip>
                    ),
                    FAVORITE: (
                      <Tooltip placement='top' title={appraisal.favorite ? `Unfavorite Vehicle` : `Favorite Vehicle`}>
                        <Icon
                          className={appraisal.favorite ? cls.heartActive : cls.heart}
                          onClick={() => favoriteAppraisal(appraisal)}
                        >
                          {appraisal.favorite ? `favorite` : `favorite_border`}
                        </Icon>
                      </Tooltip>
                    ),
                    vehicle: vehicle,
                  };
                });

                return (
                  <>
                    <Grid container spacing={2} wrap='nowrap'>
                      <Grid item>
                        <AppraisalsFilter2
                          vehicles={vehicles}
                          handleClearFilters={handleClearFilters}
                          tags={{
                            status: statusTags,
                            make: makeTags,
                            model: modelTags,
                            year: yearTags,
                            color: colorTags,
                          }}
                          setTags={{
                            status: setStatusTags,
                            make: setMakeTags,
                            model: setModelTags,
                            year: setYearTags,
                            color: setColorTags,
                          }}
                        />
                      </Grid>
                      <Grid item xs>
                        <AccordianTable
                          title={`${rows.length} Vehicles`}
                          headers={headers}
                          rows={rows}
                          actions={getTableActions(vehicles)}
                          size={`small`}
                          search={search}
                          defaultOrder={defaultOrder}
                          defaultOrderBy={defaultOrderBy}
                          order={order}
                          orderBy={orderBy}
                          tablePage={tablePage}
                          rowsPerPage={rowsPerPage}
                          rowsPerPageOptions={[10, 25, 50, 100]}
                          setSearch={setSearch}
                          setOrder={setOrder}
                          setOrderBy={setOrderBy}
                          setTablePage={setTablePage}
                          setRowsPerPage={setRowsPerPage}
                          setExpandedRowId={setExpandedRowId}
                          className={cls.table}
                          refetch={refetch}
                          refreshPersistAs={'appraisals'}
                        >
                          {TableSort.stableSort(rows, TableSort.getSorting(order, orderBy))
                            .slice(tablePage * rowsPerPage, tablePage * rowsPerPage + rowsPerPage)
                            .map(row => (
                              <AccordianRow
                                key={`appraisal-index-row-${row.vehicle.move.id}`}
                                rowId={row.vehicle.move.id}
                                expandedRowId={expandedRowId}
                                setExpandedRowId={setExpandedRowId}
                                columns={[
                                  { align: 'left', value: row.VEHICLE || `-` },
                                  { align: 'left', value: last8Digits(row.VIN) || `-` },
                                  { align: 'left', value: row.CONSUMER_NAME || `-` },
                                  { align: 'left', value: cleansePhoneNumber(row.CONSUMER_PHONE) || `-` },
                                  {
                                    align: 'left',
                                    value: row.APPRAISAL_TIME ? moment(row.APPRAISAL_TIME).format(`MM/DD hh:mm`) : `-`,
                                  },
                                  { align: 'right', value: row.STATUS },
                                  { align: 'right', value: row.FAVORITE, preventClick: true },
                                ]}
                                actions={getRowActions(row.vehicle)}
                                onClick={() => goToAppraisalDetails(row.vehicle)}
                                className={cls.row}
                              >
                                <div></div>
                              </AccordianRow>
                            ))}
                        </AccordianTable>
                      </Grid>
                    </Grid>
                  </>
                );
              } else
                return (
                  <div className={cls.notFound}>
                    <Typography className={cls.notFoundTxt}>NO VEHICLES FOUND</Typography>
                  </div>
                );
            }}
          </Query>
        )}
      </Container>
    </div>
  );
}

//////////////////////////////////////// STYLES ////////////////////////////////////////
const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  row: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: '#ffffff',
    boxShadow: 'none',
    '&:hover': {
      background: '#eee',
    },
    transition: '0.1s',
    cursor: 'pointer',
  },
  head: {
    marginBottom: theme.spacing(3),
    lineHeight: 1,
    fontSize: '24px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '21px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
  },
  icon: {
    marginTop: theme.spacing(0.5),
    color: theme.palette.text.secondary,
    cursor: 'pointer',
  },
  heart: {
    marginTop: theme.spacing(0.5),
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.error.main,
    },
    transition: '0.2s',
    cursor: 'pointer',
  },
  heartActive: {
    marginTop: theme.spacing(0.5),
    color: theme.palette.error.main,
    '&:hover': {
      color: theme.palette.error.light,
    },
    transition: '0.2s',
    cursor: 'pointer',
  },
  notFound: {
    padding: theme.spacing(4),
    border: `1px solid ${theme.palette.border}`,
    borderRadius: '8px',
    marginLeft: 'auto',
    marginRight: 'auto',
    background: '#fff',
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  restricted: {
    width: '100%',
    paddingTop: theme.spacing(4),
    textAlign: 'center',
  },
  restrictedText: {
    lineHeight: 1.333,
    fontSize: 18,
    fontWeight: 400,
  },
  restrictedButton: {
    marginTop: theme.spacing(3),
    color: '#fff',
    boxShadow: 'none',
    '&:hover, &:active': {
      boxShadow: 'none',
    },
    transition: '0.2s',
  },
}));

//////////////////////////////////////// GRAPHQL ////////////////////////////////////////
// ADD THIS TO WHERE CLAUSE ONCE WE GET CUSTOMER CONFIG --- $isConcierge: Boolean! / is_concierge: { _eq: $isConcierge }
const GET_APPRAISAL_ROWS = gql`
  query get_appraisal_rows($customerId: bigint!, $start: timestamptz!, $end: timestamptz!) {
    appraisal_vehicles(
      where: {
        customer_id: { _eq: $customerId }
        _or: [
          { move: { pickup_time: { _gte: $start, _lte: $end } } }
          {
            _and: [{ move: { ready_by: { _gte: $start, _lte: $end } } }, { move: { pickup_time: { _is_null: true } } }]
          }
        ]
      }
    ) {
      vin
      customer_id
      appraisal {
        id
        status
        favorite
        last_save_time_utc
        last_fetch_time_utc
      }
      move {
        id
        status
        pickup_time
        ready_by
        reference_num
        vehicle_year
        vehicle_make
        vehicle_model
        vehicle_color
        vehicle_odometer
        consumer_pickup
        consumer_name
        consumer_phone
        pickup_workflow_data
        delivery_workflow_data
      }
    }
  }
`;

const GET_CUSTOMER_CONFIG = gql`
  query get_customer_config($id: bigint!) {
    customers(where: { id: { _eq: $id } }) {
      id
      name
      config
    }
  }
`;

const INSERT_APPRAISALS = gql`
  mutation insert_appraisals($appraisals: [appraisals_insert_input!]!) {
    insert_appraisals(objects: $appraisals) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

const FAVORITE_APPRAISAL = gql`
  mutation favorite_appraisal($id: bigint!, $favorite: Boolean!) {
    update_appraisals(where: { id: { _eq: $id } }, _set: { favorite: $favorite }) {
      affected_rows
      returning {
        id
        favorite
      }
    }
  }
`;

//////////////////////////////////////// EXPORT ////////////////////////////////////////
export default withRouter(Appraisals);
