import React from 'react';
import { withRouter } from 'react-router';
import StandardTile from './StandardTile';
import ConsumerTile from './ConsumerTile';
import LoanerTile from './LoanerTile';

function DashboardTile(props) {
  const { move } = props;

  if (move) {
    if (
      move.consumer_type === `customer` &&
      move.movesByReturnRideId &&
      move.movesByReturnRideId.length > 0 &&
      move.movesByReturnRideId[0].consumer_type === `loaner`
    ) {
      return <ConsumerTile move={move} loaner={move.moveByReturnRideId} />;
    } else if (move.consumer_type === `customer`) {
      return <ConsumerTile move={move} />;
    } else if (move.consumer_type === `loaner`) {
      return <LoanerTile move={move} />;
    } else {
      return <StandardTile move={move} />;
    }
  } else return <></>;
}

export default withRouter(DashboardTile);
