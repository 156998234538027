import React, { useState, useEffect } from 'react';
import { makeStyles, TextField, MenuItem } from '@material-ui/core';
import { Modal, ModalHeader, ModalContent } from '../../reusable/Modal';
import MethodiFrame from './MethodiFrame';

////////// COMPONENT //////////
export default function MethodAddEditModal(props) {
  const cls = useStyles();

  const { open, onClose, methodInput, primaryExists } = props;

  const [name, setName] = useState(``);
  const [type, setType] = useState(``);

  useEffect(() => {
    if (methodInput && methodInput.name) setName(methodInput.name);
    if (methodInput && methodInput.type) setType(methodInput.type);
  }, [methodInput])

  const handleInputChange = setHandler => event => {
    if (setHandler && event) setHandler(event.target.value);
  }

  const handleClose = () => {
    if (onClose) onClose();
    setName(``);
    setType(``);
  }

  return (<>
    <Modal open={open}>

      <ModalHeader handleClose={handleClose}>{methodInput && methodInput.id !== null ? `Edit` : `Add`} Payment Method</ModalHeader>

      <ModalContent subtitle={`By clicking "Continue", your payment method will be saved and secured. Note: Sensitive information and private account details are never stored in\xa0our\xa0database.`}>
        <TextField
          fullWidth
          required
          label="Payment Name"
          placeholder="Enter a custom name for this payment..."
          variant="outlined"
          margin="none"
          value={name}
          onChange={handleInputChange(setName)}
        />
        {name.length > 0 ? <>
          <div className={cls.break} />
          <TextField
            fullWidth
            required
            select
            label="Payment Type"
            placeholder="Select a payment type..."
            variant="outlined"
            margin="none"
            value={type}
            onChange={handleInputChange(setType)}
          >
            <MenuItem key={"Select Payment Type"} value={``}><em>Select Payment Type</em></MenuItem>
            <MenuItem key={"Credit Card"} value={`card`}>Credit Card</MenuItem>
            <MenuItem key={"ACH Check"} value={`ach`}>ACH Check</MenuItem>
            <MenuItem key={"Electronic Check"} value={`echeck`}>Electronic Check</MenuItem>
          </TextField>
          {type !== `` ? <>
            <div className={cls.break} />
            <MethodiFrame id={methodInput ? methodInput.id : null} name={name} type={type} primaryExists={primaryExists} handleClose={handleClose} />
          </> : null}
        </> : null}
      </ModalContent>

    </Modal>
  </>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  break: {
    width: '100%',
    height: theme.spacing(1.5),
  },
  bigBreak: {
    width: '100%',
    height: theme.spacing(2.5),
  },
}));