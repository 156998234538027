//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import dayjs from 'dayjs';
import { makeStyles, Grid, Typography, Tooltip } from '@material-ui/core';
import { useTools } from '../../hooks/useTools';

import DriverTracker from '../../reusable/DriverTracker';

//////////////////////// COMPONENT ////////////////////////

export default function MoveTracking(props) {
  const cls = useStyles();
  const { getPickupOrDeliveryTimeFromMove, getMinutesBetween } = useTools();

  const { move } = props;

  const formatPickupStarted = dayjs(move.pickup_started).format(`h:mm A`);
  const formatPickupArrived = dayjs(move.pickup_arrived).format(`h:mm A`);
  const formatPickupSuccessful = dayjs(move.pickup_successful).format(`h:mm A`);
  const formatDeliveryStarted = dayjs(move.delivery_started).format(`h:mm A`);
  const formatDeliveryArrived = dayjs(move.delivery_arrived).format(`h:mm A`);
  const formatDeliverySuccessful = dayjs(move.delivery_successful).format(`h:mm A`);

  const pickupDrive = getMinutesBetween(move.pickup_started, move.pickup_arrived);
  const pickupInspect = getMinutesBetween(move.pickup_arrived, move.pickup_successful);
  const pickupDwell = getMinutesBetween(move.pickup_successful, move.delivery_started);
  const deliveryDrive = getMinutesBetween(move.delivery_started, move.delivery_arrived);
  const deliveryInspect = getMinutesBetween(move.delivery_arrived, move.delivery_successful);
  const totalTime = pickupDrive + pickupInspect + pickupDwell + deliveryDrive + deliveryInspect;

  const useBarStyles = makeStyles(theme => ({
    p1: {
      verticalAlign: 'top',
      position: 'relative',
      display: 'inline-block',
      width: `${totalTime && !isNaN(totalTime) ? (pickupDrive / totalTime) * 100 : 20}%`,
      height: theme.spacing(3),
      background: theme.palette.primary.main,
    },
    p2: {
      verticalAlign: 'top',
      position: 'relative',
      display: 'inline-block',
      width: `${totalTime && !isNaN(totalTime) ? (pickupInspect / totalTime) * 100 : 20}%`,
      height: theme.spacing(3),
      background: theme.palette.secondary.main,
    },
    p3: {
      verticalAlign: 'top',
      position: 'relative',
      display: 'inline-block',
      width: `${totalTime && !isNaN(totalTime) ? (pickupDwell / totalTime) * 100 : 20}%`,
      height: theme.spacing(3),
      background: theme.palette.secondary.light,
    },
    d1: {
      verticalAlign: 'top',
      position: 'relative',
      display: 'inline-block',
      width: `${totalTime && !isNaN(totalTime) ? (deliveryDrive / totalTime) * 100 : 20}%`,
      height: theme.spacing(3),
      background: theme.palette.primary.main,
    },
    d2: {
      verticalAlign: 'top',
      position: 'relative',
      display: 'inline-block',
      width: `${totalTime && !isNaN(totalTime) ? (deliveryInspect / totalTime) * 100 : 20}%`,
      height: theme.spacing(3),
      background: theme.palette.secondary.main,
    },
    txt: {
      marginTop: '6px',
      color: theme.palette.text.contrast,
      lineHeight: 1,
      textAlign: 'center',
      fontSize: 14,
      fontWeight: 600,
      [theme.breakpoints.down('sm')]: {
        fontSize: 13,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
      cursor: 'default',
    },
  }));
  const barCls = useBarStyles();

  return (
    <>
      <Grid container spacing={2} alignItems='center'>
        <Grid item>
          <Tooltip title='Move Start Time'>
            <Typography className={cls.time}>{getPickupOrDeliveryTimeFromMove(`pickup`, move, `h:mm A z`)}</Typography>
          </Tooltip>
        </Grid>
        <Grid item xs>
          {pickupDrive || totalTime === 0 ? (
            <Tooltip
              title={`Pickup Drive - ${
                pickupDrive ? `${pickupDrive} minutes (${formatPickupStarted} to ${formatPickupArrived})` : `0m`
              }`}
            >
              <div className={barCls.p1}>
                <Typography className={barCls.txt}>{pickupDrive ? `${pickupDrive}m` : `0m`}</Typography>
              </div>
            </Tooltip>
          ) : null}

          {pickupInspect || totalTime === 0 ? (
            <Tooltip
              title={`Pickup Inspection - ${
                pickupInspect ? `${pickupInspect} minutes (${formatPickupArrived} to ${formatPickupSuccessful})` : `0m`
              }`}
            >
              <div className={barCls.p2}>
                <Typography className={barCls.txt}>{pickupInspect ? `${pickupInspect}m` : `0m`}</Typography>
              </div>
            </Tooltip>
          ) : null}

          {pickupDwell || totalTime === 0 ? (
            <Tooltip
              title={`Pickup Dwelling - ${
                pickupDwell ? `${pickupDwell} minutes (${formatPickupSuccessful} to ${formatDeliveryStarted})` : `0m`
              }`}
            >
              <div className={barCls.p3}>
                <Typography className={barCls.txt}>{pickupDwell ? `${pickupDwell}m` : `0m`}</Typography>
              </div>
            </Tooltip>
          ) : null}

          {deliveryDrive || totalTime === 0 ? (
            <Tooltip
              title={`Delivery Drive - ${
                deliveryDrive ? `${deliveryDrive} minutes (${formatDeliveryStarted} to ${formatDeliveryArrived})` : `0m`
              }`}
            >
              <div className={barCls.d1}>
                <Typography className={barCls.txt}>{deliveryDrive ? `${deliveryDrive}m` : `0m`}</Typography>
              </div>
            </Tooltip>
          ) : null}

          {deliveryInspect || totalTime === 0 ? (
            <Tooltip
              title={`Delivery Inspection - ${
                deliveryInspect
                  ? `${deliveryInspect} minutes (${formatDeliveryArrived} to ${formatDeliverySuccessful})`
                  : `0m`
              }`}
            >
              <div className={barCls.d2}>
                <Typography className={barCls.txt}>{deliveryInspect ? `${deliveryInspect}m` : `0m`}</Typography>
              </div>
            </Tooltip>
          ) : null}
        </Grid>
        <Grid item>
          <Tooltip title='Move Completion Time'>
            <Typography className={cls.time}>
              {getPickupOrDeliveryTimeFromMove(`delivery`, move, `h:mm A z`)}
            </Typography>
          </Tooltip>
        </Grid>
      </Grid>

      {/* Render map when the move is in progress */}
      {!move.cancel_status && move.status && (move.status.includes(`pickup`) || move.status.includes(`delivery`)) ? (
        <>
          <div className={cls.break} />
          <DriverTracker move={move} />
        </>
      ) : null}
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  time: {
    whiteSpace: 'nowrap',
    marginTop: '2.5px',
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      marginTop: '3px',
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '3.5px',
      fontSize: 14,
    },
    cursor: 'default',
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
}));
