import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Typography, Badge } from '@material-ui/core';
// import { DateTime } from 'luxon';

export default function OverviewHeader(props) {
  const cls = useStyles();
  const { customers, date, setDate } = props;

  //Changes query variables to get moves from a different day
  const handleDateChange = name => event => {
    const str = JSON.stringify(event);
    const dateString = str.substring(1, str.length - 1);
    // console.log('DatePicked', event, typeof event, JSON.stringify(event), dateString);
    localStorage.setItem('overview-date', dateString);
    setDate(dateString);
  };

  const uncanceledMoveCount = moves => {
    /*
    This isNotCanceled func is a safe-gaurd incase a move's cancel_status is in a "canceled" status
    but the move's status has not been updated to "canceled" 
    this should never happen but I saw a few cases in the prod db that were in this in-between state
    */
    let isNotCanceled = move => {
      if (
        move.cancel_status &&
        (move.cancel_status === 'canceled' || move.cancel_status === 'started')
      ) {
        return false;
      } else {
        return true;
      }
    };
    let uncanceledMoves = moves.filter(move => move.status !== 'canceled' && isNotCanceled(move));
    return uncanceledMoves.length;
  };

  return (
    <div className={cls.headerRow}>
      <div className={cls.calendarCol}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            className={cls.datePicker}
            size='small'
            autoOk
            orientation='portrait'
            format='MM/dd/yyyy'
            label='Select Date'
            margin='none'
            inputVariant='outlined'
            value={date}
            onChange={handleDateChange(`date`)}
            InputLabelProps={{
              // className: cls.multilineColor,
              shrink: true,
            }}
            InputProps={
              {
                // className: cls.multilineColor,
              }
            }
          />
        </MuiPickersUtilsProvider>
      </div>
      {customers.map(customer => {
        return (
          <div key={'day' + customer.id} className={cls.customerCol}>
            <Badge badgeContent={uncanceledMoveCount(customer.moves)} color='primary'>
              <Typography className={cls.customerText}>{customer.name}</Typography>
            </Badge>
          </div>
        );
      })}
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  headerRow: {
    position: 'sticky',
    height: '100%',
    top: '0',
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
    // color: theme.palette.text.contrast,
    opacity: 1,
    zIndex: 3,
  },
  calendarCol: {
    position: 'sticky',
    left: '0',
    flex: '0, 1, 25em',
    padding: '20px',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 4,
    background: 'inherit',
  },
  datePicker: {
    width: '120px',
    color: theme.palette.text.contrast,
  },
  customerCol: {
    flex: 1,
    wordBreak: 'normal',
    padding: '15px',
    placeSelf: 'center',
    // margin: '10px',
    textAlign: 'center',
    minWidth: '164px',
    justifyContent: 'space-between',
  },
  multilineColor: {
    color: theme.palette.text.contrast,
  },
  customerText: {
    lineHeight: '1.25',
  },
}));
