//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

import IconBack from './IconBack';
import IconRefetch from './IconRefetch';
import IconAction from './IconAction';

//////////////////////// COMPONENT ////////////////////////

export default function Toolbar({
  children,
  fullscreen = false,
  back = false,
  title = null,
  refetch = null,
  refetchTooltip = null,
  actions = null,
  actionsTooltip = null,
}) {
  const cls = useStyles();

  return (
    <>
      <div className={fullscreen ? cls.fullBar : cls.floatingBar}>
        <Grid container spacing={2} alignItems='center'>
          {back ? (
            <Grid item>
              <IconBack className={cls.backBtn} tooltipPlacement={fullscreen ? `bottom` : `top`} />
            </Grid>
          ) : null}

          <Grid item>
            {!back && fullscreen ? <Spacer column size='xs' /> : null}
            <Typography className={cls.title}>{title || `Toolbar`}</Typography>
            {fullscreen ? <Spacer column size='sm' /> : null}
          </Grid>

          <Grid item xs>
            {children}
          </Grid>

          {refetch || (actions && actions.length > 0) ? (
            <Grid item>
              {refetch ? (
                <IconRefetch
                  refetch={refetch}
                  tooltip={refetchTooltip}
                  tooltipPlacement={fullscreen ? `bottom` : `top`}
                />
              ) : null}

              {refetch && actions && actions.length > 0 ? <Spacer column size='xs' /> : null}

              {actions && actions.length > 0 ? (
                <IconAction
                  actions={actions}
                  tooltip={actionsTooltip}
                  tooltipPlacement={fullscreen ? `bottom` : `top`}
                />
              ) : null}
            </Grid>
          ) : null}
        </Grid>
      </div>
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  floatingBar: {
    zIndex: 10,
    top: 0,
  },
  fullBar: {
    zIndex: 10,
    position: 'sticky',
    top: 0,
    padding: theme.spacing(2),
    paddingRight: theme.spacing(3.5),
    borderBottom: theme.border[0],
    background: theme.palette.background.paper,
  },
  title: {
    display: 'inline',
    verticalAlign: 'top',
    lineHeight: 1,
    fontSize: 24,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 21,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  backBtn: {
    marginRight: theme.spacing(-1),
  },
}));
