//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Chip, Icon, Typography } from '@material-ui/core';
import { Table, Column } from '../../reusable/TableComponents';
import dayjs from 'dayjs'

import { useTools } from '../../hooks/useTools';

//////////////////////// COMPONENT ////////////////////////

export default function UsersTable({ users, search }) {
  const cls = useStyles();
  const { goToUserDetails, getInitialsFromName } = useTools();

  // Set filtered users based on search results
  const [filteredUsers, setFilteredUsers] = React.useState(users);

  const formatRole = (role) => {
    if (role === 'dealer') return 'User';
    const words = role.split('-');
    const formattedWords = words.map((word, index) => {
      if (index === 0) return ''; 
      return word.charAt(0).toUpperCase() + word.slice(1);
    });

    return formattedWords.join(' ').trim();
  }
  
  React.useEffect(() => {
    let newFilteredUsers = users;

    if (search && search.length > 0 && newFilteredUsers && newFilteredUsers.length > 0) {
      const searchInput = search.toLowerCase();
      newFilteredUsers = newFilteredUsers.filter(u => {
        return (
          `${u.id}`.includes(searchInput) ||
          u.display_name.toLocaleLowerCase().includes(searchInput) ||
          u.email.toLocaleLowerCase().includes(searchInput) ||
          u.default_role.toLocaleLowerCase().includes(searchInput) ||
          u.customer.name.toLocaleLowerCase().includes(searchInput)
        );
      });
    }

    setFilteredUsers(newFilteredUsers);
    // eslint-disable-next-line
  }, [users, search]);

  const rowActions = [
    {
      label: `Go To User Details`,
      handler: user => goToUserDetails(user.id ? user.id : null),
      disabled: false,
      hide: false,
    },
  ];

  const getFormattedDate = (date) => {
    let formattedDate = dayjs(date).format('M/DD/YYYY')
    let formattedTime = dayjs(date).format('h:mm a')
    return `${formattedDate} ${formattedTime}`
  }


  return (
    <div className={cls.paper}>
      <Table
        data={filteredUsers}
        onRowClick={user => goToUserDetails(user.id)}
        rowActions={rowActions}
        defaultOrderBy={'name'}
        defaultOrder={'asc'}
        tableAriaLabel={`users`}
        stickyHeader={true}
      >
        {/*<Column*/}
        {/*  name={`id`}*/}
        {/*  value={customer => customer.id}*/}
        {/*  label={`ID`}*/}
        {/*  tooltip={`ID of the user`}*/}
        {/*  style={{ minWidth: 20 }}*/}
        {/*/>*/}
        {/*<Column*/}
        {/*  name= {`avatar_img`}*/}
        {/*  value= {user => user.display_name}*/}
        {/*  renderer= {user =>*/}
        {/*    user.avatar_url ? (*/}
        {/*        <div className={cls.avatar}>*/}
        {/*            <img className={cls.avatarImg} src={user.avatar_url} alt='avatar' />*/}
        {/*        </div>*/}
        {/*    ) : (*/}
        {/*        <div className={cls.avatar}>*/}
        {/*            <div className={cls.avatarImg}>*/}
        {/*                <Typography className={cls.initialsTxt}>*/}
        {/*                    {getInitialsFromName({ user_name: user.display_name || null })}*/}
        {/*                </Typography>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    )*/}
        {/*  }*/}
        {/*label= {`Avatar`}*/}
        {/*tooltip= {`Image of the driver`}*/}
        {/*/>*/}
        <Column
          name={`name`}
          value={user => user.display_name}
          label={`Name`}
          tooltip={`Name of the user`}
          style={{ minWidth: 40 }}
        />
        <Column
          name={`email`}
          value={user => user.email}
          label={`Email`}
          tooltip={`Email of the user`}
          style={{ minWidth: 80 }}
        />
        <Column
          name={`default rooftop`}
          value={user => user && user.customer && user.customer.name }
          label={`Default Rooftop`}
          tooltip={`Name of the user's primary customer/rooftop`}
          style={{ minWidth: 20 }}
        />
        <Column
          name={`role`}
          value={user => formatRole(user.default_role)}
          label={`Role`}
          tooltip={`Default role of the user`}
          style={{ minWidth: 80 }}
        />
        <Column
          name={`createdat`}
          value={user => getFormattedDate(user.created_at)}
          label={`Created`}
          tooltip={`When the user was created`}
          style={{ minWidth: 80 }}
        />
        <Column
          name={`updatedat`}
          value={user => getFormattedDate(user.updated_at)}
          label={`Updated`}
          tooltip={`When the user was last updated`}
          style={{ minWidth: 80 }}
        />
        <Column
          name={`status`}
          value={user => user.active}
          renderer={user => (
            <Chip
              variant={user.active ? 'default' : 'outlined'}
              icon={user.active ? null : <Icon fontSize='small'>close</Icon>}
              size='small'
              color={user.active ? 'primary' : 'default'}
              label={user.active ? 'Active' : 'Inactive'}
            />
          )}
          label={`Status`}
          align={`right`}
          tooltip={`Status of the user`}
          style={{ minWidth: 30 }}
        />
      </Table>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  paper: {
    background: theme.palette.background.paper,
  },
  initialsTxt: {
    position: 'absolute',
    top: '15%',
    left: 0,
    right: 0,
    color: theme.palette.text.contrast,
    textAlign: 'center',
    fontSize: 24,
    fontWeight: 700,
    cursor: 'default',
    userSelect: 'none',
  },
  avatarImg: {
    position: 'relative',
    objectFit: 'cover',
    width: 48,
    height: 48,
    borderRadius: '50%',
    background: theme.palette.fade[3],
  },
}));