//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import dayjs from 'dayjs';
import {
  useTheme,
  makeStyles,
  Checkbox,
  TableContainer,
  Table,
  TablePagination,
  Tooltip,
  Typography,
  Icon,
} from '@material-ui/core';
import { Button } from '@hopdrive/storybook';

import { useTools } from '../../hooks/useTools';

import { InvoiceTableHead, InvoiceTableBody } from './index';

const defaultOrder = `desc`;
const defaultOrderBy = `MOVE_ID`;

//////////////////////// COMPONENT ////////////////////////

export default function InvoiceTable({
  invoice,
  armoves,
  amount,
  checkedArmoves,
  setCheckedArmoves,
  handlePayNowModalOpen,
  handleDisputeModalOpen,
}) {
  const cls = useStyles();
  const theme = useTheme();
  const { capFirst, clampNegNum, getMonetaryValue } = useTools();

  const [order, setOrder] = React.useState(defaultOrder);
  const [orderBy, setOrderBy] = React.useState(defaultOrderBy);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  // Reset checkedArmoves when the invoice changes
  React.useEffect(() => {
    setCheckedArmoves([]);
  }, [invoice.id, setCheckedArmoves]);

  const payable = amount.due > 0 && invoice.status === `closed`;

  // See if the armove is checked based on ID found in the checkedArmoves array
  const isItemChecked = id => checkedArmoves.indexOf(id) !== -1;

  // Handle the row checkbox when clicked
  const handleCheckOne = (event, name) => {
    event.stopPropagation();
    const checkedIndex = checkedArmoves.indexOf(name);
    let newChecked = [];

    if (checkedIndex === -1) {
      newChecked = newChecked.concat(checkedArmoves, name);
    } else if (checkedIndex === 0) {
      newChecked = newChecked.concat(checkedArmoves.slice(1));
    } else if (checkedIndex === checkedArmoves.length - 1) {
      newChecked = newChecked.concat(checkedArmoves.slice(0, -1));
    } else if (checkedIndex > 0) {
      newChecked = newChecked.concat(checkedArmoves.slice(0, checkedIndex), checkedArmoves.slice(checkedIndex + 1));
    }
    setCheckedArmoves(newChecked);
  };

  // Handle the header checkbox when clicked
  const handleCheckAll = (event, rowArr) => {
    // Filter the rows and get the IDs
    const rowIdArr = rowArr.filter(row => row.status !== 'paid').map(row => row.id);

    // Check/Uncheck all based on items that are currently checked
    if (checkedArmoves.length > 0 && checkedArmoves.length === rowIdArr.length) setCheckedArmoves([]);
    else setCheckedArmoves(rowIdArr);
  };

  // Header checkbox component that checks/unchecks all unpaid moves if clicked. If all armoves are paid, it is disabled.
  const HeaderCheckbox = () => {
    const paidArmoves = armoves.filter(armove => armove.due_amount - armove.discount_amount <= armove.paid_amount);
    const unpaidArmoves = armoves.filter(armove => armove.due_amount - armove.discount_amount > armove.paid_amount);
    return (
      <Checkbox
        className={cls.checkbox}
        color='primary'
        onClick={event => handleCheckAll(event, armoves)}
        checked={checkedArmoves.length !== 0 && unpaidArmoves.length === checkedArmoves.length}
        indeterminate={paidArmoves.length !== 0 && paidArmoves.length === armoves.length}
        disabled={true}
        style={{display: 'none'}}
        // disabled={paidArmoves.length !== 0 && paidArmoves.length === armoves.length}
      />
    );
  };

  // Handle page state
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle pagination rows state
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Set headers array used in the table
  const headers = [
    { id: `CHECKBOX`, align: `left`, label: `Checkbox`, component: HeaderCheckbox() },
    { id: `MOVE_ID`, align: `left`, label: `Move\xa0ID` },
    { id: `REF_NUM`, align: `left`, label: `Ref\xa0#` },
    { id: `MOVE_DATE`, align: `left`, label: `Move\xa0Date` },
    { id: `LANE`, align: `left`, label: `Lane` },
    { id: `DLV_REP`, align: `left`, label: `Rep`, hide: invoice.customer.id !== 2 },
    { id: `DISTANCE`, align: `right`, label: `Distance` },
    { id: `WEIGHT_CLASS`, align: `right`, label: `Vehicle\xa0Weight\xa0Class` },
    { id: `COST`, align: `right`, label: `Cost` },
  ];

  // A cleaner way to build the row object for the table (stronger logic when it comes to error handling)
  const buildRowObject = (armove = null) => {
    // Initialize the row object
    let row = {};

    // Check for armove and set each row field
    if (armove) {
      const transportDetail = armove.details.find(detail => detail.name === `Transport`);
      row.armove = armove;
      row.CHECKBOX = (
        <Checkbox
          className={cls.checkbox}
          color='primary'
          onClick={event => handleCheckOne(event, armove.id)}
          checked={isItemChecked(armove.id)}
          disabled={true}
          style={{display: 'none'}}
          // disabled={armove.due_amount - armove.discount_amount <= armove.paid_amount}
          indeterminate={armove.due_amount - armove.discount_amount <= armove.paid_amount}
        />
      );

      if (armove.move) {
        row.MOVE_ID = armove.move.id || null;
        row.REF_NUM = armove.move.reference_num || null;

        if (armove.move.lane) {
          row.LANE = armove.move.lane.description || null;
          row.DISTANCE = armove.move.lane.distance_miles ? `${armove.move.lane.distance_miles} mi` : `0 mi`;
        }

        row.DLV_REP =
          armove.move.tags && armove.move.tags.includes('delivery reported') ? (
            <Icon fontSize='small' style={{ margin: 0 }}>
              backup
            </Icon>
          ) : null;
      }

      row.MOVE_DATE = armove.billable_datetime ? dayjs(armove.billable_datetime).utc().format(`MM/DD/YYYY`) : null;
      row.WEIGHT_CLASS = transportDetail && transportDetail.amount > 0 && transportDetail.rate_source ? transportDetail.rate_source : null;
      row.COST = (
        <>
          {armove.disputed || armove.discount_amount > 0 ? (
            <Tooltip
              placement='top'
              title={
                armove.disputed
                  ? `Record is disputed - ${capFirst(armove.dispute_reason)}`
                  : `Record is discounted for $${armove.discount_amount.toFixed(2)} - ${capFirst(
                      armove.discount_reason
                    )}`
              }
            >
              <Icon className={cls.rowIcon} fontSize='small'>
                {armove.disputed ? `announcement` : `local_offer`}
              </Icon>
            </Tooltip>
          ) : null}
          {armove.due_amount - armove.discount_amount <= armove.paid_amount ? (
            <Tooltip placement='top' title={`Paid - ${getMonetaryValue(armove.paid_amount)}`}>
              <Icon className={cls.rowIcon} style={{ color: theme.palette.success.main }} fontSize='small'>
                check_circle
              </Icon>
            </Tooltip>
          ) : armove.paid_amount > 0 ? (
            <Tooltip placement='top' title={`Partially Paid - ${getMonetaryValue(armove.paid_amount)}`}>
              <Icon className={cls.rowIcon} style={{ color: theme.palette.warning.main }} fontSize='small'>
                contrast
              </Icon>
            </Tooltip>
          ) : null}
          <Typography className={cls.rowTxt}>
            {armove.due_amount > 0
              ? `${getMonetaryValue(clampNegNum(armove.due_amount - armove.discount_amount))}`
              : armove.due_amount === 0
              ? getMonetaryValue(0)
              : `-`}
          </Typography>
        </>
      );
    }
    return row;
  };

  // Set rows array with the armove object attached to each row
  const rows = armoves.map((armove, i) => {
    return buildRowObject(armove);
  });

  return (
    <div className={cls.paper}>
      <div className={cls.title}>
        <Typography className={cls.titleTxt}>Moves List</Typography>
      </div>

      <Button
        disabled={!payable}
        className={cls.payBtn}
        color='primary'
        variant='outlined'
        onClick={() => handlePayNowModalOpen(`pay-now`, { selectedInvoice: invoice, ...amount })}
      >
        Pay Now
      </Button>

      <TableContainer>
        <Table size='small' aria-label='invoice-table'>
          <InvoiceTableHead
            headers={headers}
            order={order}
            orderBy={orderBy}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
          />
          <InvoiceTableBody
            rows={rows}
            order={order}
            orderBy={orderBy}
            page={page}
            rowsPerPage={rowsPerPage}
            handleDisputeModalOpen={handleDisputeModalOpen}
          />
        </Table>
      </TableContainer>

      <div className={cls.pagination}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component='div'
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  title: {
    width: '100%',
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  titleTxt: {
    lineHeight: 1,
    fontSize: 21,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
    cursor: 'default',
  },
  payBtn: {
    position: 'absolute',
    top: 12,
    right: 12,
  },
  checkbox: {
    padding: theme.spacing(0.25),
  },
  rowTxt: {
    display: 'inline',
    color: 'inherit',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      lineHeight: '14px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
      lineHeight: '12px',
    },
  },
  rowIcon: {
    display: 'inline',
    verticalAlign: '-25%',
    marginRight: theme.spacing(0.5),
    fontSize: 21,
    color: '#inherit',
    cursor: 'pointer',
  },
}));
