//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

import { useTools } from '../../hooks/useTools';
import { useInvoices } from './useInvoices';

//////////////////////// COMPONENT ////////////////////////

export default function InvoiceCalculations({ invoice }) {
  const cls = useStyles();
  const { getMonetaryValue } = useTools();
  const { buildAmountObject } = useInvoices();

  const amount = buildAmountObject(invoice);

  return (
    <div className={cls.paper}>
      <Typography className={cls.titleTxt}>Calculated Totals</Typography>
      <Typography className={cls.subtitleTxt}>*Includes processing fee</Typography>

      <Spacer size='sm' />

      {amount.discounted || amount.disputed ? (
        <>
          <Typography display='inline' className={cls.key}>
            Subtotal&nbsp;
          </Typography>
          <Typography display='inline' className={cls.val}>
            {getMonetaryValue(amount.subtotal)}
          </Typography>
          <br />
          <Typography display='inline' className={cls.key}>
            Processing Fee:&nbsp;
          </Typography>
          <Typography display='inline' className={cls.val}>
            ({getMonetaryValue(amount.processingFee)})
          </Typography>
          <br />
          {amount.discounted ? (
            <>
              <Typography display='inline' className={cls.key}>
                Discounted&nbsp;
              </Typography>
              <Typography display='inline' className={cls.val}>
                ({getMonetaryValue(amount.discounted)})
              </Typography>
              <br />
            </>
          ) : null}
          {amount.disputed ? (
            <>
              <Typography display='inline' className={cls.key}>
                Disputed&nbsp;
              </Typography>
              <Typography display='inline' className={cls.val}>
                ({getMonetaryValue(amount.disputed)})
              </Typography>
              <br />
            </>
          ) : null}
          <div className={cls.lineBreak} />
        </>
      ) : null}

      <Typography display='inline' className={cls.keyDemi}>
        Move&nbsp;Total&nbsp;
      </Typography>
      <Typography display='inline' className={cls.valDemi}>
        {getMonetaryValue(amount.moveDueTotal)}
      </Typography>
      <br />
      {amount.productTotal > 0 ? (
        <>
          <Typography display='inline' className={cls.keyDemi}>
            Product&nbsp;Total&nbsp;
          </Typography>
          <Typography display='inline' className={cls.valDemi}>
            {getMonetaryValue(amount.productTotal)}
          </Typography>
          <br />
        </>
      ) : (
        <></>
      )}
      {/* {amount.processingFee > 0 ? (
        <> */}
      <Typography display='inline' className={cls.keyDemi}>
        Processing&nbsp;Fee&nbsp;
      </Typography>
      <Typography display='inline' className={cls.valDemi}>
        {getMonetaryValue(amount.processingFee)}
      </Typography>
      <br />
      {/* </>
      ) : (
        <></>
      )} */}
      {/* <div className={cls.lineBreak} />
      <Typography display='inline' className={cls.keyDemi}>
        Total&nbsp;
      </Typography>
      <Typography display='inline' className={cls.valDemi}>
        {getMonetaryValue(amount.totalSansProcessingFee)}
      </Typography> */}

      <div className={cls.lineBreak} />

      <Typography display='inline' className={cls.keyBold}>
        Amount&nbsp;Paid&nbsp;
      </Typography>
      <Typography display='inline' className={cls.valBold}>
        {getMonetaryValue(amount.paymentAmount)}
      </Typography>
      <br />
      {parseInt(amount.balanceRemaining) > 0 ? (
        <>
          <Typography>
            <Typography display='inline' className={cls.keyBold}>
              Prepaid&nbsp;Move&nbsp;Balance
            </Typography>
            <Typography display='inline' className={cls.valBold}>
              {getMonetaryValue(amount.balanceRemaining)}
            </Typography>
          </Typography>
        </>
      ) : (
        <Typography>
          <Typography display='inline' className={cls.keyBold}>
            Amount&nbsp;Due&nbsp;
          </Typography>
          <Typography display='inline' className={cls.valBold}>
            {getMonetaryValue(amount.due)}
          </Typography>
        </Typography>
      )}
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  titleTxt: {
    lineHeight: 1,
    fontSize: 21,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
    cursor: 'default',
  },
  subtitleTxt: {
    lineHeight: 1.25,
    fontSize: 10,
    fontWeight: 400,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('sm')]: {
      fontSize: 9,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 8,
    },
    cursor: 'default',
  },
  key: {
    color: theme.palette.text.disabled,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  val: {
    float: 'right',
    color: theme.palette.text.disabled,
    fontSize: 14,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  keyDemi: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  valDemi: {
    float: 'right',
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  keyBold: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  valBold: {
    float: 'right',
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: '6px',
    marginBottom: '8px',
    background: theme.palette.dividerStrong,
  },
}));
