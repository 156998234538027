import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import DashboardTile from './DashboardTile';
import BoldDivide from '../../reusable/BoldDivide';

////////// COMPONENT //////////
export default function DashboardGridByRooftop(props) {
  const cls = useStyles();

  const { moves } = props;

  const groupByCustomerId = movesArray => {
    const groupArray = [];
    const customerArray = movesArray.map(move => move.customer);
    //Sets can only contain unique values, so this will remove duplicates
    const uniqueCustomerArray = [...new Set(customerArray)];
    //Create a new array of objects that contain the name, id, and moves for each customer
    uniqueCustomerArray.forEach(customer => {
      if (!customer || !customer.id || !customer.name) return;
      const customerMovesArray = movesArray.filter(move => move && move.customer && move.customer.id === customer.id);
      const customerObj = { id: customer.id, name: customer.name, moves: customerMovesArray };
      groupArray.push(customerObj);
    });
    return groupArray;
  };

  return (
    <>
      {groupByCustomerId(moves).map(customer => (
        <React.Fragment key={`cgg-${customer.id}`}>
          {customer && customer.name && (
            <>
              <BoldDivide spacer color='dark'>
                {customer.name + ' moves'}
              </BoldDivide>
              <Grid container justifyContent='center' alignItems='flex-start' spacing={2}>
                {customer.moves.map(move => (
                  <Grid key={`cggm-${move.id}`} item xs className={cls.tile}>
                    <DashboardTile move={move} />
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </React.Fragment>
      ))}
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  tile: {
    position: 'relative',
    minWidth: '480px',
    maxWidth: '540px',
    [theme.breakpoints.down('md')]: {
      minWidth: '360px',
      maxWidth: '420px',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
      maxWidth: '100%',
    },
  },
}));
