import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import DashboardTile from './DashboardTile';
import Divide from '../../reusable/Divide';

////////// COMPONENT //////////
export default function DashboardGridByHangtag(props) {
  const cls = useStyles();

  const { moves } = props;

  const movesWithHangtags = moves.filter(move => move.hangtags && move.hangtags.length);

  return (
    <>
      {movesWithHangtags.length ? (
        <>
          <Divide spacer tip={`Track moves with hangtags.`}>
            Moves With Hangtags
          </Divide>

          <Grid container justifyContent='center' alignItems='flex-start' spacing={2}>
            {movesWithHangtags.map(move => (
              <Grid key={move.id} item xs className={cls.tile}>
                <DashboardTile move={move} />
              </Grid>
            ))}
          </Grid>
        </>
      ) : null}
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  tile: {
    position: 'relative',
    minWidth: '480px',
    maxWidth: '540px',
    [theme.breakpoints.down('md')]: {
      minWidth: '360px',
      maxWidth: '420px',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
      maxWidth: '100%',
    },
  },
}));
