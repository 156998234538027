//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { TableBody } from '@material-ui/core';
import { getComparator, stableSort } from '../../utils/tableSort';

import { UploadedTableRow } from './index';

//////////////////////// COMPONENT ////////////////////////

export default function UploadedTableBody({ rows, errors, order, orderBy, page, rowsPerPage }) {
  const getErrors = index => {
    const rowErrors = errors.filter(err => err.index === index);
    return rowErrors;
  };

  return (
    <TableBody>
      {stableSort(rows, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, i) => {
          return (
            <React.Fragment key={`uploaded-row-${i}`}>
              <UploadedTableRow row={row} errors={getErrors(i)} index={i} />
            </React.Fragment>
          );
        })}
    </TableBody>
  );
}
