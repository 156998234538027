import gql from 'graphql-tag';

const INSERT_BATCH_JOBS = gql`
  mutation dealer_csvImport_insertBatchJobs($jobs: [batch_jobs_insert_input!]!) {
    insert_batch_jobs(objects: $jobs) {
      affected_rows
      returning {
        id
        batch_id
        input
        sequence
        status
        trigger_type
        createdat
        createdby
      }
    }
  }
`;

const SUBSCRIBE_TO_BATCH_JOBS = gql`
  subscription dealer_csvImport_subscribeToBatchJobs($batchId: uuid) {
    batch_jobs(where: { batch_id: { _eq: $batchId } }, order_by: { sequence: asc_nulls_last }) {
      id
      output
      status
      updatedat
      updatedby
    }
  }
`;

export { INSERT_BATCH_JOBS, SUBSCRIBE_TO_BATCH_JOBS };
