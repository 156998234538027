import React, { useEffect, useState } from 'react';
import { makeStyles, Grid, Typography, TextField } from '@material-ui/core';
import moment from 'moment';

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  tip: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
}));

////////// COMPONENT //////////
export default function DatetimeSelect(props) {
  const cls = useStyles();

  const { timeData, onChange, tip, disabled } = props;

  const initialValues = {
    date: moment.utc(timeData, 'yyyy-MM-DDD').local().format('yyyy-MM-DD'),
    time: moment.utc(timeData, 'HH:mm').local().format('HH:mm'),
  };

  const [dateTime, setDateTime] = useState(initialValues);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');

  useEffect(() => {
    const initialValues = {
      date: moment.utc(timeData, 'yyyy-MM-DDD').local().format('yyyy-MM-DD'),
      time: moment.utc(timeData, 'HH:mm').local().format('HH:mm'),
    };
    setDateTime(initialValues);
  }, [timeData]);

  const handleChange = event => {
    const { name, value } = event.target;

    const newDateTime = {
      ...dateTime,
      [name]: value,
    };
    const combineDate = moment(newDateTime.date + ' ' + newDateTime.time);
    const now = moment();

    if (combineDate >= now) {
      // Check if pickup time is within 90 minutes of current time
      if (combineDate.diff(now, `minutes`) < 90) {
        setErrorText(`Pickup time must be at least 90 minutes ahead of the current time!`);
        setError(true);
      } else {
        setErrorText(``);
        setError(false);
      }
    } else {
      setErrorText(`Cannot set pickup time in the past!`);
      setError(true);
    }

    setDateTime(newDateTime);
    if (onChange) onChange(combineDate);
  };

  return (
    <>
      {tip ? (
        <>
          <Typography className={cls.tip}>{tip}</Typography>
          <div className={cls.break} />
        </>
      ) : null}

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            required
            name='date'
            type='date'
            value={dateTime.date}
            fullWidth
            variant='outlined'
            size='small'
            onChange={handleChange}
            label='Pickup Date'
            disabled={disabled}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ 'aria-label': 'pickup-date' }}
            error={props.validation && props.validation.pdate ? !props.validation.pdate : false}
            helperText={
              errorText
                ? errorText
                : props.validation && !props.validation.ptime
                ? 'Pickup time cannot be in the past!'
                : null
            }
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            required
            name='time'
            type='time'
            fullWidth
            variant='outlined'
            onChange={handleChange}
            value={dateTime.time}
            size='small'
            label='Pickup Time'
            disabled={disabled}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ 'aria-label': 'pickup-time' }}
            error={error ? true : props.validation && props.validation.ptime ? !props.validation.ptime : false}
            helperText={
              errorText
                ? errorText
                : props.validation && !props.validation.ptime
                ? 'Pickup time cannot be in the past!'
                : null
            }
          />
        </Grid>
      </Grid>
    </>
  );
}
