import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { useData } from "../../DataProvider";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import * as Sentry from "@sentry/react";
import { getUserName, getUserEmail, getUserAvatar, getUserRole } from '../utils/authHelper'
import { ReactComponent as IconHD } from '../../static/bunny.svg';

const styles = theme => ({
  root: {
    display: 'flex',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  card: {
    maxWidth: 500,
  },
  media: {
    objectFit: "cover",
  },
  inlineTxt: {
    display: "inline-flex",
    marginLeft: "10px",
  },
    iconHd: {
    objectFit: 'cover',
    width: 250,
    height: 'auto',
    margin: 10,
    color: 'lightgray'
  },
});

function ProfilePage(props) {
  const ctx = useData();
  const { classes } = props;

  const [role, setRole] = React.useState(null)

  const fetchUserRole = async () => {
    let userRole = await getUserRole()

    switch (userRole) {
      case 'dealer-super-admin':
        setRole('Super Admin')
        break;
      case 'dealer-admin':
        setRole('Admin');
        break;
      case 'dealer':
        setRole('User');
        break;
    }
  }

  React.useEffect(() => {
    fetchUserRole()
  }, [])
  
  return (
        <div className={classes.root}>
          <Grid container justifyContent="center" alignItems="center">
            <Card className={classes.card}>
              {/* <CardActionArea> */}
              <IconHD className={classes.iconHd}/>

              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {getUserName() ||
                    getUserEmail ||
                    "HopDrive Dispatch User"}
                </Typography>
                <Typography
                  color="textSecondary"
                  style={{ position: "relative", top: "-18px" }}
                  // className={classes.inlineTxt}
                  variant="h6"
                  component="div"
                >
                  {!ctx.firebaseUser
                    ? "Unregistered"
                    : "HopDrive"}
                </Typography>
                <Typography component='div' color="textSecondary">
                  Role:
                  <Typography component='div' className={classes.inlineTxt}>
                    {role
                      ? role
                        .charAt(0)
                        .toUpperCase() +
                        role.slice(1)
                      : "Unregistered user"}
                  </Typography>
                </Typography>
                <Typography component='div' color="textSecondary">
                  Rooftop:
                  <Query query={GET_CUSTOMER} variables={{ id: parseInt(ctx.customerOverride || ctx.customerId) }} onError={(error) => {console.error(error); Sentry.captureException(error)}}>
                    {({ loading, data }) => {
                      if (loading) return null;
                      if (data && data.customers && data.customers.length > 0) return <Typography component='div' className={classes.inlineTxt}>{data.customers[0].name}</Typography>
                      else return null;
                    }}
                  </Query>
                </Typography>
                <Typography component='div' color="textSecondary">
                  Email:
                  <Typography component='div' className={classes.inlineTxt}>
                    <a
                      className="btn btn-link"
                      href={
                        getUserEmail()
                          ? `mailto:${getUserEmail()}`
                          : null
                      }
                    >
                      {getUserEmail() || "No email on record"}
                    </a>
                  </Typography>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </div>
  );
}

////////// GRAPHQL //////////
var GET_CUSTOMER = gql`
  query get_customer ($id: bigint!) {
    customers (where: {id: {_eq: $id}}) {
      id
      name
    }
  }
`;

ProfilePage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProfilePage);
