import React from 'react';
import ReactToPrint from 'react-to-print';
import dayjs from 'dayjs';

import { makeStyles, Tooltip, Grid, Button, Typography } from '@material-ui/core';
import { Save as SaveIcon } from '@material-ui/icons';
import Divide from '../../reusable/Divide';

import MoveStatusTracker from '../../reusable/MoveStatusTracker';
import MoveDetailsInfo from './MoveDetailsInfo';
import MoveDetailsLocation from './MoveDetailsLocation';

// import hd_logo from '../../../static/logo-banner-light.svg';

//////////////////////////////////////// COMPONENT ////////////////////////////////////////
export default function MoveDetailsToPrint({ move, pickupPhotos, deliveryPhotos }) {
  const cls = useStyles();

  const componentRef = React.useRef();

  const ComponentToPrint = React.forwardRef((props, ref) => (
    <div ref={ref} className={cls.root}>
      <div className={cls.page}>
        <div className={cls.preventPageBreak}>
          <MoveStatusTracker move={move} size='small' />
          <div className={cls.break} />
        </div>

        <div className={cls.preventPageBreak}>
          <MoveDetailsInfo move={move} showImages={false} />
        </div>

        <div className={cls.preventPageBreak}>
          <Divide spacer>Pickup & Delivery</Divide>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <MoveDetailsLocation type='pickup' move={move} showImages={false} />
            </Grid>
            <Grid item xs={6}>
              <MoveDetailsLocation type='delivery' move={move} showImages={false} />
            </Grid>
          </Grid>
        </div>

        {pickupPhotos && pickupPhotos.length ? (
          <div className={cls.preventPageBreak}>
            <Divide spacer>Pickup Images</Divide>
            <Grid container spacing={1}>
              {pickupPhotos.map((image, i) => (
                <Grid key={`pdf-pickup-image-${i}`} item style={{ position: `relative` }}>
                  <img className={cls.image} src={image.original} alt='' />
                  <Typography className={cls.label}>{image.label}</Typography>
                </Grid>
              ))}
            </Grid>
          </div>
        ) : null}

        {deliveryPhotos && deliveryPhotos.length ? (
          <div className={cls.preventPageBreak}>
            <Divide spacer>Delivery Images</Divide>
            <Grid container spacing={1}>
              {deliveryPhotos.map((image, i) => (
                <Grid key={`pdf-delivery-image-${i}`} item style={{ position: `relative` }}>
                  <img className={cls.image} src={image.original} alt='' />
                  <Typography className={cls.label}>{image.label}</Typography>
                </Grid>
              ))}
            </Grid>
          </div>
        ) : null}
      </div>
    </div>
  ));

  return (
    <>
      <ReactToPrint
        trigger={() => (
          <Tooltip title='Print or save move details as a PDF'>
            <Button disableElevation color='secondary' variant='contained' startIcon={<SaveIcon />}>
              Save As
            </Button>
          </Tooltip>
        )}
        content={() => componentRef.current}
        documentTitle={`move_details_${move.id}_${dayjs().format()}`}
      />

      <div style={{ display: `none` }}>
        <ComponentToPrint ref={componentRef} />
      </div>
    </>
  );
}

//////////////////////////////////////// STYLES ////////////////////////////////////////
const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  page: {
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: theme.spacing(2),
  },
  preventPageBreak: {
    '@media print': {
      clear: 'both',
      pageBreakInside: 'avoid',
    },
  },
  image: {
    maxWidth: 188,
    maxHeight: 141,
  },
  label: {
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 10,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
}));
